"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UN_GOAL_MAP = exports.Goal = exports.UN_GOALS = void 0;
exports.UN_GOALS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17'];
class Goal {
    constructor(type) {
        this.icon = '';
        this.description = '';
        this.icon = exports.UN_GOAL_MAP[type].icon;
        this.description = exports.UN_GOAL_MAP[type].description;
    }
}
exports.Goal = Goal;
exports.UN_GOAL_MAP = {
    1: {
        icon: 'https://ecocart-public-images.s3.amazonaws.com/UN-Sustainability-Goals/goal-01.png',
        description: ''
    },
    2: {
        icon: 'https://ecocart-public-images.s3.amazonaws.com/UN-Sustainability-Goals/goal-02.png',
        description: ''
    },
    3: {
        icon: 'https://ecocart-public-images.s3.amazonaws.com/UN-Sustainability-Goals/goal-03.png',
        description: ''
    },
    4: {
        icon: 'https://ecocart-public-images.s3.amazonaws.com/UN-Sustainability-Goals/goal-04.png',
        description: ''
    },
    5: {
        icon: 'https://ecocart-public-images.s3.amazonaws.com/UN-Sustainability-Goals/goal-05.png',
        description: ''
    },
    6: {
        icon: 'https://ecocart-public-images.s3.amazonaws.com/UN-Sustainability-Goals/goal-06.png',
        description: ''
    },
    7: {
        icon: 'https://ecocart-public-images.s3.amazonaws.com/UN-Sustainability-Goals/goal-07.png',
        description: ''
    },
    8: {
        icon: 'https://ecocart-public-images.s3.amazonaws.com/UN-Sustainability-Goals/goal-08.png',
        description: ''
    },
    9: {
        icon: 'https://ecocart-public-images.s3.amazonaws.com/UN-Sustainability-Goals/goal-09.png',
        description: ''
    },
    10: {
        icon: 'https://ecocart-public-images.s3.amazonaws.com/UN-Sustainability-Goals/goal-10.png',
        description: ''
    },
    11: {
        icon: 'https://ecocart-public-images.s3.amazonaws.com/UN-Sustainability-Goals/goal-11.png',
        description: ''
    },
    12: {
        icon: 'https://ecocart-public-images.s3.amazonaws.com/UN-Sustainability-Goals/goal-12.png',
        description: ''
    },
    13: {
        icon: 'https://ecocart-public-images.s3.amazonaws.com/UN-Sustainability-Goals/goal-13.png',
        description: ''
    },
    14: {
        icon: 'https://ecocart-public-images.s3.amazonaws.com/UN-Sustainability-Goals/goal-14.png',
        description: ''
    },
    15: {
        icon: 'https://ecocart-public-images.s3.amazonaws.com/UN-Sustainability-Goals/goal-15.png',
        description: ''
    },
    16: {
        icon: 'https://ecocart-public-images.s3.amazonaws.com/UN-Sustainability-Goals/goal-16.png',
        description: ''
    },
    17: {
        icon: 'https://ecocart-public-images.s3.amazonaws.com/UN-Sustainability-Goals/goal-17.png',
        description: ''
    }
};
