"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Experiment = void 0;
// to-be extended (properties added) as necessary
class Experiment {
    constructor() {
        this.key = 'dummy_ab_test'; // unique identifier for the experiment
        this.variations = ['default', 'none']; // array of variations
        this.weights = [0.5, 0.5]; // array of weights for each variation
    }
}
exports.Experiment = Experiment;
