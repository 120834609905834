"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertCalculationResponseToCalculation = void 0;
const universal_utils_1 = require("@ecocart/universal-utils");
const convertCalculationResponseToCalculation = (response, merchant) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const calc = Number(((_a = response === null || response === void 0 ? void 0 : response.variant_details) === null || _a === void 0 ? void 0 : _a.price) || 0);
    const co2 = Number(((_b = response === null || response === void 0 ? void 0 : response.variant_details) === null || _b === void 0 ? void 0 : _b.offset) || 0);
    const currencyCode = ((_c = response === null || response === void 0 ? void 0 : response.variant_details) === null || _c === void 0 ? void 0 : _c.currency) || (merchant === null || merchant === void 0 ? void 0 : merchant.currencyCode) || 'USD';
    return {
        id: (response === null || response === void 0 ? void 0 : response.identifier) || '',
        price: calc,
        priceFormatted: (0, universal_utils_1.formatCurrency)(calc, currencyCode),
        currencyCode,
        co2: co2 > 0 ? Math.round(co2) : 1,
        co2Unit: ((_d = response === null || response === void 0 ? void 0 : response.variant_details) === null || _d === void 0 ? void 0 : _d.offset_unit) || 'lb',
        variantId: (((_e = response === null || response === void 0 ? void 0 : response.variant_details) === null || _e === void 0 ? void 0 : _e.store_variant_id) ? String(response.variant_details.store_variant_id) : null),
        quantity: 1,
        co2Manufacturing: (_f = response === null || response === void 0 ? void 0 : response.offset_manufacturing) !== null && _f !== void 0 ? _f : 0,
        co2Shipping: (_g = response === null || response === void 0 ? void 0 : response.offset_shipping) !== null && _g !== void 0 ? _g : 0
    };
};
exports.convertCalculationResponseToCalculation = convertCalculationResponseToCalculation;
