"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBEntityMetadata = void 0;
class DBEntityMetadata {
    constructor() {
        this.active = true;
        this.createdAt = 0; // Date.now() | unix timestamp
        this.updatedAt = 0; // Date.now() | unix timestamp
    }
}
exports.DBEntityMetadata = DBEntityMetadata;
