"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.performanceMonitor = exports.PerformanceSummaryItem = void 0;
const universal_utils_1 = require("@ecocart/universal-utils");
const INITIATOR_TYPES = [
    'beacon',
    'css',
    'fetch',
    'iframe',
    'img',
    'link',
    'navigation',
    'other',
    'script',
    'video',
    'xmlhttprequest'
];
const MARK_EVENT_TYPES = ['initiated', 'resolved'];
class Mark {
    constructor() {
        this.timestamp = 0;
    }
}
class PerformanceSummaryItem {
    constructor() {
        this.duration = 0;
        this.mark_start = new Mark();
        this.mark_end = new Mark();
    }
}
exports.PerformanceSummaryItem = PerformanceSummaryItem;
class PerformanceMonitor {
    constructor() {
        this._performanceSummary = {};
        this._eventTimes = undefined;
    }
    get performanceSummary() {
        if (!this._performanceSummary)
            return {};
        Object.keys(this._performanceSummary).forEach((item_key) => {
            const item = this._performanceSummary[item_key];
            const mark_keys = ['mark_start', 'mark_end'];
            mark_keys.forEach((mark_key) => {
                var _a, _b, _c;
                const mark = item[mark_key];
                if (mark.event && mark.type && mark.query && this._eventTimes && ((_c = (_b = (_a = this._eventTimes) === null || _a === void 0 ? void 0 : _a[mark.event]) === null || _b === void 0 ? void 0 : _b[mark.type]) === null || _c === void 0 ? void 0 : _c[mark.query])) {
                    this._performanceSummary[item_key][mark_key].timestamp = this._eventTimes[mark.event][mark.type][mark.query];
                }
            });
            if (!this._performanceSummary[item_key].duration &&
                this._performanceSummary[item_key].mark_end.timestamp &&
                this._performanceSummary[item_key].mark_start.timestamp) {
                const duration = this._performanceSummary[item_key].mark_end.timestamp - this._performanceSummary[item_key].mark_start.timestamp;
                this._performanceSummary[item_key].duration = duration > 0 ? duration : 0;
            }
        });
        return this._performanceSummary;
    }
    get eventTimes() {
        return this._eventTimes;
    }
    init(performanceSummary) {
        this._performanceSummary = performanceSummary;
        this._eventTimes = Object.values(performanceSummary).reduce((acc, curr) => {
            if (curr.mark_start.event && curr.mark_start.type && curr.mark_start.query) {
                (0, universal_utils_1.set)(acc, ['initiated', curr.mark_start.event, curr.mark_start.type, curr.mark_start.query], 0);
            }
            if (curr.mark_end.event && curr.mark_end.type && curr.mark_end.query) {
                (0, universal_utils_1.set)(acc, ['resolved', curr.mark_end.event, curr.mark_end.type, curr.mark_end.query], 0);
            }
            return acc;
        }, {});
    }
    allEventsMarked() {
        if (!this._eventTimes || !Object.keys(this._eventTimes).length)
            return false;
        return [...Object.values(this._eventTimes.initiated), ...Object.values(this._eventTimes.resolved)]
            .reduce((acc, curr) => {
            acc = [...acc, ...Object.values(curr)];
            return acc;
        }, [])
            .includes(0);
    }
    markEvent({ event, type, query, timestamp }) {
        var _a, _b;
        if (!event || !type || !query || !((_b = (_a = this._eventTimes) === null || _a === void 0 ? void 0 : _a[event]) === null || _b === void 0 ? void 0 : _b[type]))
            return;
        if (!this._eventTimes[event][type][query]) {
            this._eventTimes[event][type][query] = timestamp || performance.now();
        }
    }
    setStart(key, timestamp) {
        var _a, _b;
        if (!((_b = (_a = this._performanceSummary) === null || _a === void 0 ? void 0 : _a[key]) === null || _b === void 0 ? void 0 : _b.mark_start))
            return;
        this._performanceSummary[key].mark_start.timestamp = timestamp || performance.now();
    }
    setEnd(key, timestamp) {
        var _a, _b;
        if (!((_b = (_a = this._performanceSummary) === null || _a === void 0 ? void 0 : _a[key]) === null || _b === void 0 ? void 0 : _b.mark_end))
            return;
        this._performanceSummary[key].mark_end.timestamp = timestamp || performance.now();
    }
    setDuration(key, duration) {
        var _a;
        if (!((_a = this._performanceSummary) === null || _a === void 0 ? void 0 : _a[key]) || !duration)
            return;
        this._performanceSummary[key].duration = duration;
    }
}
exports.performanceMonitor = new PerformanceMonitor();
