"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultMerchantGAParams = exports.TemplateToTypeMap = void 0;
const widget_1 = require("./widget");
exports.TemplateToTypeMap = {
    impact_counter: 'impact',
    seven: 'impact',
    promo: 'promo',
    three: 'promo',
    simple_checkout: 'checkout',
    checkout_widget: 'checkout',
    order_protection_checkout: 'order_protection',
    four: 'checkout',
    eco_incentives: 'incentives',
    education_touchpoint: 'education',
    impact_receipt: 'receipt',
    enhanced_pdp: 'enhanced_pdp',
    ab_test_widget_a_visual: 'checkout',
    ab_test_widget_b_cobranded: 'checkout',
    ab_test_widget_c_action: 'checkout',
    ab_test_pdp_a_background: 'promo',
    ab_test_pdp_b_cobranded: 'promo',
    ab_test_pdp_c_action: 'promo'
};
const getActiveWidgets = ({ widgetMap, widgetPlacements }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const isOfBroadPageType = (broadPageType, { pageType, pathname }) => {
        switch (broadPageType) {
            case 'checkout':
                return !!((pageType === null || pageType === void 0 ? void 0 : pageType.startsWith('checkout_')) || (pathname === null || pathname === void 0 ? void 0 : pathname.includes('checkout')));
            case 'cart':
                return pageType === 'cart';
            case 'in_store':
            default:
                return ['in_store', 'product'].includes(pageType);
        }
    };
    const isOfTemplateType = (type, { template }) => (template ? exports.TemplateToTypeMap[template] === type : false);
    const visibleWidgets = (0, widget_1.mergeWidgetData)(widgetMap, widgetPlacements).filter((widget) => widget.visible);
    // in-store
    const inStoreWidgets = visibleWidgets.filter((widget) => isOfBroadPageType('in_store', widget));
    const inStoreImpactWidget = inStoreWidgets.find((widget) => isOfTemplateType('impact', widget));
    const inStorePromoWidget = inStoreWidgets.find((widget) => isOfTemplateType('promo', widget));
    const inStoreCheckoutWidget = inStoreWidgets.find((widget) => isOfTemplateType('checkout', widget));
    const inStoreEducationTouchpointWidget = inStoreWidgets.find((widget) => isOfTemplateType('education', widget));
    const inStoreOrderProtectionWidget = inStoreWidgets.find((widget) => isOfTemplateType('order_protection', widget));
    // cart
    const cartWidgets = visibleWidgets.filter((widget) => isOfBroadPageType('cart', widget));
    const cartImpactWidget = cartWidgets.find((widget) => isOfTemplateType('impact', widget));
    const cartPromoWidget = cartWidgets.find((widget) => isOfTemplateType('promo', widget));
    const cartCheckoutWidget = cartWidgets.find((widget) => isOfTemplateType('checkout', widget));
    const cartOrderProtectionWidget = cartWidgets.find((widget) => isOfTemplateType('order_protection', widget));
    // checkout
    const checkoutWidgets = visibleWidgets.filter((widget) => isOfBroadPageType('checkout', widget));
    const checkoutImpactWidget = checkoutWidgets.find((widget) => isOfTemplateType('impact', widget));
    const checkoutPromoWidget = checkoutWidgets.find((widget) => isOfTemplateType('promo', widget));
    const checkoutCheckoutWidget = checkoutWidgets.find((widget) => isOfTemplateType('checkout', widget));
    const checkoutOrderProtectionWidget = checkoutWidgets.find((widget) => isOfTemplateType('order_protection', widget));
    return {
        in_store_impact_widget_template: inStoreImpactWidget === null || inStoreImpactWidget === void 0 ? void 0 : inStoreImpactWidget.template,
        in_store_promo_widget_template: inStorePromoWidget === null || inStorePromoWidget === void 0 ? void 0 : inStorePromoWidget.template,
        in_store_checkout_widget_template: inStoreCheckoutWidget === null || inStoreCheckoutWidget === void 0 ? void 0 : inStoreCheckoutWidget.template,
        in_store_checkout_widget_upsell_enabled: ((_b = (_a = inStoreCheckoutWidget === null || inStoreCheckoutWidget === void 0 ? void 0 : inStoreCheckoutWidget.elements) === null || _a === void 0 ? void 0 : _a.upsell) === null || _b === void 0 ? void 0 : _b.disable) === false,
        in_store_checkout_widget_social_proof_enabled: ((_d = (_c = inStoreCheckoutWidget === null || inStoreCheckoutWidget === void 0 ? void 0 : inStoreCheckoutWidget.elements) === null || _c === void 0 ? void 0 : _c.social_proof) === null || _d === void 0 ? void 0 : _d.disable) === false,
        in_store_education_touchpoint_template: inStoreEducationTouchpointWidget === null || inStoreEducationTouchpointWidget === void 0 ? void 0 : inStoreEducationTouchpointWidget.template,
        in_store_order_protection_template: inStoreOrderProtectionWidget === null || inStoreOrderProtectionWidget === void 0 ? void 0 : inStoreOrderProtectionWidget.template,
        cart_impact_widget_template: cartImpactWidget === null || cartImpactWidget === void 0 ? void 0 : cartImpactWidget.template,
        cart_promo_widget_template: cartPromoWidget === null || cartPromoWidget === void 0 ? void 0 : cartPromoWidget.template,
        cart_checkout_widget_template: cartCheckoutWidget === null || cartCheckoutWidget === void 0 ? void 0 : cartCheckoutWidget.template,
        cart_order_protection_template: cartOrderProtectionWidget === null || cartOrderProtectionWidget === void 0 ? void 0 : cartOrderProtectionWidget.template,
        cart_checkout_widget_upsell_enabled: ((_f = (_e = cartCheckoutWidget === null || cartCheckoutWidget === void 0 ? void 0 : cartCheckoutWidget.elements) === null || _e === void 0 ? void 0 : _e.upsell) === null || _f === void 0 ? void 0 : _f.disable) === false,
        cart_checkout_widget_social_proof_enabled: ((_h = (_g = cartCheckoutWidget === null || cartCheckoutWidget === void 0 ? void 0 : cartCheckoutWidget.elements) === null || _g === void 0 ? void 0 : _g.social_proof) === null || _h === void 0 ? void 0 : _h.disable) === false,
        checkout_impact_widget_template: checkoutImpactWidget === null || checkoutImpactWidget === void 0 ? void 0 : checkoutImpactWidget.template,
        checkout_promo_widget_template: checkoutPromoWidget === null || checkoutPromoWidget === void 0 ? void 0 : checkoutPromoWidget.template,
        checkout_order_protection_template: checkoutOrderProtectionWidget === null || checkoutOrderProtectionWidget === void 0 ? void 0 : checkoutOrderProtectionWidget.template,
        checkout_checkout_widget_template: checkoutCheckoutWidget === null || checkoutCheckoutWidget === void 0 ? void 0 : checkoutCheckoutWidget.template,
        checkout_checkout_widget_upsell_enabled: ((_k = (_j = checkoutCheckoutWidget === null || checkoutCheckoutWidget === void 0 ? void 0 : checkoutCheckoutWidget.elements) === null || _j === void 0 ? void 0 : _j.upsell) === null || _k === void 0 ? void 0 : _k.disable) === false,
        checkout_checkout_widget_social_proof_enabled: ((_m = (_l = checkoutCheckoutWidget === null || checkoutCheckoutWidget === void 0 ? void 0 : checkoutCheckoutWidget.elements) === null || _l === void 0 ? void 0 : _l.social_proof) === null || _m === void 0 ? void 0 : _m.disable) === false
    };
};
const getDefaultMerchantGAParams = (merchant) => {
    if (!merchant)
        return {};
    return Object.assign({ shop_name: merchant.shopName, payor_mode: merchant.payorMode, offset_multiplier: merchant.offsetMultiplier, default_opted_in: merchant.optOut, is_ecocart_enabled: merchant.ecocartEnabled }, getActiveWidgets(merchant));
};
exports.getDefaultMerchantGAParams = getDefaultMerchantGAParams;
