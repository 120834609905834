"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FINANCIAL_PROPS = exports.MERCHANT_ADMIN_ENUM_MAP = exports.MERCHANT_ENUM_MAP = exports.CURRENCY_CODES = exports.MANUFACTURING_CALCULATION_TYPES = exports.AUTOFULFILLMENT_MODES = exports.INVOICE_FREQUENCYS = exports.UNIT_SYSTEMS = exports.LOCALES = exports.CASHBACK_PRICING_MODES = exports.FLAT_RATE_MODES = exports.PKG_PROTECTION_PRICING_MODES = exports.PRICING_MODES = exports.PAYOR_MODES = exports.PAYMENT_SERVICE_PROVIDERS = exports.PLATFORMS = exports.INTEGRATION_TYPES = exports.ECOCART_JS_VERSIONS = exports.SETUP_STEPS = void 0;
/* --------------------------------- consts --------------------------------- */
exports.SETUP_STEPS = ['account_intake', 'product_ingestion', 'categorization', 'variant_creation', 'complete'];
exports.ECOCART_JS_VERSIONS = ['v1', 'v2'];
exports.INTEGRATION_TYPES = ['general', 'checkout'];
exports.PLATFORMS = ['Shopify', 'Shopify_headless', 'Wix', 'WooCommerce', 'BigCommerce', 'Custom', 'api'];
exports.PAYMENT_SERVICE_PROVIDERS = ['none', 'stripe', 'shopify_payments', 'bill'];
exports.PAYOR_MODES = ['customer_paying', 'merchant_paying', 'customer_matching', 'merchant_matching'];
exports.PRICING_MODES = ['calculation', 'flat_rate'];
exports.PKG_PROTECTION_PRICING_MODES = ['pct_aov', 'flat_rate'];
exports.FLAT_RATE_MODES = ['percentage', 'line_item_basis', 'cart_basis'];
exports.CASHBACK_PRICING_MODES = ['pct_aov', 'flat_rate'];
exports.LOCALES = ['da', 'de', 'en', 'en-CA', 'es', 'fr', 'it', 'jp', 'nb', 'nl', 'pl', 'pt', 'sk', 'zh-CN', 'zh-TW'];
exports.UNIT_SYSTEMS = ['imperial', 'metric'];
exports.INVOICE_FREQUENCYS = ['monthly', 'bi_weekly'];
exports.AUTOFULFILLMENT_MODES = ['disabled', 'enabled', 'delayed'];
exports.MANUFACTURING_CALCULATION_TYPES = ['defra_store', 'defra_product', 'lca'];
exports.CURRENCY_CODES = [
    'AED',
    'AFN',
    'ALL',
    'AMD',
    'ANG',
    'AOA',
    'ARS',
    'AUD',
    'AWG',
    'AZN',
    'BAM',
    'BBD',
    'BDT',
    'BGN',
    'BHD',
    'BIF',
    'BMD',
    'BND',
    'BOB',
    'BRL',
    'BSD',
    'BTN',
    'BWP',
    'BYN',
    'BZD',
    'CAD',
    'CDF',
    'CHF',
    'CLP',
    'CNY',
    'COP',
    'CRC',
    'CUC',
    'CUP',
    'CVE',
    'CZK',
    'DJF',
    'DKK',
    'DOP',
    'DZD',
    'EGP',
    'ERN',
    'ETB',
    'EUR',
    'FJD',
    'FKP',
    'GBP',
    'GEL',
    'GGP',
    'GHS',
    'GIP',
    'GMD',
    'GNF',
    'GTQ',
    'GYD',
    'HKD',
    'HNL',
    'HRK',
    'HTG',
    'HUF',
    'IDR',
    'ILS',
    'IMP',
    'INR',
    'IQD',
    'IRR',
    'ISK',
    'JEP',
    'JMD',
    'JOD',
    'JPY',
    'KES',
    'KGS',
    'KHR',
    'KMF',
    'KPW',
    'KRW',
    'KWD',
    'KYD',
    'KZT',
    'LAK',
    'LBP',
    'LKR',
    'LRD',
    'LSL',
    'LYD',
    'MAD',
    'MDL',
    'MGA',
    'MKD',
    'MMK',
    'MNT',
    'MOP',
    'MRO',
    'MUR',
    'MVR',
    'MWK',
    'MXN',
    'MYR',
    'MZN',
    'NAD',
    'NGN',
    'NIO',
    'NOK',
    'NPR',
    'NZD',
    'OMR',
    'PAB',
    'PEN',
    'PGK',
    'PHP',
    'PKR',
    'PLN',
    'PYG',
    'QAR',
    'RON',
    'RSD',
    'RUB',
    'RWF',
    'SAR',
    'SBD',
    'SCR',
    'SDG',
    'SEK',
    'SGD',
    'SHP',
    'SLL',
    'SOS',
    'SPL',
    'SRD',
    'STD',
    'SVC',
    'SYP',
    'SZL',
    'THB',
    'TJS',
    'TMT',
    'TND',
    'TOP',
    'TRY',
    'TTD',
    'TVD',
    'TWD',
    'TZS',
    'UAH',
    'UGX',
    'USD',
    'UYU',
    'UZS',
    'VEF',
    'VND',
    'VUV',
    'WST',
    'XAF',
    'XCD',
    'XDR',
    'XOF',
    'XPF',
    'YER',
    'ZAR',
    'ZMW',
    'ZWD'
];
/* -------------------------------- enum maps ------------------------------- */
exports.MERCHANT_ENUM_MAP = {
    platform: exports.PLATFORMS,
    payorMode: exports.PAYOR_MODES,
    locale: exports.LOCALES
};
exports.MERCHANT_ADMIN_ENUM_MAP = {
    pricingMode: exports.PRICING_MODES,
    pkgProtectionPricingMode: exports.PKG_PROTECTION_PRICING_MODES,
    flatRateMode: exports.FLAT_RATE_MODES,
    platform: exports.PLATFORMS,
    payorMode: exports.PAYOR_MODES,
    paymentServiceProvider: exports.PAYMENT_SERVICE_PROVIDERS,
    locale: exports.LOCALES,
    unitSystem: exports.UNIT_SYSTEMS,
    setupStep: exports.SETUP_STEPS,
    invoiceFrequency: exports.INVOICE_FREQUENCYS,
    manufacturingCalculationType: exports.MANUFACTURING_CALCULATION_TYPES
};
/* ------------------------------ grouped props ----------------------------- */
exports.FINANCIAL_PROPS = [
    'offsetMultiplier',
    'offsetPremium',
    'pricingMode',
    'flatRateMode',
    'flatRatePercentage',
    'flatRateLineItemAmount',
    'flatRateCartAmount',
    'fee',
    'enableShipping',
    'enableManufacturing',
    'currencyCode',
    'unitSystem',
    'payorMode',
    'pkgProtectionEnabled',
    'pkgProtectionPricingMode',
    'pkgProtectionPercentage',
    'pkgProtectionFlatRateAmount',
];
