"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REDEMPTION_NAMES_MAP = void 0;
// To be used in the redemption request email
exports.REDEMPTION_NAMES_MAP = {
    tapcart: 'Tapcart',
    klaviyo: 'Klaviyo',
    marketing_spend: 'covering your marketing spend',
    giveaways: 'giveaways',
    treet: 'Treet',
    maergo: 'Maergo',
    buy_from_ecocart: 'buying carbon offsets from EcoCart',
    life_cycle_analysis: 'life cycle analysis'
};
