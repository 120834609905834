"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUNSustainabilityLogo = exports.getVerificationLogo = void 0;
// Third party verification logos
const getVerificationLogo = (type) => {
    switch (type) {
        case 'American Carbon Registry':
            return 'https://ecocart-public-images.s3.amazonaws.com/verification/American+Carbon+Registry.png';
        case 'Gold Standard':
            return 'https://ecocart-public-images.s3.amazonaws.com/verification/Gold+Standard.png';
        case 'BC Carbon Registry':
            return 'https://ecocart-public-images.s3.amazonaws.com/verification/BC+Carbon+Registry.png';
        case 'CDM':
            return 'https://ecocart-public-images.s3.amazonaws.com/verification/CDM.png';
        case 'CSA Group':
            return 'https://ecocart-public-images.s3.amazonaws.com/verification/CSA+Group.png';
        case 'Climate Action Reserve':
            return 'https://ecocart-public-images.s3.amazonaws.com/verification/Climate+Action+Reserve.png';
        case 'VERRA':
            return 'https://ecocart-public-images.s3.amazonaws.com/verification/Verra.png';
        case 'Verified Carbon Standard':
            return 'https://ecocart-public-images.s3.amazonaws.com/verification/Verified+Carbon+Standard.png';
        default:
            return '';
    }
};
exports.getVerificationLogo = getVerificationLogo;
const getUNSustainabilityLogo = (goal) => {
    return `https://ecocart-public-images.s3.amazonaws.com/UN-Sustainability-Goals/goal-${goal}.png`;
};
exports.getUNSustainabilityLogo = getUNSustainabilityLogo;
