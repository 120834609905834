"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Project = exports.Coordinates = exports.Impact = void 0;
const db_entity_metadata_1 = require("./prototypes/db-entity-metadata");
class Impact {
    constructor() {
        this.amount = 0;
        this.label = '';
        this.icon = '';
    }
}
exports.Impact = Impact;
class Coordinates {
    constructor() {
        this.lat = 0;
        this.lng = 0;
    }
}
exports.Coordinates = Coordinates;
class Project extends db_entity_metadata_1.DBEntityMetadata {
    constructor() {
        super();
        this.id = '';
        this.name = '';
        this.description = '';
        this.location = '';
        this.coordinates = new Coordinates();
        this.type = 'Forestry';
        this.isPortfolio = false;
        this.impacts = [new Impact()];
        this.verifications = [];
        this.benefits = [''];
        this.images = [];
        this.url = '';
        this.totalCO2Offset = 0;
        this.goals = [];
    }
}
exports.Project = Project;
