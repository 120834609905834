"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IMAGE_ASSETS = exports.GLOBAL_PUBLIC_CONFIG = void 0;
exports.GLOBAL_PUBLIC_CONFIG = {
    UTILS_VERSION: '__DEPRECATED__',
    WIDGET_BASE_URL: {
        DEV: 'https://dev-widget.ecocart.tools',
        UAT: 'https://widget.ecocart.tools',
        PROD: 'https://widget.ecocart.tools'
    },
    APP_BASE_URL: {
        DEV: 'https://dev-app.ecocart.io',
        UAT: 'https://uat-app.ecocart.io',
        PROD: 'https://app.ecocart.io'
    },
    API_BASE_URL: {
        DEV: 'https://merchant-dev.ecocart.io',
        UAT: 'https://merchant-uat.ecocart.io',
        PROD: 'https://merchant.ecocart.io'
    },
    USER_MGMT_API_BASE_URL: {
        DEV: 'https://users-dev.ecocart.io',
        UAT: 'https://users-uat.ecocart.io',
        PROD: 'https://users.ecocart.io'
    },
    PROJECTS_API_BASE_URL: {
        DEV: 'https://projects-dev.ecocart.io',
        UAT: 'https://projects-uat.ecocart.io',
        PROD: 'https://projects.ecocart.io'
    },
    NODE_REPORTING_API_BASE_URL: {
        DEV: 'https://merchants-analytics-dev.ecocart.io',
        UAT: 'https://merchants-analytics-uat.ecocart.io',
        PROD: 'https://merchants-analytics.ecocart.io'
    },
    POWER_TOOLS_API_BASE_URL: {
        DEV: 'https://msx-power-tools-dev.ecocart.io',
        UAT: 'https://msx-power-tools-uat.ecocart.io',
        PROD: 'https://msx-power-tools.ecocart.io'
    },
    FIN_ORCH_LAYER_API_BASE_URL: {
        DEV: 'https://finorchlayer-dev.ecocart.io',
        UAT: 'https://finorchlayer-uat.ecocart.io',
        PROD: 'https://finorchlayer.ecocart.io'
    },
    SHOPIFY_NODE_API_BASE_URL: {
        DEV: 'https://dev-shopify-tools.n3q5s4si0aans.us-east-1.cs.amazonlightsail.com',
        UAT: undefined,
        PROD: 'https://shopify.ecocart.tools'
    },
    PUPPETEER_API_BASE_URL: {
        DEV: 'https://dev-puppeteer-tools.n3q5s4si0aans.us-east-1.cs.amazonlightsail.com',
        UAT: undefined,
        PROD: 'https://puppeteer-tools.n3q5s4si0aans.us-east-1.cs.amazonlightsail.com'
    },
    PAYMENT_PROJECT_BASE_URL: {
        DEV: 'https://exhoblrfp6.execute-api.us-east-2.amazonaws.com/prod',
        UAT: 'https://b3oq4qne6g.execute-api.us-east-2.amazonaws.com/prod',
        PROD: 'https://wjmpgvs1x8.execute-api.us-east-1.amazonaws.com/prod'
    },
    CONTACTS_SERVICE_BASE_URL: {
        DEV: 'https://contacts-dev.ecocart.io',
        UAT: 'https://contacts-uat.ecocart.io',
        PROD: 'https://contacts.ecocart.io'
    },
    CLAIMS_SERVICE_BASE_URL: {
        DEV: 'https://img3a11im8.execute-api.us-east-2.amazonaws.com/Stage',
        UAT: 'https://rz2txbkqy2.execute-api.us-east-1.amazonaws.com/Stage',
        PROD: 'https://claims.core.ecocart.io'
    },
    S3_IMAGE_BUCKET: 'https://ecocart-widget.s3.amazonaws.com/img',
    S3_PUBLIC_IMAGE_BUCKET: 'https://ecocart-public-images.s3.amazonaws.com',
    X_ECO_PUB_KEY: '0ec32d14-3c15-45e6-a85a-da48c06a1e52'
};
exports.IMAGE_ASSETS = {
    GLOBE: `${exports.GLOBAL_PUBLIC_CONFIG.S3_IMAGE_BUCKET}/globe_1.png`,
    INFO_ICON: `${exports.GLOBAL_PUBLIC_CONFIG.S3_PUBLIC_IMAGE_BUCKET}/info-icon.svg`,
    ECOCART: `${exports.GLOBAL_PUBLIC_CONFIG.S3_PUBLIC_IMAGE_BUCKET}/ecocart-logo.svg`,
    ON_PILL_LOGO: `${exports.GLOBAL_PUBLIC_CONFIG.S3_PUBLIC_IMAGE_BUCKET}/EC-bw-logo-white.svg`,
    ECOCART_WHITE: `${exports.GLOBAL_PUBLIC_CONFIG.S3_PUBLIC_IMAGE_BUCKET}/EC-bw-logo-white.svg`,
    ECOCART_BLACK: `${exports.GLOBAL_PUBLIC_CONFIG.S3_PUBLIC_IMAGE_BUCKET}/EC-bw-logo-black.svg`,
    ECOCART_COLOR_WHITE: `${exports.GLOBAL_PUBLIC_CONFIG.S3_PUBLIC_IMAGE_BUCKET}/ecocart-logo-white.svg`,
    ECOCART_GRAYSCALE: `${exports.GLOBAL_PUBLIC_CONFIG.S3_PUBLIC_IMAGE_BUCKET}/ecocart-grayscale.png`,
    ECOCART_COLOR_BLACK: `${exports.GLOBAL_PUBLIC_CONFIG.S3_PUBLIC_IMAGE_BUCKET}/ecocart-logo.svg`,
    QUESTION: `${exports.GLOBAL_PUBLIC_CONFIG.S3_IMAGE_BUCKET}/ask.svg`,
    IMAGE_PLACEHOLDER: `${exports.GLOBAL_PUBLIC_CONFIG.S3_PUBLIC_IMAGE_BUCKET}/image-placeholder.jpeg`
};
