"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EXPERIMENT_KEYS = void 0;
exports.EXPERIMENT_KEYS = [
    'dummy_ab_test',
    'show_checkout_widget',
    'show_checkout_widget_body_copy',
    'vary_checkout_widget_templates',
    'vary_checkout_widget_precheck',
    'show_pdp_widget',
    'vary_pdp_widget_templates',
    'enabled_checkout_widget_features',
    'carbon_versus_plastic_customer_paying',
    'climate_positive_versus_climate_contribution',
    'default_versus_support_global_forestry_projects'
];
