"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.gb = void 0;
const entities_1 = require("@ecocart/entities");
const mongrule_1 = require("./mongrule");
const util_1 = require("./util");
class GrowthBook {
    constructor() {
        this.enabled = false;
    }
    init({ experiments, user }) {
        var _a;
        if (!((_a = experiments === null || experiments === void 0 ? void 0 : experiments[0]) === null || _a === void 0 ? void 0 : _a.key) || !user) {
            this.enabled = false;
            return;
        }
        this._user = user;
        this._experiment = experiments[0];
        this.enabled = !!(entities_1.EXPERIMENT_KEYS.includes(this._experiment.key) && entities_1.features[this._experiment.key]);
    }
    isFeatureEnabled(key) {
        var _a;
        return this.enabled && key === ((_a = this._experiment) === null || _a === void 0 ? void 0 : _a.key);
    }
    getFeatureValue(key, defaultValue) {
        var _a;
        if (!this.enabled || key !== ((_a = this._experiment) === null || _a === void 0 ? void 0 : _a.key))
            return defaultValue;
        const value = this._evalFeature(key).value;
        return value === null ? defaultValue : value;
    }
    _evalFeature(key) {
        // Unknown feature id
        if (!this._experiment || !entities_1.features || !entities_1.features[key]) {
            return this._getFeatureResult(key, null, 'unknownFeature');
        }
        // For experiment rules, run an experiment
        const exp = {
            variations: this._experiment.variations,
            weights: this._experiment.weights,
            key: key
        };
        // Only return a value if the user is part of the experiment
        const res = this._run(exp, key);
        if (res.inExperiment && !res.passthrough) {
            return this._getFeatureResult(key, res.value, 'experiment', exp, res);
        }
        // Fall back to using the default value
        const feature = entities_1.features[key];
        return this._getFeatureResult(key, feature.defaultValue === undefined ? null : feature.defaultValue, 'defaultValue');
    }
    _getFeatureResult(key, value, source, experiment, result) {
        const ret = {
            value,
            on: !!value,
            off: !value,
            source,
            ruleId: ''
        };
        if (experiment)
            ret.experiment = experiment;
        if (result)
            ret.experimentResult = result;
        return ret;
    }
    _conditionPasses(condition) {
        return (0, mongrule_1.evalCondition)({}, condition);
    }
    _run(experiment, featureId) {
        var _a;
        const key = experiment.key;
        const numVariations = ((_a = experiment === null || experiment === void 0 ? void 0 : experiment.variations) === null || _a === void 0 ? void 0 : _a.length) || 0;
        // 1. If experiment has less than 2 variations, return immediately
        if (numVariations < 2) {
            return this._getResult(experiment, -1, false, featureId);
        }
        // 2. If the context is disabled, return immediately
        if (!this.enabled) {
            return this._getResult(experiment, -1, false, featureId);
        }
        const { hashValue } = this._getHashAttribute();
        // 8. Exclude if condition is false
        if (experiment.condition && !this._conditionPasses(experiment.condition)) {
            return this._getResult(experiment, -1, false, featureId);
        }
        const n = Number((0, util_1.hash)(String(key), hashValue, 1));
        const ranges = (0, util_1.getBucketRanges)(numVariations, 1, experiment.weights);
        const assigned = (0, util_1.chooseVariation)(n, ranges);
        // 10. Return if not in experiment
        if (assigned < 0) {
            return this._getResult(experiment, -1, false, featureId);
        }
        // 13. Build the result object
        return this._getResult(experiment, assigned, true, featureId, n);
    }
    _getHashAttribute() {
        return { hashAttribute: 'id', hashValue: this._user.id };
    }
    _getResult(experiment, variationIndex, hashUsed, featureId, bucket) {
        var _a, _b;
        let inExperiment = true;
        // If assigned variation is not valid, use the baseline and mark the user as not in the experiment
        if (variationIndex < 0 || variationIndex >= (((_a = experiment === null || experiment === void 0 ? void 0 : experiment.variations) === null || _a === void 0 ? void 0 : _a.length) || 0)) {
            variationIndex = 0;
            inExperiment = false;
        }
        const { hashAttribute, hashValue } = this._getHashAttribute();
        const res = {
            key: String(variationIndex),
            featureId,
            inExperiment,
            hashUsed,
            variationId: variationIndex,
            value: (_b = experiment === null || experiment === void 0 ? void 0 : experiment.variations) === null || _b === void 0 ? void 0 : _b[variationIndex],
            hashAttribute,
            hashValue
        };
        if (bucket !== undefined)
            res.bucket = bucket;
        return res;
    }
}
exports.gb = new GrowthBook();
