"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getShopName = exports.onElementReady = exports.loadStylesheet = exports.loadScript = void 0;
const params_1 = require("./params");
const isScriptLoaded = (src) => {
    return Array.from(document.scripts).some((active) => active.src === src);
};
const loadScript = (src) => {
    return !isScriptLoaded(src)
        ? new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.setAttribute('src', src);
            script.onload = () => resolve(null);
            script.onerror = () => reject();
            document.body.appendChild(script);
        })
        : new Promise((resolve) => resolve(null));
};
exports.loadScript = loadScript;
const isLinkTagLoaded = (href) => {
    const head = document.querySelector('head');
    return !!(head === null || head === void 0 ? void 0 : head.querySelector(`link[href="${href}"]`));
};
const loadStylesheet = (href, attributes = {}) => {
    return !isLinkTagLoaded(href)
        ? new Promise((resolve) => {
            const stylesheet = document.createElement('link');
            stylesheet.setAttribute('rel', 'stylesheet');
            stylesheet.setAttribute('href', href);
            if (attributes && typeof attributes === 'object' && attributes !== null) {
                for (const [attr, val] of Object.entries(attributes)) {
                    stylesheet.setAttribute(attr, val);
                }
            }
            document.getElementsByTagName('head')[0].appendChild(stylesheet);
            resolve(null);
        })
        : new Promise((resolve) => resolve(null));
};
exports.loadStylesheet = loadStylesheet;
const onElementReady = (selector) => {
    return new Promise((resolve) => {
        const el = document.querySelector(selector);
        if (el) {
            resolve(el);
            return;
        }
        new MutationObserver((_, observer) => {
            const el = document.querySelector(selector);
            if (el) {
                observer.disconnect();
                resolve(el);
            }
        }).observe(document.documentElement, {
            childList: true,
            subtree: true
        });
    });
};
exports.onElementReady = onElementReady;
const getShopName = () => {
    var _a;
    const extractedShopName = (0, params_1.extractParamValueFromCurrentScript)('shop_name');
    return extractedShopName || ((_a = window === null || window === void 0 ? void 0 : window.Shopify) === null || _a === void 0 ? void 0 : _a.shop);
};
exports.getShopName = getShopName;
