/** @type {import('tailwindcss').Config} */
module.exports = {
  future: {
    purgeLayersByDefault: true,
    removeDeprecatedGapUtilities: true
  },
  theme: {
    screens: {
      'xs': '0px',
      'sm': '490px',
      'md': '768px',
      'lg': '1024px',
      'xl': '1280px',
      '2xl': '1440px'
    },
    colors: {
      primary: {
        50: '#E6F8EF',
        100: '#B8E9D2',
        200: '#8AD6B3',
        300: '#5CBF94',
        400: '#31A375',
        500: '#0F8354',
        DEFAULT: '#0F8354',
        600: '#007445',
        700: '#006036',
        800: '#004625',
        900: '#002613'
      },
      secondary: {
        50: '#E6EEFF',
        100: '#B8D2FF',
        200: '#8AB6FF',
        300: '#5C9CFF',
        400: '#2E83FF',
        500: '#006CFF',
        DEFAULT: '#006CFF',
        600: '#0053CC',
        700: '#003B99',
        800: '#002566',
        900: '#011732'
      },
      white: '#fff',
      black: '#000',
      tertiary: {
        50: '#F2F4F6',
        100: '#D4DADF',
        200: '#B6BFC7',
        300: '#97A5AF',
        400: '#798A96',
        500: '#5B6F7D',
        600: '#3D5462',
        700: '#1E3948',
        800: '#001D2C',
        DEFAULT: '#001D2C',
        900: '#00070D'
      },
      algea: {
        50: '#E6FFF2',
        100: '#B8FFDC',
        200: '#8AFFC6',
        300: '#61FFB0',
        400: '#43F69A',
        500: '#2CE284',
        DEFAULT: '#2CE284',
        600: '#11C769',
        700: '#00994E',
        800: '#006634',
        900: '#00331A'
      },
      gray: {
        50: '#F5F5F5',
        100: '#DDDDDD',
        200: '#C4C4C4',
        300: '#ACACAC',
        400: '#949494',
        500: '#757575',
        600: '#636363',
        700: '#4B4B4B',
        800: '#333333',
        DEFAULT: '#333333',
        900: '#0A0A0A'
      },
      success: {
        50: '#E3FFD9',
        100: '#A1DE8D',
        200: '#7DD161',
        300: '#6CCA4E',
        400: '#5CC43B',
        500: '#39B820',
        DEFAULT: '#39B820',
        600: '#2BA201',
        700: '#248901',
        800: '#1D6F01',
        900: '#175601'
      },
      warning: {
        50: '#FFF5DB',
        100: '#FEDD8E',
        200: '#FED36C',
        300: '#FECA52',
        400: '#FEC239',
        500: '#FFB800',
        DEFAULT: '#BA941F',
        600: '#DEAD17',
        700: '#BA941F',
        800: '#A2811B',
        900: '#776611'
      },
      danger: {
        50: '#FFE8EA',
        100: '#FBA7AC',
        200: '#F98A92',
        300: '#F8747D',
        400: '#F75A65',
        500: '#F8404B',
        DEFAULT: '#B91B29',
        600: '#D11F2E',
        700: '#B91B29',
        800: '#8F0512',
        900: '#76040F'
      },
      transparent: 'transparent',
      inherit: 'inherit',
      current: 'current'
    },
    fontSize: {
      '2xs': ['10px', { lineHeight: '12px' }],
      'xs': ['11px', { lineHeight: '16px' }],
      'sm': ['12px', { lineHeight: '16px' }],
      'base': ['14px', { lineHeight: '20px' }],
      'lg': ['16px', { lineHeight: '24px' }],
      'xl': ['18px', { lineHeight: '24px' }],
      '2xl': ['20px', { lineHeight: '24px' }],
      '3xl': ['22px', { lineHeight: '28px' }],
      '4xl': ['25px', { lineHeight: '32px' }],
      '5xl': ['28px', { lineHeight: '36px' }],
      '6xl': ['32px', { lineHeight: '40px' }],
      '7xl': ['36px', { lineHeight: '44px' }],
      '8xl': ['40px', { lineHeight: '48px' }],
      '9xl': ['45px', { lineHeight: '56px' }],
      '10xl': ['51px', { lineHeight: '64px' }],
      '11xl': ['57px', { lineHeight: '64px' }]
    },
    spacing: {
      0: '0px',
      1: '4px',
      2: '8px',
      3: '12px',
      4: '16px',
      5: '20px',
      6: '24px',
      7: '32px',
      8: '40px',
      9: '64px',
      10: '72px',
      11: '80px'
    },
    lineHeight: {
      'xs': '12px',
      'sm': '16px',
      'base': '18px',
      'md': '20px',
      'lg': '24px',
      '2xl': '28px',
      '3xl': '32px',
      '4xl': '36px',
      '5xl': '40px',
      '6xl': '44px',
      '7xl': '48px',
      '8xl': '56px',
      '9xl': '64px',
      '10xl': '72px'
    },
    borderRadius: {
      none: '0px',
      xs: '4px',
      sm: '8px',
      md: '16px',
      lg: '24px',
      full: '9999px'
    },
    extend: {
      flex: {
        2: '2 1 0%',
        3: '3 1 0%',
        4: '4 1 0%',
        5: '5 1 0%',
        6: '6 1 0%',
        7: '7 1 0%',
        8: '8 1 0%',
        9: '9 1 0%'
      }
    }
  },
  plugins: []
};
