"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PERMISSIONS = void 0;
/* ---------------------------------- consts -------------------------------- */
// format = `[ACTION]_[SCOPE]_[ENTITY]`
// -- current types --
// ACTION = get | create
// SCOPE  = all
// ENTITY = merchants | users
exports.PERMISSIONS = ['get_all_merchants', 'get_all_users', 'create_all_users'];
