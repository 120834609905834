"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VERIFICATION_TYPES = void 0;
/* --------------------------------- consts --------------------------------- */
exports.VERIFICATION_TYPES = [
    'VERRA',
    'Gold Standard',
    'Climate Action Reserve',
    'American Carbon Registry',
    'BC Carbon Registry',
    'CDM',
    'Verified Carbon Standard',
    'CSA Group'
];
