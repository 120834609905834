"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.logEcoEvt = exports.EcoEvt = void 0;
const constants_1 = require("./constants");
const http_1 = require("./http");
const lodash_1 = require("./lodash");
class EcoEvt {
    constructor(type, service_name, shop_name, metadata) {
        this.type = type;
        this.service_name = service_name;
        this.shop_name = shop_name;
        this.timestamp = Date.now();
        this.metadata = metadata;
    }
}
exports.EcoEvt = EcoEvt;
/* ----------------------------------- evt ---------------------------------- */
let evtQueue = [];
let evtDisabled = false;
const sendEcoEvts = () => __awaiter(void 0, void 0, void 0, function* () {
    if (!(evtQueue === null || evtQueue === void 0 ? void 0 : evtQueue.length) || evtDisabled)
        return;
    try {
        yield (0, http_1.axiosFetch)('https://evts.ecocart.io/event', {
            method: 'POST',
            body: JSON.stringify(evtQueue),
            headers: { 'Content-Type': 'application/json', 'X-Eco-Key': constants_1.GLOBAL_PUBLIC_CONFIG.X_ECO_PUB_KEY }
        });
    }
    catch (_a) {
        evtDisabled = true;
    }
    evtQueue = [];
});
const debouncedSendEcoEvts = (0, lodash_1.debounce)(sendEcoEvts, 2000);
const logEcoEvt = (evt) => {
    if (!evt)
        return;
    evtQueue.push(evt);
    debouncedSendEcoEvts();
};
exports.logEcoEvt = logEcoEvt;
