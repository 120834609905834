"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Offset = exports.WidgetAllSettings = exports.WidgetPlacement = exports.Widget = void 0;
const widget_1 = require("./enum/widget");
const DEFAULT_WIDGET_ELEMENT_PROPS = {
    styles: undefined,
    text: undefined,
    custom: undefined,
    disable: undefined,
    overrides: undefined
};
const DEFAULT_WIDGET_ELEMENTS = widget_1.WIDGET_ELEMENTS.reduce((acc, curr) => {
    acc[curr] = DEFAULT_WIDGET_ELEMENT_PROPS;
    return acc;
}, {});
class Widget {
    constructor() {
        this.template = 'four';
        this.name = '';
        this.elements = DEFAULT_WIDGET_ELEMENTS;
    }
}
exports.Widget = Widget;
class WidgetPlacement {
    constructor() {
        this.visible = true;
        this.selector = '';
        this.selectMultiple = false;
        this.placement = 'before';
        this.widgetKey = '';
        this.pageType = 'in_store';
        this.pathname = ''; // ** location.pathname
    }
}
exports.WidgetPlacement = WidgetPlacement;
class WidgetAllSettings {
    constructor() {
        this.offsets = [{ type: 'co2' }, { type: 'miles' }];
        this.refreshAfterInStoreCartUpdate = 'default';
        this.refreshAfterInStoreCartAddRemove = 'default';
        this.refreshAfterCartPageCartUpdate = 'default';
        this.refreshAfterCartPageCartAddRemove = 'default';
        this.refreshAfterCheckoutCartUpdate = 'default';
        this.refreshAfterCheckoutCartAddRemove = 'default';
        this.disableConfirmEcoCartRemoval = false;
        this.breakpoints = {
            'xs': 0,
            'sm': 490,
            'md': 768,
            'lg': 1024,
            'xl': 1280,
            '2xl': 1440
        };
    }
}
exports.WidgetAllSettings = WidgetAllSettings;
class Offset {
    constructor() {
        this.type = 'co2';
        this.unit = 'lb';
        this.img = '';
    }
}
exports.Offset = Offset;
