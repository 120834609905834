"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getV2ScriptSrc = exports.getV1ScriptSrc = void 0;
const universal_utils_1 = require("@ecocart/universal-utils");
const getV1ScriptSrc = (shopName) => {
    let script = 'https://s.ecocartapp.com/ecocart.js';
    if (shopName)
        script += `?shop_name=${shopName}`;
    return script;
};
exports.getV1ScriptSrc = getV1ScriptSrc;
const getV2ScriptSrc = (shopName) => {
    let script = `${universal_utils_1.GLOBAL_PUBLIC_CONFIG.WIDGET_BASE_URL.PROD}/ecocart.js`;
    if (shopName)
        script += `?shop_name=${shopName}`;
    return script;
};
exports.getV2ScriptSrc = getV2ScriptSrc;
