"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getShopifyProductHandleFromURL = exports.removeQueryParamsFromUrl = exports.extractParamValueFromUrl = exports.extractParamValueFromCurrentScript = void 0;
const scripts_1 = require("./scripts");
const extractParamValueFromCurrentScript = (paramKey) => {
    if (!paramKey)
        return '';
    const script = document.currentScript ||
        document.querySelector(`script[src^="${(0, scripts_1.getV2ScriptSrc)()}"]`) ||
        document.querySelector(`script[src^="${(0, scripts_1.getV1ScriptSrc)()}"]`);
    if (script === null || script === void 0 ? void 0 : script.src) {
        const currentScriptSrc = script.src;
        const queryParams = new URLSearchParams(new URL(currentScriptSrc).search);
        return queryParams.get(paramKey);
    }
    else {
        return '';
    }
};
exports.extractParamValueFromCurrentScript = extractParamValueFromCurrentScript;
const extractParamValueFromUrl = (paramKey) => {
    if (!paramKey)
        return '';
    const queryParams = new URLSearchParams(new URL(window.location.href).search);
    return queryParams.get(paramKey);
};
exports.extractParamValueFromUrl = extractParamValueFromUrl;
const removeQueryParamsFromUrl = (url) => {
    const [path] = url.split('?');
    return path;
};
exports.removeQueryParamsFromUrl = removeQueryParamsFromUrl;
const getShopifyProductHandleFromURL = () => {
    const currentURL = window.location.href;
    const urlParts = currentURL.split('/');
    const productsIndex = urlParts.indexOf('products');
    // The product handle is the part of the URL after "products"
    if (productsIndex !== -1 && productsIndex < urlParts.length - 1) {
        return urlParts[productsIndex + 1];
    }
    console.error('Product handle not found in the URL.');
    return undefined;
};
exports.getShopifyProductHandleFromURL = getShopifyProductHandleFromURL;
