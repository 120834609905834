"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOCALE_TO_LANGUAGE = void 0;
exports.LOCALE_TO_LANGUAGE = {
    'da': 'Danish',
    'de': 'German',
    'en': 'English',
    'en-CA': 'English (Canada)',
    'es': 'Spanish',
    'fr': 'French',
    'it': 'Italian',
    'jp': 'Japanese',
    'nb': 'Norwegian Bokmål',
    'nl': 'Dutch',
    'pl': 'Polish',
    'pt': 'Portuguese',
    'sk': 'Slovak',
    'zh-CN': 'Chinese (Simplified)',
    'zh-TW': 'Chinese (Traditional)'
};
