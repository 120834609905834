"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.t = exports.getTranslationsLocale = exports.setTranslationsLocale = exports.translations = void 0;
let _locale = 'en';
exports.translations = {
    'en': {
        add_price_for: 'Add [price] for',
        sustainable_shipping: 'Sustainable Shipping',
        shipping_protection: 'Shipping Protection',
        loss_damage_theft_protection: 'Loss, Damage, Theft Protection',
        protect_your_order_and_the_environment: 'Protect Your Order and the Environment',
        protect_your_package_while_offseting_carbon: "Protect your package from loss, theft or damage while offsetting your order's carbon footprint",
        purchasing_this_will_offset_amount_carbon: 'Purchasing This Will Offset [co2_per_item] Of Carbon With <span class="sr-only">EcoCart</span>[ecocart_logo]',
        this_is_like: 'This is like',
        driving_amount_miles_in_an_average_gas_vehicle: '<span class="font-bold">driving [miles_per_item] miles</span> in an average gas vehicle',
        amount_tree_seedlings_grown_for_10_years: '<span class="font-bold">[seedlings_per_item] tree seedlings</span> grown for 10 years',
        template_support_forestry_projects: 'Support Global Forestry Projects',
        template_makeAClimateContribution: 'Make A Climate Contribution',
        template_removeAmtOfPlasticFromOcean: 'Remove <span class="text-primary-400">1 lb</span> of plastic from the ocean',
        template_shopSustainablyByCounteractingPlasticWaste: "Shop Sustainably By Counteracting Your Order's Plastic Waste",
        template_yourContributionWillRemoveAmtPlasticFromOcean: 'Your <span class="text-primary-400">$1.00</span> contribution will remove <span class="text-primary-400">1 lb</span> of plastic from the ocean, counteracting the waste associated with the product and its packaging',
        template_ecocartEnablesUsToPartnerWithOceanPlasticRemovalProjects: 'EcoCart enables us to partner with ocean plastic removal projects in order to help us fight against climate change and counteract the plastic waste associated with each of our orders.',
        template_byCheckingOutWithEcoCartYourOrderWillIncludeAnInvestmentFutureHealth: 'By checking out with EcoCart, your order will include an investment in the future health of the planet by helping clean plastic up from the ocean.',
        template_thisIsEquivalentToAmtPlasticBottles: 'This is equivalent to the amount of plastic found in <span class="text-primary-400">24</span> 16oz water bottles',
        template_accordingToUNEnvironment: 'According to the UN Environment Programme, the average person is responsible for 156 water bottles being discarded as waste every year. So, it would take <span class="text-primary-400">[plastic_to_purchases]</span> purchases to counteract the average consumer\'s annual water bottle waste.',
        template_yourContributionWillHelpFundCleanHub: 'Your contribution will help fund Clean Hub, a certified plastic removal project',
        template_cleanHubIsAVerifiedProject: 'Clean Hub is a verified project dedicated to promoting sustainability and environmental preservation.',
        template_brandHasPartneredWithEcocart: '[brand] has partnered with EcoCart to estimate and offset the carbon footprint of your order by funding verified carbon removal projects around the world.',
        template_yourContributionAtWork: 'Your contribution at work...',
        template_verifiedBy: 'Verified by',
        template_yourOrderWillOffsetAmountCarbon: 'Your Order Will Offset [co2] of Carbon',
        template_byCheckingOutWithEcocartYourOrderWillHelp: 'By checking out with EcoCart, your order will help support the [project]',
        template_shopSustainably: 'Shop Sustainably',
        template_ecomindedShoppersLikeYouHaveOffset: 'Way to go! Eco-minded shoppers like you have <span class="text-primary-400">offset [global_all_time_carbon_offset] of Carbon</span> when checking out with EcoCart.',
        template_ofCarbonOffset: 'of Carbon Offset',
        template_goToStore: 'Go To Store',
        template_werePlantingTreesOnOrders: "We're Planting [eco_incentives_trees] Trees On Orders Over [eco_incentives_threshold]",
        template_youreValueAwayFromPlantingTrees: "You're [amount_needed_for_incentives] Away From Planting [eco_incentives_trees] Trees",
        template_hoorayYourOrderWillPlantTreesMerchantWillAlsoOffset: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. [brand] Will Also Offset The Emissions Of Your Order At Checkout!',
        template_hoorayYourOrderWillPlantTreesMaximizeImpact: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. Maximize Your Impact By Offsetting Your Order At Checkout!',
        template_merchantWillOffsetTwiceAsMuchCarbonAsYourOrderEmits: '<span class="text-primary-400">[brand]</span> will offset twice as much carbon as your order emits',
        template_merchantWillCoverTheCostOfOffsettingYourOrder: '<span class="text-primary-400">[brand]</span> will cover the cost of offsetting your order\'s carbon footprint',
        template_selectEcoCartInYourCartOrAtCheckout: 'Select EcoCart in your cart or at checkout to effortlessly contribute towards a greener future by offsetting',
        template_offsetYourOrdersCarbonFootPrintByCheckingOutWithEcocart: "Offset your order's carbon footprint by checking out with EcoCart",
        template_ecocartEstimatesEachOrdersCarbonFootprintLifeTimeUsage: "EcoCart estimates each order's carbon footprint by calculating variables such as shipping method, distance, materials, manufacturing processes, industry averages, lifetime usage, and more.",
        template_merchantIsOffsettingYourOrdersCarbonFootprintMatchTheirContributionDouble: "[brand] is offsetting your order's carbon footprint, <strong>match their contribution to double the impact</strong>",
        template_merchantWillOffsetYourOrdersCarbonFootprint: '<span class="text-primary-400">[brand]</span> will offset your order\'s carbon footprint',
        template_merchantWillDoubleYourOrdersCarbonOffset: '<span class="text-primary-400">[brand]</span> will double your order\'s carbon offset',
        template_merchantWillDoubleTheImpactByMatching: '<strong>[brand] will double the impact</strong> by matching your contribution',
        template_merchantMadeYourOrderClimatePositive: '[brand] Made Your Order Climate Positive',
        template_offsetTwiceAsMuchCarbonAsYourOrderEmits: 'Offset twice as much carbon as your order emits',
        template_merchantWillMatchQuadrupling: '<span class="text-primary-400">[brand]</span> will match your contribution, quadrupling your order\'s total carbon offset!',
        template_yourContributionTwiceAsMuch: 'Your <span class="text-primary-400">[price]</span> contribution will offset <span class="text-primary-400">[co2]</span> of carbon, twice as much as your order\'s estimated footprint, making making a positive lasting environmental impact',
        template_ecocartEstimatesEachOrderOffest2x: "EcoCart estimates each order's carbon footprint by calculating variables such as shipping method, distance, materials, manufacturing processes, industry averages, lifetime usage, and more.<br><br>By checking out with EcoCart, your order will double the offset of its estimated carbon footprint. making a positive lasting environmental impact.",
        template_yourOrderWillOffset4x: 'Your order will offset 4x the amount of carbon it was estimated to have emitted, making it a positive lasting environmental impact',
        template_merchantWillOffsetTwiceAsMuch: '<span class="text-primary-400">[brand]</span> will offset <span class="text-primary-400">[co2]</span> of carbon, twice as much as your order\'s estimated footprint, making a positive lasting environmental impact',
        template_byContributingYourOrderWillOffset4x: 'By contributing, your order will quadruple the offset of its estimated carbon emissions, achieving four times the environmental impact!',
        template_matchTheirContributionToQuadruple: "Match their contribution to quadruple your order's total carbon offset!",
        template_offsetMyOrdersFootprint: "Offset My Order's Carbon Footprint",
        template_ecocartEstimatesEachOrdersCarbonFootprint: "EcoCart estimates each order's carbon footprint by calculating variables such as shipping method, distance, materials, manufacturing processes, industry averages, and more.",
        template_ecocartPartnersWithCertifiedProjects: 'EcoCart partners with certified projects to offset the carbon emissions from manufacturing and shipping your order.',
        template_merchantOffsetYourCarbonFootprint: '<span class="text-primary-400">[brand]</span> Offset Your Carbon Footprint',
        template_merchantContributionWillHelpFund: '<span class="text-primary-400">[brand]</span><span class="text-primary-400">\'s</span> contribution will help fund <span class="text-primary-400">[project]</span>, a certified carbon offsetting program.',
        template_yourContributionWillHelpFund: 'Your contribution will help fund <span class="text-primary-400">[project]</span>, a certified carbon offsetting program.',
        template_byContributingYouWillOffsetCarbon: 'By contributing <span class="text-primary-400">[price]</span> you will offset <span class="text-primary-400">[co2]</span> of carbon',
        template_merchantWillOffsetCarbon: '<span class="text-primary-400">[brand]</span> will offset <span class="text-primary-400">[co2]</span> of carbon',
        template_yourOrderHasBeenOffsetMaximizeImpact: '<span class="font-semibold">Your order has been offset!</span> Maximize your impact by going climate positive!',
        template_yourContributionWillGoTowards: 'Your contribution will go towards funding the [project]',
        template_withASmallContribution: 'With a small contribution, we can mitigate the emissions calculated from the items in your cart and reduce your carbon footprint',
        template_EcoCartWillCalculateTheFootprint: 'EcoCart will calculate the carbon footprint of your order',
        template_throughCollectiveActionYouAreTakingProactive: 'Through collective action, we can make a real difference in protecting our planet. By offsetting your carbon footprint, you are taking a proactive step towards building a more sustainable future',
        template_merchantHasOffsetYourOrderWith: '[brand] Has Offset Your Order With',
        template_offsetYourOrderAtCheckoutWith: 'Offset Your Order At Checkout With',
        template_yourOrderBreakdown: 'Your order breakdown',
        template_projectIsAVerifiedProject: '<a href="[project_url]" target="_blank" rel="noopener noreferrer">[project]</a> is a verified project dedicated to promoting sustainability and environmental preservation.',
        template_yourOrderWillOffsetAtLeastCarbon: 'Your order will offset at least [co2] of carbon which is more carbon than your order produces, exceeding net zero standards!',
        template_doubleTheImpact: 'Double the impact by matching their contribution doubling the impact of your contribution.',
        template_oneYearTreeAbsorbCO2Amount: 'According to the US Department of Agriculture, in one year a mature tree can absorb more than 48 lbs of carbon dioxide from the atmosphere and release oxygen in exchange',
        template_carbonTreesCanAbsorb: 'This is equivalent to the amount of carbon <span class="text-primary-400">[lbs_co2_absorbed_by_one_tree_annually] trees</span> can absorb in one year',
        template_ecocartProprietaryAlgo: "EcoCart's proprietary algorithm estimates each order's carbon footprint in real-time by calculating variables such as shipping method, distance, manufacturing methods, materials, and more.",
        template_shopSustainablyByOffsetingCO2Footprint: "Shop Sustainably By Offsetting Your Order's Carbon Footprint",
        template_reduceMyOrderCO2: "Reduce My Order's Carbon Footprint",
        template_makeMyOrder: 'Make my order carbon neutral',
        template_makeMyOrderClimatePositive: 'Double the Climate Impact of My Order',
        template_offsetEstimatedCo2e: 'Offset the estimated carbon emissions of your order',
        template_merchantMatchYourContribution: '<span class="text-primary-400">[brand]</span> will match your contribution, doubling the offset of its estimated carbon footprint',
        template_yourOrderHasBeen: 'Your order has been made carbon neutral',
        template_yourOrderWillBe: 'Your order will be made carbon neutral by [brand]',
        template_addToMake: 'Add [price] to make your order carbon neutral',
        template_addToReduce: 'Add [price] to reduce your carbon footprint',
        template_addToMatch: 'Add [price] to match their contribution and double the impact!',
        template_addToDouble: 'Add [price] to double the impact',
        template_byCheckingTheBox: "By checking the box, you'll be protecting [trees] trees in the [project], which are absorbing the CO2 created by your order, making it carbon neutral!",
        template_byDonating: "By donating, you'll be protecting [trees] trees in the [project], which are absorbing the CO2 created by your order, making it carbon neutral!",
        template_byDonatingMatch: "By donating, you'll be protecting [trees] trees in the [project], which are absorbing the CO2 created by your order. [brand] will match your contribution to double the impact and save even more trees.",
        template_carbonNeutralOrderFor: 'Carbon Neutral Order for [price]',
        template_carbonNeutralShipping: 'Carbon Neutral Shipping',
        template_madeCarbonNeutralBy: 'Made Carbon Neutral by [brand]',
        template_brandIsMaking: '[brand] is Making 100% of Orders Carbon Neutral',
        template_brandWillMatch: "[brand] will match your contribution, doubling your order's impact",
        template_brandIsProudMerchantPaying: '[brand] is proud to offset the carbon emissions from your order by protecting [trees] trees in the [project], making it carbon neutral.',
        template_brandIsProudCustomerMatching: "[brand] is proud to offset the carbon emissions from your order by protecting [trees] trees in the [project]. By donating, you'll be matching [brand]'s contribution, doubling the impact and saving even more trees!",
        template_brandsContribution: "[brand]'s contribution will go directly to help fund the [project]",
        template_impact: 'Impact',
        template_weHaveOffset: 'We Have Offset [total_co2_offset] of CO2 Emissions',
        template_wePartneredWithEcocart: 'We partnered with EcoCart to make every single order carbon neutral (aka net zero)! EcoCart will help us eliminate, capture, or mitigate all of the carbon emissions created from manufacturing and shipping our products.',
        template_learnMore: 'Learn More',
        template_shoppersOffsetTheirCarbonFootprint: '<b>[global_daily_ecocart_order_count] Shoppers</b> Have Offset Their Carbon Footprint Today',
        cta_on: 'On',
        cta_off: 'Off',
        cta_add: 'Add',
        cta_remove: 'Remove',
        cta_close: 'Close',
        cta_learnMore: 'Learn More',
        info_milesDriven: 'miles driven',
        info_businessCards: 'business cards',
        info_arcticIce: 'arctic ice saved',
        info_lightbulbs: 'lightbulbs powered',
        info_co2Emissions: 'of CO₂ emissions',
        info_lbsOfCo2: 'lbs of CO₂ offset',
        info_treesSaved: 'trees saved',
        info_carbonNeutralOrder: 'Carbon Neutral Order',
        info_carbonNeutralOption: 'Carbon neutral option with',
        info_or: 'or',
        info_trees: 'trees',
        info_poweredBy: 'Powered by',
        info_yourImpact: 'Your Climate Impact',
        info_joinShoppers: 'Join over 3 million shoppers and counting',
        info_ourFriendsEstimate: 'Our friends at EcoCart have estimated the carbon footprint of shipping and manufacturing your specific order to be:'
    },
    'en-CA': {
        add_price_for: 'Add [price] for',
        sustainable_shipping: 'Sustainable Shipping',
        shipping_protection: 'Shipping Protection',
        loss_damage_theft_protection: 'Loss, Damage, Theft Protection',
        protect_your_order_and_the_environment: 'Protect Your Order and the Environment',
        protect_your_package_while_offseting_carbon: "Protect your package from loss, theft or damage while offsetting your order's carbon footprint",
        purchasing_this_will_offset_amount_carbon: 'Purchasing This Will Offset [co2_per_item] Of Carbon With <span class="sr-only">EcoCart</span>[ecocart_logo]',
        this_is_like: 'This is like',
        driving_amount_miles_in_an_average_gas_vehicle: '<span class="font-bold">driving [miles_per_item] miles</span> in an average gas vehicle',
        amount_tree_seedlings_grown_for_10_years: '<span class="font-bold">[seedlings_per_item] tree seedlings</span> grown for 10 years',
        template_support_forestry_projects: 'Support Global Forestry Projects',
        template_makeAClimateContribution: 'Make A Climate Contribution',
        template_removeAmtOfPlasticFromOcean: 'Remove <span class="text-primary-400">1 lb</span> of plastic from the ocean',
        template_shopSustainablyByCounteractingPlasticWaste: "Shop Sustainably By Counteracting Your Order's Plastic Waste",
        template_yourContributionWillRemoveAmtPlasticFromOcean: 'Your <span class="text-primary-400">$1.00</span> contribution will remove <span class="text-primary-400">1 lb</span> of plastic from the ocean, counteracting the waste associated with the product and its packaging',
        template_ecocartEnablesUsToPartnerWithOceanPlasticRemovalProjects: 'EcoCart enables us to partner with ocean plastic removal projects in order to help us fight against climate change and counteract the plastic waste associated with each of our orders.',
        template_byCheckingOutWithEcoCartYourOrderWillIncludeAnInvestmentFutureHealth: 'By checking out with EcoCart, your order will include an investment in the future health of the planet by helping clean plastic up from the ocean.',
        template_thisIsEquivalentToAmtPlasticBottles: 'This is equivalent to the amount of plastic found in <span class="text-primary-400">24</span> 16oz water bottles',
        template_accordingToUNEnvironment: 'According to the UN Environment Programme, the average person is responsible for 156 water bottles being discarded as waste every year. So, it would take <span class="text-primary-400">[plastic_to_purchases]</span> purchases to counteract the average consumer\'s annual water bottle waste.',
        template_yourContributionWillHelpFundCleanHub: 'Your contribution will help fund Clean Hub, a certified plastic removal project',
        template_cleanHubIsAVerifiedProject: 'Clean Hub is a verified project dedicated to promoting sustainability and environmental preservation.',
        template_brandHasPartneredWithEcocart: '[brand] has partnered with EcoCart to estimate and offset the carbon footprint of your order by funding verified carbon removal projects around the world.',
        template_yourContributionAtWork: 'Your contribution at work...',
        template_verifiedBy: 'Verified by',
        template_yourOrderWillOffsetAmountCarbon: 'Your Order Will Offset [co2] of Carbon',
        template_byCheckingOutWithEcocartYourOrderWillHelp: 'By checking out with EcoCart, your order will help support the [project]',
        template_shopSustainably: 'Shop Sustainably',
        template_ecomindedShoppersLikeYouHaveOffset: 'Way to go! Eco-minded shoppers like you have <span class="text-primary-400">offset [global_all_time_carbon_offset] of Carbon</span> when checking out with EcoCart.',
        template_ofCarbonOffset: 'of Carbon Offset',
        template_goToStore: 'Go To Store',
        template_werePlantingTreesOnOrders: "We're Planting [eco_incentives_trees] Trees On Orders Over [eco_incentives_threshold]",
        template_youreValueAwayFromPlantingTrees: "You're [amount_needed_for_incentives] Away From Planting [eco_incentives_trees] Trees",
        template_hoorayYourOrderWillPlantTreesMerchantWillAlsoOffset: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. [brand] Will Also Offset The Emissions Of Your Order At Checkout!',
        template_hoorayYourOrderWillPlantTreesMaximizeImpact: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. Maximize Your Impact By Offsetting Your Order At Checkout!',
        template_merchantWillOffsetTwiceAsMuchCarbonAsYourOrderEmits: '<span class="text-primary-400">[brand]</span> will offset twice as much carbon as your order emits',
        template_merchantWillCoverTheCostOfOffsettingYourOrder: '<span class="text-primary-400">[brand]</span> will cover the cost of offsetting your order\'s carbon footprint',
        template_selectEcoCartInYourCartOrAtCheckout: 'Select EcoCart in your cart or at checkout to effortlessly contribute towards a greener future by offsetting',
        template_offsetYourOrdersCarbonFootPrintByCheckingOutWithEcocart: "Offset your order's carbon footprint by checking out with EcoCart",
        template_ecocartEstimatesEachOrdersCarbonFootprintLifeTimeUsage: "EcoCart estimates each order's carbon footprint by calculating variables such as shipping method, distance, materials, manufacturing processes, industry averages, lifetime usage, and more.",
        template_merchantIsOffsettingYourOrdersCarbonFootprintMatchTheirContributionDouble: "[brand] is offsetting your order's carbon footprint, <strong>match their contribution to double the impact</strong>",
        template_merchantWillOffsetYourOrdersCarbonFootprint: '<span class="text-primary-400">[brand]</span> will offset your order\'s carbon footprint',
        template_merchantWillDoubleYourOrdersCarbonOffset: '<span class="text-primary-400">[brand]</span> will double your order\'s carbon offset',
        template_merchantWillDoubleTheImpactByMatching: '<strong>[brand] will double the impact</strong> by matching your contribution',
        template_merchantMadeYourOrderClimatePositive: '[brand] Made Your Order Climate Positive',
        template_offsetTwiceAsMuchCarbonAsYourOrderEmits: 'Offset twice as much carbon as your order emits',
        template_merchantWillMatchQuadrupling: '<span class="text-primary-400">[brand]</span> will match your contribution, quadrupling your order\'s total carbon offset!',
        template_yourContributionTwiceAsMuch: 'Your <span class="text-primary-400">[price]</span> contribution will offset <span class="text-primary-400">[co2]</span> of carbon, twice as much as your order\'s estimated footprint, making making a positive lasting environmental impact',
        template_ecocartEstimatesEachOrderOffest2x: "EcoCart estimates each order's carbon footprint by calculating variables such as shipping method, distance, materials, manufacturing processes, industry averages, lifetime usage, and more.<br><br>By checking out with EcoCart, your order will double the offset of its estimated carbon footprint. making a positive lasting environmental impact.",
        template_yourOrderWillOffset4x: 'Your order will offset 4x the amount of carbon it was estimated to have emitted, making it a positive lasting environmental impact',
        template_merchantWillOffsetTwiceAsMuch: '<span class="text-primary-400">[brand]</span> will offset <span class="text-primary-400">[co2]</span> of carbon, twice as much as your order\'s estimated footprint, making a positive lasting environmental impact',
        template_byContributingYourOrderWillOffset4x: 'By contributing, your order will quadruple the offset of its estimated carbon emissions, achieving four times the environmental impact!',
        template_matchTheirContributionToQuadruple: "Match their contribution to quadruple your order's total carbon offset!",
        template_offsetMyOrdersFootprint: "Offset My Order's Carbon Footprint",
        template_ecocartEstimatesEachOrdersCarbonFootprint: "EcoCart estimates each order's carbon footprint by calculating variables such as shipping method, distance, materials, manufacturing processes, industry averages, and more.",
        template_ecocartPartnersWithCertifiedProjects: 'EcoCart partners with certified projects to offset the carbon emissions from manufacturing and shipping your order.',
        template_merchantOffsetYourCarbonFootprint: '<span class="text-primary-400">[brand]</span> Offset Your Carbon Footprint',
        template_merchantContributionWillHelpFund: '<span class="text-primary-400">[brand]</span><span class="text-primary-400">\'s</span> contribution will help fund <span class="text-primary-400">[project]</span>, a certified carbon offsetting program.',
        template_yourContributionWillHelpFund: 'Your contribution will help fund <span class="text-primary-400">[project]</span>, a certified carbon offsetting program.',
        template_byContributingYouWillOffsetCarbon: 'By contributing <span class="text-primary-400">[price]</span> you will offset <span class="text-primary-400">[co2]</span> of carbon',
        template_merchantWillOffsetCarbon: '<span class="text-primary-400">[brand]</span> will offset <span class="text-primary-400">[co2]</span> of carbon',
        template_yourOrderHasBeenOffsetMaximizeImpact: '<span class="font-semibold">Your order has been offset!</span> Maximize your impact by going climate positive!',
        template_yourContributionWillGoTowards: 'Your contribution will go towards funding the [project]',
        template_withASmallContribution: 'With a small contribution, we can mitigate the emissions calculated from the items in your cart and reduce your carbon footprint',
        template_EcoCartWillCalculateTheFootprint: 'EcoCart will calculate the carbon footprint of your order',
        template_throughCollectiveActionYouAreTakingProactive: 'Through collective action, we can make a real difference in protecting our planet. By offsetting your carbon footprint, you are taking a proactive step towards building a more sustainable future',
        template_merchantHasOffsetYourOrderWith: '[brand] Has Offset Your Order With',
        template_offsetYourOrderAtCheckoutWith: 'Offset Your Order At Checkout With',
        template_yourOrderBreakdown: 'Your order breakdown',
        template_projectIsAVerifiedProject: '<a href="[project_url]" target="_blank" rel="noopener noreferrer">[project]</a> is a verified project dedicated to promoting sustainability and environmental preservation.',
        template_yourOrderWillOffsetAtLeastCarbon: 'Your order will offset at least [co2] of carbon which is more carbon than your order produces, exceeding net zero standards!',
        template_doubleTheImpact: 'Double the impact by matching their contribution doubling the impact of your contribution.',
        template_oneYearTreeAbsorbCO2Amount: 'According to the US Department of Agriculture, in one year a mature tree can absorb more than 48 lbs of carbon dioxide from the atmosphere and release oxygen in exchange',
        template_carbonTreesCanAbsorb: 'This is equivalent to the amount of carbon <span class="text-primary-400">[lbs_co2_absorbed_by_one_tree_annually] trees</span> can absorb in one year',
        template_ecocartProprietaryAlgo: "EcoCart's proprietary algorithm estimates each order's carbon footprint in real-time by calculating variables such as shipping method, distance, manufacturing methods, materials, and more.",
        template_shopSustainablyByOffsetingCO2Footprint: "Shop Sustainably By Offsetting Your Order's Carbon Footprint",
        template_reduceMyOrderCO2: "Reduce My Order's Carbon Footprint",
        template_makeMyOrder: 'Make my order carbon neutral',
        template_makeMyOrderClimatePositive: 'Double the Climate Impact of My Order',
        template_offsetEstimatedCo2e: 'Offset the estimated carbon emissions of your order',
        template_merchantMatchYourContribution: '<span class="text-primary-400">[brand]</span> will match your contribution, doubling the offset of its estimated carbon footprint',
        template_yourOrderHasBeen: 'Your order has been made carbon neutral',
        template_yourOrderWillBe: 'Your order will be made carbon neutral by [brand]',
        template_addToMake: 'Add [price] to make your order carbon neutral',
        template_addToReduce: 'Add [price] to reduce your carbon footprint',
        template_addToMatch: 'Add [price] to match their contribution and double the impact!',
        template_addToDouble: 'Add [price] to double the impact',
        template_byCheckingTheBox: "By checking the box, you'll be protecting [trees] trees in the [project], which are absorbing the CO2 created by your order, making it carbon neutral!",
        template_byDonating: "By donating, you'll be protecting [trees] trees in the [project], which are absorbing the CO2 created by your order, making it carbon neutral!",
        template_byDonatingMatch: "By donating, you'll be protecting [trees] trees in the [project], which are absorbing the CO2 created by your order. [brand] will match your contribution to double the impact and save even more trees.",
        template_carbonNeutralOrderFor: 'Carbon Neutral Order for [price]',
        template_carbonNeutralShipping: 'Carbon Neutral Shipping',
        template_madeCarbonNeutralBy: 'Made Carbon Neutral by [brand]',
        template_brandIsMaking: '[brand] is Making 100% of Orders Carbon Neutral',
        template_brandWillMatch: "[brand] will match your contribution, doubling your order's impact",
        template_brandIsProudMerchantPaying: '[brand] is proud to offset the carbon emissions from your order by protecting [trees] trees in the [project], making it carbon neutral.',
        template_brandIsProudCustomerMatching: "[brand] is proud to offset the carbon emissions from your order by protecting [trees] trees in the [project]. By donating, you'll be matching [brand]'s contribution, doubling the impact and saving even more trees!",
        template_brandsContribution: "[brand]'s contribution will go directly to help fund the [project]",
        template_impact: 'Impact',
        template_weHaveOffset: 'We have offset [total_co2_offset] of CO2',
        template_wePartneredWithEcocart: 'We partnered with EcoCart to make every single order carbon neutral (aka net zero)! EcoCart will help us eliminate, capture, or mitigate all of the carbon emissions created from manufacturing and shipping our products.',
        template_learnMore: 'Learn More',
        template_shoppersOffsetTheirCarbonFootprint: '<b>[global_daily_ecocart_order_count] Shoppers</b> Have Offset Their Carbon Footprint Today',
        cta_on: 'On',
        cta_off: 'Off',
        cta_add: 'Add',
        cta_remove: 'Remove',
        cta_close: 'Close',
        cta_learnMore: 'Learn More',
        info_milesDriven: 'km driven',
        info_businessCards: 'business cards',
        info_arcticIce: 'arctic ice saved',
        info_lightbulbs: 'lightbulbs powered',
        info_co2Emissions: 'of CO₂ emissions',
        info_lbsOfCo2: 'kg of CO₂ offset',
        info_treesSaved: 'trees saved',
        info_carbonNeutralOrder: 'Carbon Neutral Order',
        info_carbonNeutralOption: 'Carbon neutral option with',
        info_or: 'or',
        info_trees: 'trees',
        info_poweredBy: 'Powered by',
        info_yourImpact: 'Your Climate Impact',
        info_joinShoppers: 'Join over 3 million shoppers and counting',
        info_ourFriendsEstimate: 'Our friends at EcoCart have estimated the carbon footprint of shipping and manufacturing your specific order to be:'
    },
    'es': {
        add_price_for: 'Add [price] for',
        sustainable_shipping: 'Sustainable Shipping',
        shipping_protection: 'Shipping Protection',
        loss_damage_theft_protection: 'Loss, Damage, Theft Protection',
        protect_your_order_and_the_environment: 'Protect Your Order and the Environment',
        protect_your_package_while_offseting_carbon: "Protect your package from loss, theft or damage while offsetting your order's carbon footprint",
        purchasing_this_will_offset_amount_carbon: 'Purchasing This Will Offset [co2_per_item] Of Carbon With <span class="sr-only">EcoCart</span>[ecocart_logo]',
        this_is_like: 'This is like',
        driving_amount_miles_in_an_average_gas_vehicle: '<span class="font-bold">driving [miles_per_item] miles</span> in an average gas vehicle',
        amount_tree_seedlings_grown_for_10_years: '<span class="font-bold">[seedlings_per_item] tree seedlings</span> grown for 10 years',
        template_support_forestry_projects: 'Support Global Forestry Projects',
        template_makeAClimateContribution: 'Make A Climate Contribution',
        template_removeAmtOfPlasticFromOcean: 'Remove <span class="text-primary-400">1 lb</span> of plastic from the ocean',
        template_shopSustainablyByCounteractingPlasticWaste: "Shop Sustainably By Counteracting Your Order's Plastic Waste",
        template_yourContributionWillRemoveAmtPlasticFromOcean: 'Your <span class="text-primary-400">$1.00</span> contribution will remove <span class="text-primary-400">1 lb</span> of plastic from the ocean, counteracting the waste associated with the product and its packaging',
        template_ecocartEnablesUsToPartnerWithOceanPlasticRemovalProjects: 'EcoCart enables us to partner with ocean plastic removal projects in order to help us fight against climate change and counteract the plastic waste associated with each of our orders.',
        template_byCheckingOutWithEcoCartYourOrderWillIncludeAnInvestmentFutureHealth: 'By checking out with EcoCart, your order will include an investment in the future health of the planet by helping clean plastic up from the ocean.',
        template_thisIsEquivalentToAmtPlasticBottles: 'This is equivalent to the amount of plastic found in <span class="text-primary-400">24</span> 16oz water bottles',
        template_accordingToUNEnvironment: 'According to the UN Environment Programme, the average person is responsible for 156 water bottles being discarded as waste every year. So, it would take <span class="text-primary-400">[plastic_to_purchases]</span> purchases to counteract the average consumer\'s annual water bottle waste.',
        template_yourContributionWillHelpFundCleanHub: 'Your contribution will help fund Clean Hub, a certified plastic removal project',
        template_cleanHubIsAVerifiedProject: 'Clean Hub is a verified project dedicated to promoting sustainability and environmental preservation.',
        template_brandHasPartneredWithEcocart: '[brand] has partnered with EcoCart to estimate and offset the carbon footprint of your order by funding verified carbon removal projects around the world.',
        template_yourContributionAtWork: 'Your contribution at work...',
        template_verifiedBy: 'Verified by',
        template_yourOrderWillOffsetAmountCarbon: 'Tu pedido compensará [co2] de carbono',
        template_byCheckingOutWithEcocartYourOrderWillHelp: 'Al pagar con EcoCart, tu pedido ayudará a apoyar el [project]',
        template_shopSustainably: 'Shop Sustainably',
        template_ecomindedShoppersLikeYouHaveOffset: 'Way to go! Eco-minded shoppers like you have <span class="text-primary-400">offset [global_all_time_carbon_offset] of Carbon</span> when checking out with EcoCart.',
        template_ofCarbonOffset: 'of Carbon Offset',
        template_goToStore: 'Go To Store',
        template_werePlantingTreesOnOrders: "We're Planting [eco_incentives_trees] Trees On Orders Over [eco_incentives_threshold]",
        template_youreValueAwayFromPlantingTrees: "You're [amount_needed_for_incentives] Away From Planting [eco_incentives_trees] Trees",
        template_hoorayYourOrderWillPlantTreesMerchantWillAlsoOffset: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. [brand] Will Also Offset The Emissions Of Your Order At Checkout!',
        template_hoorayYourOrderWillPlantTreesMaximizeImpact: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. Maximize Your Impact By Offsetting Your Order At Checkout!',
        template_merchantWillOffsetTwiceAsMuchCarbonAsYourOrderEmits: '<span class="text-primary-400">[brand]</span> will offset twice as much carbon as your order emits',
        template_merchantWillCoverTheCostOfOffsettingYourOrder: '<span class="text-primary-400">[brand]</span> will cover the cost of offsetting your order\'s carbon footprint',
        template_selectEcoCartInYourCartOrAtCheckout: 'Select EcoCart in your cart or at checkout to effortlessly contribute towards a greener future by offsetting',
        template_offsetYourOrdersCarbonFootPrintByCheckingOutWithEcocart: "Offset your order's carbon footprint by checking out with EcoCart",
        template_ecocartEstimatesEachOrdersCarbonFootprintLifeTimeUsage: "EcoCart estimates each order's carbon footprint by calculating variables such as shipping method, distance, materials, manufacturing processes, industry averages, lifetime usage, and more.",
        template_merchantIsOffsettingYourOrdersCarbonFootprintMatchTheirContributionDouble: "[brand] is offsetting your order's carbon footprint, <strong>match their contribution to double the impact</strong>",
        template_merchantWillOffsetYourOrdersCarbonFootprint: '<span class="text-primary-400">[brand]</span> will offset your order\'s carbon footprint',
        template_merchantWillDoubleYourOrdersCarbonOffset: '<span class="text-primary-400">[brand]</span> will double your order\'s carbon offset',
        template_merchantWillDoubleTheImpactByMatching: '<strong>[brand] will double the impact</strong> by matching your contribution',
        template_merchantMadeYourOrderClimatePositive: '[brand] Made Your Order Climate Positive',
        template_offsetTwiceAsMuchCarbonAsYourOrderEmits: 'Compensa el doble de carbono de lo que emite tu pedido',
        template_merchantWillMatchQuadrupling: '<span class="text-primary-400">[brand]</span> will match your contribution, quadrupling your order\'s total carbon offset!',
        template_yourContributionTwiceAsMuch: 'Tu <span class="text-primary-400">[price]</span> contribución compensará <span class="text-primary-400">[co2]</span> de carbono, el doble de la huella estimada de tu pedido, generando un impacto ambiental positivo y duradero.',
        template_ecocartEstimatesEachOrderOffest2x: 'EcoCart estima la huella de carbono de cada pedido calculando variables como el método de envío, la distancia, los materiales, los procesos de fabricación, los promedios de la industria, el uso durante toda la vida, y más.<br><br>Al finalizar la compra con EcoCart, tu pedido duplicará la compensación de su huella de carbono estimada, generando un impacto ambiental positivo y duradero.',
        template_yourOrderWillOffset4x: 'Tu pedido compensará 4 veces la cantidad de carbono que se estimó que había emitido, haciendo un impacto ambiental positivo y duradero',
        template_merchantWillOffsetTwiceAsMuch: '<span class="text-primary-400">[brand]</span> compensará <span class="text-primary-400">[co2]</span> de carbono, el doble de la huella estimada de tu pedido, creando un impacto ambiental positivo y duradero',
        template_byContributingYourOrderWillOffset4x: 'Al contribuir, su pedido cuadruplicará la compensación de sus emisiones de carbono estimadas, ¡logrando cuatro veces el impacto ambiental!',
        template_matchTheirContributionToQuadruple: "Match their contribution to quadruple your order's total carbon offset!",
        template_offsetMyOrdersFootprint: "Offset My Order's Carbon Footprint",
        template_ecocartEstimatesEachOrdersCarbonFootprint: 'EcoCart estima la huella de carbono de cada pedido calculando variables como el método de envío, la distancia, los materiales, los procesos de fabricación, los promedios de la industria y más.',
        template_ecocartPartnersWithCertifiedProjects: 'EcoCart se asocia con proyectos certificados para compensar las emisiones de carbono derivadas de la fabricación y el envío de tu pedido.',
        template_merchantOffsetYourCarbonFootprint: '<span class="text-primary-400">[brand]</span> Offset Your Carbon Footprint',
        template_merchantContributionWillHelpFund: '<span class="text-primary-400">[brand]</span><span class="text-primary-400">\'s</span> contribution will help fund <span class="text-primary-400">[project]</span>, a certified carbon offsetting program.',
        template_yourContributionWillHelpFund: 'Tu contribución ayudará a financiar <span class="text-primary-400">[project]</span>, un programa certificado de compensación de carbono.',
        template_byContributingYouWillOffsetCarbon: 'Al contribuir con <span class="text-primary-400">[price]</span> compensarás <span class="text-primary-400">[co2]</span> de carbono.',
        template_merchantWillOffsetCarbon: '<span class="text-primary-400">[brand]</span> compensará <span class="text-primary-400">[co2]</span> de carbono',
        template_yourOrderHasBeenOffsetMaximizeImpact: '<span class="font-semibold">Your order has been offset!</span> Maximize your impact by going climate positive!',
        template_yourContributionWillGoTowards: 'Your contribution will go towards funding the [project]',
        template_withASmallContribution: 'With a small contribution, we can mitigate the emissions calculated from the items in your cart and reduce your carbon footprint',
        template_EcoCartWillCalculateTheFootprint: 'EcoCart will calculate the carbon footprint of your order',
        template_throughCollectiveActionYouAreTakingProactive: 'Through collective action, we can make a real difference in protecting our planet. By offsetting your carbon footprint, you are taking a proactive step towards building a more sustainable future',
        template_merchantHasOffsetYourOrderWith: '[brand] ha compensado tu pedido con',
        template_offsetYourOrderAtCheckoutWith: 'Compensa tu pedido al finalizar la compra con',
        template_yourOrderBreakdown: 'Desglose de tu pedido',
        template_projectIsAVerifiedProject: '<a href="[project_url]" target="_blank" rel="noopener noreferrer">[project]</a> es un proyecto verificado dedicado a promover la sostenibilidad y la preservación del medio ambiente.',
        template_yourOrderWillOffsetAtLeastCarbon: 'Your order will offset at least [co2] of carbon which is more carbon than your order produces, exceeding net zero standards!',
        template_doubleTheImpact: 'Duplica el impacto igualando su contribución, duplicando el impacto de tu contribución.',
        template_oneYearTreeAbsorbCO2Amount: 'Según el Departamento de Agricultura de Estados Unidos, en un año un árbol maduro puede absorber más de 48 libras de dióxido de carbono de la atmósfera y liberar oxígeno a cambio.',
        template_carbonTreesCanAbsorb: 'Esto equivale a la cantidad de carbono que <span class="text-primary-400">[lbs_co2_absorbed_by_one_tree_annually] árboles</span> pueden absorber en un año',
        template_ecocartProprietaryAlgo: 'El algoritmo patentado de EcoCart estima la huella de carbono de cada pedido en tiempo real, calculando variables como el método de envío, la distancia, los métodos de fabricación, los materiales y más.',
        template_shopSustainablyByOffsetingCO2Footprint: 'Compra de manera sostenible compensando la huella de carbono de tu pedido',
        template_reduceMyOrderCO2: 'Reduzca la huella de carbono de mi pedido ',
        template_makeMyOrder: 'Hacer mi pedido neutral en carbono',
        template_makeMyOrderClimatePositive: 'Duplica el Impacto Climático de Mi Pedido',
        template_offsetEstimatedCo2e: 'Compense las emisiones de carbono estimadas de su pedido',
        template_merchantMatchYourContribution: '<span class="text-primary-400">[brand]</span> igualará tu contribución, duplicando la compensación de su huella de carbono estimada',
        template_yourOrderHasBeen: 'Su pedido se ha hecho carbono neutral',
        template_yourOrderWillBe: 'Su pedido será neutralizado en carbono por [brand]',
        template_addToMake: 'Agregue [price] para que su pedido sea neutral en carbono',
        template_addToReduce: 'Agregue [price] para reducir su huella de carbono',
        template_addToMatch: '¡Agregue [price] para igualar su contribución y duplicar el impacto!',
        template_addToDouble: 'Agregue [price] para duplicar el impacto',
        template_byCheckingTheBox: 'Al marcar la casilla, estarás protegiendo [trees] árboles en el [project], que están absorbiendo el CO2 creado por tu pedido, ¡haciéndolo neutral en carbono!',
        template_byDonating: 'Al donar, estarás protegiendo [trees] árboles en el [project], que están absorbiendo el CO2 creado por tu pedido, ¡haciéndolo neutral en carbono!',
        template_byDonatingMatch: 'Al donar, estarás protegiendo [trees] árboles en el [project], que están absorbiendo el CO2 generado por tu pedido. [brand] igualará su contribución para duplicar el impacto y salvar aún más árboles.',
        template_carbonNeutralOrderFor: 'Orden Carbono Neutral por [price]',
        template_carbonNeutralShipping: 'Envío Carbono Neutral',
        template_madeCarbonNeutralBy: 'Fabricado con carbono neutral por [brand]',
        template_brandIsMaking: '[brand] realiza el 100% de los pedidos con emisiones neutras de carbono',
        template_brandWillMatch: '¡[brand] igualará su contribución y duplicará el impacto!',
        template_brandIsProudMerchantPaying: '[brand] se enorgullece de compensar las emisiones de carbono de su pedido al proteger [trees] árboles en el [project], haciéndolo neutral en carbono.',
        template_brandIsProudCustomerMatching: '[brand] se enorgullece de compensar las emisiones de carbono de su pedido al proteger [trees] árboles en el [project]. ¡Al donar, igualará la contribución de [brand], duplicará el impacto y salvará aún más árboles!',
        template_brandsContribution: 'La contribución de [brand] se destinará directamente a ayudar a financiar el [project]',
        template_impact: 'Impacto',
        template_weHaveOffset: 'Hemos compensado [total_co2_offset] libras de emisiones de carbono',
        template_wePartneredWithEcocart: 'We partnered with EcoCart to make every single order carbon neutral (aka net zero)! EcoCart will help us eliminate, capture, or mitigate all of the carbon emissions created from manufacturing and shipping our products.',
        template_learnMore: 'Learn More',
        template_shoppersOffsetTheirCarbonFootprint: '<b>[global_daily_ecocart_order_count] Shoppers</b> Have Offset Their Carbon Footprint Today',
        cta_on: 'Sí',
        cta_off: 'No',
        cta_add: 'Agregar',
        cta_remove: 'Eliminar',
        cta_close: 'Cerrar',
        cta_learnMore: 'Conoce Más',
        info_milesDriven: 'km recorridas',
        info_businessCards: 'tarjetas de presentación',
        info_arcticIce: 'hielo ártico salvado',
        info_lightbulbs: 'encendido bombillas',
        info_trees: 'árboles',
        info_co2Emissions: 'de emisiones de CO₂',
        info_lbsOfCo2: 'de emisiones de CO₂',
        info_treesSaved: 'árboles salvados',
        info_carbonNeutralOrder: 'Orden Carbono Neutral',
        info_carbonNeutralOption: 'Opción carbono neutral con',
        info_or: 'o',
        info_poweredBy: 'Respaldado por',
        info_yourImpact: 'Su impacto climático',
        info_joinShoppers: 'Únase a más de 3 millones de compradores y contando',
        info_ourFriendsEstimate: 'Nuestros amigos en EcoCart han estimado que la huella de carbono del envió y la fabricación de su pedido es:'
    },
    'it': {
        add_price_for: 'Add [price] for',
        sustainable_shipping: 'Sustainable Shipping',
        shipping_protection: 'Shipping Protection',
        loss_damage_theft_protection: 'Loss, Damage, Theft Protection',
        protect_your_order_and_the_environment: 'Protect Your Order and the Environment',
        protect_your_package_while_offseting_carbon: "Protect your package from loss, theft or damage while offsetting your order's carbon footprint",
        purchasing_this_will_offset_amount_carbon: 'Purchasing This Will Offset [co2_per_item] Of Carbon With <span class="sr-only">EcoCart</span>[ecocart_logo]',
        this_is_like: 'This is like',
        driving_amount_miles_in_an_average_gas_vehicle: '<span class="font-bold">driving [miles_per_item] miles</span> in an average gas vehicle',
        amount_tree_seedlings_grown_for_10_years: '<span class="font-bold">[seedlings_per_item] tree seedlings</span> grown for 10 years',
        template_support_forestry_projects: 'Support Global Forestry Projects',
        template_makeAClimateContribution: 'Make A Climate Contribution',
        template_removeAmtOfPlasticFromOcean: 'Remove <span class="text-primary-400">1 lb</span> of plastic from the ocean',
        template_shopSustainablyByCounteractingPlasticWaste: "Shop Sustainably By Counteracting Your Order's Plastic Waste",
        template_yourContributionWillRemoveAmtPlasticFromOcean: 'Your <span class="text-primary-400">$1.00</span> contribution will remove <span class="text-primary-400">1 lb</span> of plastic from the ocean, counteracting the waste associated with the product and its packaging',
        template_ecocartEnablesUsToPartnerWithOceanPlasticRemovalProjects: 'EcoCart enables us to partner with ocean plastic removal projects in order to help us fight against climate change and counteract the plastic waste associated with each of our orders.',
        template_byCheckingOutWithEcoCartYourOrderWillIncludeAnInvestmentFutureHealth: 'By checking out with EcoCart, your order will include an investment in the future health of the planet by helping clean plastic up from the ocean.',
        template_thisIsEquivalentToAmtPlasticBottles: 'This is equivalent to the amount of plastic found in <span class="text-primary-400">24</span> 16oz water bottles',
        template_accordingToUNEnvironment: 'According to the UN Environment Programme, the average person is responsible for 156 water bottles being discarded as waste every year. So, it would take <span class="text-primary-400">[plastic_to_purchases]</span> purchases to counteract the average consumer\'s annual water bottle waste.',
        template_yourContributionWillHelpFundCleanHub: 'Your contribution will help fund Clean Hub, a certified plastic removal project',
        template_cleanHubIsAVerifiedProject: 'Clean Hub is a verified project dedicated to promoting sustainability and environmental preservation.',
        template_brandHasPartneredWithEcocart: '[brand] has partnered with EcoCart to estimate and offset the carbon footprint of your order by funding verified carbon removal projects around the world.',
        template_yourContributionAtWork: 'Your contribution at work...',
        template_verifiedBy: 'Verified by',
        template_yourOrderWillOffsetAmountCarbon: 'Il tuo ordine compenserà [co2] di carbonio',
        template_byCheckingOutWithEcocartYourOrderWillHelp: 'Effettuando il checkout con EcoCart, il tuo ordine contribuirà a sostenere il [project]',
        template_shopSustainably: 'Shop Sustainably',
        template_ecomindedShoppersLikeYouHaveOffset: 'Way to go! Eco-minded shoppers like you have <span class="text-primary-400">offset [global_all_time_carbon_offset] of Carbon</span> when checking out with EcoCart.',
        template_ofCarbonOffset: 'of Carbon Offset',
        template_goToStore: 'Go To Store',
        template_werePlantingTreesOnOrders: "We're Planting [eco_incentives_trees] Trees On Orders Over [eco_incentives_threshold]",
        template_youreValueAwayFromPlantingTrees: "You're [amount_needed_for_incentives] Away From Planting [eco_incentives_trees] Trees",
        template_hoorayYourOrderWillPlantTreesMerchantWillAlsoOffset: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. [brand] Will Also Offset The Emissions Of Your Order At Checkout!',
        template_hoorayYourOrderWillPlantTreesMaximizeImpact: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. Maximize Your Impact By Offsetting Your Order At Checkout!',
        template_merchantWillOffsetTwiceAsMuchCarbonAsYourOrderEmits: '<span class="text-primary-400">[brand]</span> will offset twice as much carbon as your order emits',
        template_merchantWillCoverTheCostOfOffsettingYourOrder: '<span class="text-primary-400">[brand]</span> will cover the cost of offsetting your order\'s carbon footprint',
        template_selectEcoCartInYourCartOrAtCheckout: 'Select EcoCart in your cart or at checkout to effortlessly contribute towards a greener future by offsetting',
        template_offsetYourOrdersCarbonFootPrintByCheckingOutWithEcocart: "Offset your order's carbon footprint by checking out with EcoCart",
        template_ecocartEstimatesEachOrdersCarbonFootprintLifeTimeUsage: "EcoCart estimates each order's carbon footprint by calculating variables such as shipping method, distance, materials, manufacturing processes, industry averages, lifetime usage, and more.",
        template_merchantIsOffsettingYourOrdersCarbonFootprintMatchTheirContributionDouble: "[brand] is offsetting your order's carbon footprint, <strong>match their contribution to double the impact</strong>",
        template_merchantWillOffsetYourOrdersCarbonFootprint: '<span class="text-primary-400">[brand]</span> will offset your order\'s carbon footprint',
        template_merchantWillDoubleYourOrdersCarbonOffset: '<span class="text-primary-400">[brand]</span> will double your order\'s carbon offset',
        template_merchantWillDoubleTheImpactByMatching: '<strong>[brand] will double the impact</strong> by matching your contribution',
        template_merchantMadeYourOrderClimatePositive: '[brand] Made Your Order Climate Positive',
        template_offsetTwiceAsMuchCarbonAsYourOrderEmits: 'Compensare il doppio delle emissioni di carbonio della tua ordinazione',
        template_merchantWillMatchQuadrupling: '<span class="text-primary-400">[brand]</span> will match your contribution, quadrupling your order\'s total carbon offset!',
        template_yourContributionTwiceAsMuch: 'Il tuo <span class="text-primary-400">[price]</span> contributo compenserà <span class="text-primary-400">[co2]</span> di carbonio, il doppio dell\'impronta stimata del tuo ordine, creando un impatto ambientale positivo e duraturo.',
        template_ecocartEstimatesEachOrderOffest2x: "EcoCart stima l'impronta di carbonio di ogni ordine calcolando variabili come il metodo di spedizione, la distanza, i materiali, i processi di produzione, le medie del settore, l'uso nel corso della vita e altro ancora.<br><br>Effettuando il checkout con EcoCart, il tuo ordine raddoppierà la compensazione della sua impronta di carbonio stimata, creando un impatto ambientale positivo e duraturo.",
        template_yourOrderWillOffset4x: 'Il tuo ordine compenserà 4 volte la quantità di carbonio che si stima abbia emesso, rendendolo un impatto ambientale positivo e duraturo',
        template_merchantWillOffsetTwiceAsMuch: '<span class="text-primary-400">[brand]</span> compenserà <span class="text-primary-400">[co2]</span> di carbonio, il doppio dell\'impronta stimata del tuo ordine, creando un impatto ambientale positivo e duraturo',
        template_byContributingYourOrderWillOffset4x: 'Contribuendo, il tuo ordine quadruplicherà la compensazione delle sue emissioni di carbonio stimate, ottenendo un impatto ambientale quattro volte maggiore!',
        template_matchTheirContributionToQuadruple: "Match their contribution to quadruple your order's total carbon offset!",
        template_offsetMyOrdersFootprint: "Offset My Order's Carbon Footprint",
        template_ecocartEstimatesEachOrdersCarbonFootprint: "EcoCart stima l'impronta di carbonio di ogni ordine calcolando variabili come il metodo di spedizione, la distanza, i materiali, i processi di produzione, le medie dell'industria e altro ancora.",
        template_ecocartPartnersWithCertifiedProjects: 'EcoCart collabora con progetti certificati per compensare le emissioni di carbonio derivanti dalla produzione e dalla spedizione del tuo ordine.',
        template_merchantOffsetYourCarbonFootprint: '<span class="text-primary-400">[brand]</span> Offset Your Carbon Footprint',
        template_merchantContributionWillHelpFund: '<span class="text-primary-400">[brand]</span><span class="text-primary-400">\'s</span> contribution will help fund <span class="text-primary-400">[project]</span>, a certified carbon offsetting program.',
        template_yourContributionWillHelpFund: 'Il tuo contributo aiuterà a finanziare <span class="text-primary-400">[project]</span>, un programma certificato di compensazione delle emissioni di carbonio.',
        template_byContributingYouWillOffsetCarbon: 'Contribuendo con <span class="text-primary-400">[price]</span> compenserai <span class="text-primary-400">[co2]</span> di carbonio.',
        template_merchantWillOffsetCarbon: '<span class="text-primary-400">[brand]</span> compenserà <span class="text-primary-400">[co2]</span> di carbonio',
        template_yourOrderHasBeenOffsetMaximizeImpact: '<span class="font-semibold">Your order has been offset!</span> Maximize your impact by going climate positive!',
        template_yourContributionWillGoTowards: 'Your contribution will go towards funding the [project]',
        template_withASmallContribution: 'With a small contribution, we can mitigate the emissions calculated from the items in your cart and reduce your carbon footprint',
        template_EcoCartWillCalculateTheFootprint: 'EcoCart will calculate the carbon footprint of your order',
        template_throughCollectiveActionYouAreTakingProactive: 'Through collective action, we can make a real difference in protecting our planet. By offsetting your carbon footprint, you are taking a proactive step towards building a more sustainable future',
        template_merchantHasOffsetYourOrderWith: '[brand] ha compensato il tuo ordine con',
        template_offsetYourOrderAtCheckoutWith: 'Compensa il tuo ordine al momento del checkout con',
        template_yourOrderBreakdown: 'Dettaglio del tuo ordine',
        template_projectIsAVerifiedProject: '<a href="[project_url]" target="_blank" rel="noopener noreferrer">[project]</a> è un progetto verificato dedicato alla promozione della sostenibilità e della conservazione dell\'ambiente.',
        template_yourOrderWillOffsetAtLeastCarbon: 'Your order will offset at least [co2] of carbon which is more carbon than your order produces, exceeding net zero standards!',
        template_doubleTheImpact: "Raddoppia l'impatto abbinando il loro contributo raddoppiando l'impatto del tuo contributo.",
        template_oneYearTreeAbsorbCO2Amount: "Secondo il Dipartimento dell'Agricoltura degli Stati Uniti, in un anno un albero maturo può assorbire più di 48 libbre di anidride carbonica dall'atmosfera e rilasciare ossigeno in cambio.",
        template_carbonTreesCanAbsorb: 'Questo equivale alla quantità di carbonio che <span class="text-primary-400">[lbs_co2_absorbed_by_one_tree_annually] alberi</span> possono assorbire in un anno',
        template_ecocartProprietaryAlgo: "L'algoritmo proprietario di EcoCart stima in tempo reale l'impronta di carbonio di ogni ordine calcolando variabili come il metodo di spedizione, la distanza, i metodi di produzione, i materiali e altro ancora.",
        template_shopSustainablyByOffsetingCO2Footprint: "Acquista in modo sostenibile compensando l'impronta di carbonio del tuo ordine",
        template_reduceMyOrderCO2: "Riduci l'impronta di carbonio del mio ordine",
        template_makeMyOrder: 'Rendi il mio ordine a emissioni zero',
        template_makeMyOrderClimatePositive: "Raddoppia l'Impatto Climatico del Mio Ordine",
        template_offsetEstimatedCo2e: 'Compensa le stime di emissioni di carbonio del tuo ordine',
        template_merchantMatchYourContribution: '<span class="text-primary-400">[brand]</span> raddoppierà il tuo contributo, raddoppiando la compensazione della sua impronta di carbonio stimata',
        template_yourOrderHasBeen: 'Il tuo ordine è stato reso carbon neutral',
        template_yourOrderWillBe: 'Il tuo ordine sarà reso carbon neutral da [brand]',
        template_addToMake: 'Aggiungi [price] per rendere il tuo ordine a emissioni zero',
        template_addToReduce: 'Aggiungi [price] per ridurre la tua impronta di carbonio',
        template_addToMatch: "Aggiungi [price] per abbinare il loro contributo e raddoppiare l'impatto!",
        template_addToDouble: "Aggiungi [price] per raddoppiare l'impatto",
        template_byCheckingTheBox: 'Selezionando la casella, proteggerai [trees] alberi nel [project], che stanno assorbendo la CO2 creata dal tuo ordine, rendendolo carbon neutral!',
        template_byDonating: 'Donando, proteggerai [trees] gli alberi del [project], che stanno assorbendo la CO2 creata dal tuo ordine, rendendolo carbon neutral!',
        template_byDonatingMatch: "Donando, proteggerai [trees] alberi nel [project], che stanno assorbendo la CO2 creata dal tuo ordine. [brand] corrisponderà al tuo contributo per raddoppiare l'impatto e salvare ancora più alberi.",
        template_carbonNeutralOrderFor: 'Ordine a emissioni zero per [price]',
        template_carbonNeutralShipping: 'Spedizione a emissioni zero',
        template_madeCarbonNeutralBy: 'Reso Carbon Neutral da [brand]',
        template_brandIsMaking: '[brand] sta realizzando il 100% degli ordini a emissioni zero',
        template_brandWillMatch: "[brand] corrisponderà al tuo contributo e raddoppierà l'impatto!",
        template_brandIsProudMerchantPaying: '[brand] è orgoglioso di compensare le emissioni di carbonio del tuo ordine proteggendo [trees] alberi nel [project], rendendolo carbon neutral.',
        template_brandIsProudCustomerMatching: "[brand] è orgoglioso di compensare le emissioni di carbonio del tuo ordine proteggendo [trees] alberi nel [project]. Donando, corrisponderai al contributo di [brand], raddoppiando l'impatto e salvando ancora più alberi!",
        template_brandsContribution: 'Il contributo di [brand] andrà direttamente a finanziare il [project]',
        template_impact: 'Impatto',
        template_weHaveOffset: 'Abbiamo compensato [total_co2_offset] libbre di emissioni di carbonio',
        template_wePartneredWithEcocart: 'We partnered with EcoCart to make every single order carbon neutral (aka net zero)! EcoCart will help us eliminate, capture, or mitigate all of the carbon emissions created from manufacturing and shipping our products.',
        template_learnMore: 'Learn More',
        template_shoppersOffsetTheirCarbonFootprint: '<b>[global_daily_ecocart_order_count] Shoppers</b> Have Offset Their Carbon Footprint Today',
        cta_on: 'Sì',
        cta_off: 'No',
        cta_add: 'Aggiungi',
        cta_remove: 'Rimuovi',
        cta_close: 'Chiudi',
        cta_learnMore: 'Leggi di più',
        info_milesDriven: 'km guidate',
        info_businessCards: 'biglietti da visita',
        info_arcticIce: 'ghiaccio artico salvato',
        info_lightbulbs: 'luci alimentate',
        info_trees: 'alberi',
        info_co2Emissions: 'di emissioni di CO₂',
        info_lbsOfCo2: 'di emissioni di CO₂',
        info_treesSaved: 'alberi salvati',
        info_carbonNeutralOrder: 'Rendi il mio ordine a emissioni zero',
        info_carbonNeutralOption: 'Opzione 0 emissioni con',
        info_or: 'o',
        info_poweredBy: 'Offerto da',
        info_yourImpact: 'Il tuo impatto ambientale',
        info_joinShoppers: 'Unisciti a oltre 3 milioni di acquirenti e oltre',
        info_ourFriendsEstimate: "Il nostro partner EcoCart ha stimato che l'impronta della spedizione e della produzione del tuo ordine sia:"
    },
    'pl': {
        add_price_for: 'Add [price] for',
        sustainable_shipping: 'Sustainable Shipping',
        shipping_protection: 'Shipping Protection',
        loss_damage_theft_protection: 'Loss, Damage, Theft Protection',
        protect_your_order_and_the_environment: 'Protect Your Order and the Environment',
        protect_your_package_while_offseting_carbon: "Protect your package from loss, theft or damage while offsetting your order's carbon footprint",
        purchasing_this_will_offset_amount_carbon: 'Purchasing This Will Offset [co2_per_item] Of Carbon With <span class="sr-only">EcoCart</span>[ecocart_logo]',
        this_is_like: 'This is like',
        driving_amount_miles_in_an_average_gas_vehicle: '<span class="font-bold">driving [miles_per_item] miles</span> in an average gas vehicle',
        amount_tree_seedlings_grown_for_10_years: '<span class="font-bold">[seedlings_per_item] tree seedlings</span> grown for 10 years',
        template_support_forestry_projects: 'Support Global Forestry Projects',
        template_makeAClimateContribution: 'Make A Climate Contribution',
        template_removeAmtOfPlasticFromOcean: 'Remove <span class="text-primary-400">1 lb</span> of plastic from the ocean',
        template_shopSustainablyByCounteractingPlasticWaste: "Shop Sustainably By Counteracting Your Order's Plastic Waste",
        template_yourContributionWillRemoveAmtPlasticFromOcean: 'Your <span class="text-primary-400">$1.00</span> contribution will remove <span class="text-primary-400">1 lb</span> of plastic from the ocean, counteracting the waste associated with the product and its packaging',
        template_ecocartEnablesUsToPartnerWithOceanPlasticRemovalProjects: 'EcoCart enables us to partner with ocean plastic removal projects in order to help us fight against climate change and counteract the plastic waste associated with each of our orders.',
        template_byCheckingOutWithEcoCartYourOrderWillIncludeAnInvestmentFutureHealth: 'By checking out with EcoCart, your order will include an investment in the future health of the planet by helping clean plastic up from the ocean.',
        template_thisIsEquivalentToAmtPlasticBottles: 'This is equivalent to the amount of plastic found in <span class="text-primary-400">24</span> 16oz water bottles',
        template_accordingToUNEnvironment: 'According to the UN Environment Programme, the average person is responsible for 156 water bottles being discarded as waste every year. So, it would take <span class="text-primary-400">[plastic_to_purchases]</span> purchases to counteract the average consumer\'s annual water bottle waste.',
        template_yourContributionWillHelpFundCleanHub: 'Your contribution will help fund Clean Hub, a certified plastic removal project',
        template_cleanHubIsAVerifiedProject: 'Clean Hub is a verified project dedicated to promoting sustainability and environmental preservation.',
        template_brandHasPartneredWithEcocart: '[brand] has partnered with EcoCart to estimate and offset the carbon footprint of your order by funding verified carbon removal projects around the world.',
        template_yourContributionAtWork: 'Your contribution at work...',
        template_verifiedBy: 'Verified by',
        template_yourOrderWillOffsetAmountCarbon: 'Twoje zamówienie zrekompensuje [co2] dwutlenek węgla',
        template_byCheckingOutWithEcocartYourOrderWillHelp: 'Płacąc za pomocą EcoCart, Twoje zamówienie pomoże wesprzeć [project]',
        template_shopSustainably: 'Shop Sustainably',
        template_ecomindedShoppersLikeYouHaveOffset: 'Way to go! Eco-minded shoppers like you have <span class="text-primary-400">offset [global_all_time_carbon_offset] of Carbon</span> when checking out with EcoCart.',
        template_ofCarbonOffset: 'of Carbon Offset',
        template_goToStore: 'Go To Store',
        template_werePlantingTreesOnOrders: "We're Planting [eco_incentives_trees] Trees On Orders Over [eco_incentives_threshold]",
        template_youreValueAwayFromPlantingTrees: "You're [amount_needed_for_incentives] Away From Planting [eco_incentives_trees] Trees",
        template_hoorayYourOrderWillPlantTreesMerchantWillAlsoOffset: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. [brand] Will Also Offset The Emissions Of Your Order At Checkout!',
        template_hoorayYourOrderWillPlantTreesMaximizeImpact: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. Maximize Your Impact By Offsetting Your Order At Checkout!',
        template_merchantWillOffsetTwiceAsMuchCarbonAsYourOrderEmits: '<span class="text-primary-400">[brand]</span> will offset twice as much carbon as your order emits',
        template_merchantWillCoverTheCostOfOffsettingYourOrder: '<span class="text-primary-400">[brand]</span> will cover the cost of offsetting your order\'s carbon footprint',
        template_selectEcoCartInYourCartOrAtCheckout: 'Select EcoCart in your cart or at checkout to effortlessly contribute towards a greener future by offsetting',
        template_offsetYourOrdersCarbonFootPrintByCheckingOutWithEcocart: "Offset your order's carbon footprint by checking out with EcoCart",
        template_ecocartEstimatesEachOrdersCarbonFootprintLifeTimeUsage: "EcoCart estimates each order's carbon footprint by calculating variables such as shipping method, distance, materials, manufacturing processes, industry averages, lifetime usage, and more.",
        template_merchantIsOffsettingYourOrdersCarbonFootprintMatchTheirContributionDouble: "[brand] is offsetting your order's carbon footprint, <strong>match their contribution to double the impact</strong>",
        template_merchantWillOffsetYourOrdersCarbonFootprint: '<span class="text-primary-400">[brand]</span> will offset your order\'s carbon footprint',
        template_merchantWillDoubleYourOrdersCarbonOffset: '<span class="text-primary-400">[brand]</span> will double your order\'s carbon offset',
        template_merchantWillDoubleTheImpactByMatching: '<strong>[brand] will double the impact</strong> by matching your contribution',
        template_merchantMadeYourOrderClimatePositive: '[brand] Made Your Order Climate Positive',
        template_offsetTwiceAsMuchCarbonAsYourOrderEmits: 'Zrekompensuj dwa razy więcej dwutlenku węgla niż emituje Twoje zamówienie',
        template_merchantWillMatchQuadrupling: '<span class="text-primary-400">[brand]</span> will match your contribution, quadrupling your order\'s total carbon offset!',
        template_yourContributionTwiceAsMuch: 'Twoja <span class="text-primary-400">[price]</span> składka zrównoważy <span class="text-primary-400">[co2]</span> węgla, dwa razy więcej niż szacowany ślad węglowy Twojego zamówienia, co spowoduje pozytywny i trwały wpływ na środowisko.',
        template_ecocartEstimatesEachOrderOffest2x: 'EcoCart szacuje ślad węglowy każdego zamówienia, obliczając zmienne takie jak metoda wysyłki, odległość, materiały, procesy produkcyjne, średnie branżowe, czas użytkowania i inne.<br><br>Dokonując zakupu za pomocą EcoCart, Twoje zamówienie podwoi kompensację szacowanego śladu węglowego, co przyniesie pozytywny, trwały wpływ na środowisko.',
        template_yourOrderWillOffset4x: 'Twoje zamówienie zrównoważy 4x ilość węgla, którą oszacowano, że wyemitowało, co sprawi, że będzie miało pozytywny, trwały wpływ na środowisko.',
        template_merchantWillOffsetTwiceAsMuch: '<span class="text-primary-400">[brand]</span> zrównoważy <span class="text-primary-400">[co2]</span> węgla, dwa razy więcej niż szacowany ślad węglowy Twojego zamówienia, co spowoduje pozytywny, trwały wpływ na środowisko',
        template_byContributingYourOrderWillOffset4x: 'Poprzez wkład, Twoje zamówienie poczwórnie zrównoważy szacowane emisje dwutlenku węgla, osiągając czterokrotnie większy wpływ na środowisko!',
        template_matchTheirContributionToQuadruple: "Match their contribution to quadruple your order's total carbon offset!",
        template_offsetMyOrdersFootprint: "Offset My Order's Carbon Footprint",
        template_ecocartEstimatesEachOrdersCarbonFootprint: 'EcoCart oszacowuje ślad węglowy każdego zamówienia, obliczając zmienne takie jak metoda wysyłki, odległość, materiały, procesy produkcyjne, średnie branżowe i wiele więcej.',
        template_ecocartPartnersWithCertifiedProjects: 'EcoCart współpracuje z certyfikowanymi projektami w celu zrekompensowania emisji dwutlenku węgla związanych z produkcją i wysyłką Twojego zamówienia.',
        template_merchantOffsetYourCarbonFootprint: '<span class="text-primary-400">[brand]</span> Offset Your Carbon Footprint',
        template_merchantContributionWillHelpFund: '<span class="text-primary-400">[brand]</span><span class="text-primary-400">\'s</span> contribution will help fund <span class="text-primary-400">[project]</span>, a certified carbon offsetting program.',
        template_yourContributionWillHelpFund: 'Twoja dotacja pomoże w finansowaniu programu <span class="text-primary-400">[project]</span>, certyfikowanego programu kompensacji emisji dwutlenku węgla.',
        template_byContributingYouWillOffsetCarbon: 'Przyczyniając się kwotą <span class="text-primary-400">[price]</span> zrekompensujesz <span class="text-primary-400">[co2]</span> dwutlenek węgla.',
        template_merchantWillOffsetCarbon: '<span class="text-primary-400">[brand]</span> zrekompensuje <span class="text-primary-400">[co2]</span> dwutlenek węgla',
        template_yourOrderHasBeenOffsetMaximizeImpact: '<span class="font-semibold">Your order has been offset!</span> Maximize your impact by going climate positive!',
        template_yourContributionWillGoTowards: 'Your contribution will go towards funding the [project]',
        template_withASmallContribution: 'With a small contribution, we can mitigate the emissions calculated from the items in your cart and reduce your carbon footprint',
        template_EcoCartWillCalculateTheFootprint: 'EcoCart will calculate the carbon footprint of your order',
        template_throughCollectiveActionYouAreTakingProactive: 'Through collective action, we can make a real difference in protecting our planet. By offsetting your carbon footprint, you are taking a proactive step towards building a more sustainable future',
        template_merchantHasOffsetYourOrderWith: '[brand] skompensował Twoje zamówienie za pomocą',
        template_offsetYourOrderAtCheckoutWith: 'Skompensuj swoje zamówienie przy kasie za pomocą',
        template_yourOrderBreakdown: 'Podział Twojego zamówienia',
        template_projectIsAVerifiedProject: '<a href="[project_url]" target="_blank" rel="noopener noreferrer">[project]</a> to zweryfikowany projekt poświęcony promowaniu zrównoważonego rozwoju i ochronie środowiska.',
        template_yourOrderWillOffsetAtLeastCarbon: 'Your order will offset at least [co2] of carbon which is more carbon than your order produces, exceeding net zero standards!',
        template_doubleTheImpact: 'Podwój efekt, dopasowując ich wkład, podwajając efekt twojego wkładu.',
        template_oneYearTreeAbsorbCO2Amount: 'Według Departamentu Rolnictwa Stanów Zjednoczonych, w ciągu jednego roku dorosłe drzewo może pochłonąć ponad 48 funtów dwutlenku węgla z atmosfery i uwolnić tlen w zamian.',
        template_carbonTreesCanAbsorb: 'Jest to równoważne ilości węgla, jaką <span class="text-primary-400">[lbs_co2_absorbed_by_one_tree_annually] drzewa</span> mogą absorbować w ciągu roku',
        template_ecocartProprietaryAlgo: 'Własny algorytm EcoCartu w czasie rzeczywistym szacuje ślad węglowy każdego zamówienia, obliczając zmienne takie jak metoda wysyłki, odległość, metody produkcji, materiały i wiele więcej.',
        template_shopSustainablyByOffsetingCO2Footprint: 'Kupuj zrównoważone produkty, rekompensując ślad węglowy Twojego zamówienia',
        template_reduceMyOrderCO2: 'Zmniejsz ślad węglowy mojego zamówienia',
        template_makeMyOrder: 'Spraw, aby moje zamówienie było neutralne pod względem emisji dwutlenku węgla',
        template_makeMyOrderClimatePositive: 'Podwój wpływ klimatyczny mojego zamówienia',
        template_offsetEstimatedCo2e: 'Skompensuj szacowane emisje węgla z Twojego zamówienia',
        template_merchantMatchYourContribution: '<span class="text-primary-400">[brand]</span> dopasuje twoją wpłatę, podwajając kompensację szacowanego śladu węglowego',
        template_yourOrderHasBeen: 'Twoje zamówienie zostało zneutralizowane pod względem emisji dwutlenku węgla',
        template_yourOrderWillBe: 'Twoje zamówienie będzie neutralne pod względem emisji dwutlenku węgla przez [brand]',
        template_addToMake: 'Dodaj [price], aby zamówienie było neutralne pod względem emisji dwutlenku węgla',
        template_addToReduce: 'Dodaj [price], aby zmniejszyć swój ślad węglowy',
        template_addToMatch: 'Dodaj [price], aby dopasować ich wkład i podwoić wpływ!',
        template_addToDouble: 'Dodaj [price], aby podwoić wpływ',
        template_byCheckingTheBox: 'Zaznaczając to pole, będziesz chronić [trees] w [project], które pochłaniają CO2 wytworzone przez Twoje zamówienie, czyniąc je neutralnymi pod względem emisji dwutlenku węgla!',
        template_byDonating: 'Przekazując darowiznę, będziesz chronić [trees] drzew w [project], które pochłaniają CO2 wytworzone przez Twoje zamówienie, czyniąc je neutralnymi pod względem emisji dwutlenku węgla!',
        template_byDonatingMatch: 'Przekazując darowiznę, będziesz chronić [trees] drzew w [project], które pochłaniają CO2 wytworzone przez twoje zamówienie. [brand] dopasuje Twój wkład, aby podwoić wpływ i uratować jeszcze więcej drzew.',
        template_carbonNeutralOrderFor: 'Zamówienie neutralne pod względem emisji dwutlenku węgla za [price]',
        template_carbonNeutralShipping: 'Wysyłka neutralna pod względem emisji dwutlenku węgla',
        template_madeCarbonNeutralBy: 'Wykonane z neutralnej emisji dwutlenku węgla przez [brand]',
        template_brandIsMaking: '[brand] robi 100% zamówień neutralnych pod względem emisji dwutlenku węgla',
        template_brandWillMatch: '[brand] dopasuje się do Twojego wkładu i podwoi wpływ!',
        template_brandIsProudMerchantPaying: '[brand] is proud to offset the carbon emissions from your order by protecting [trees] trees in the [project], making it carbon neutral.',
        template_brandIsProudCustomerMatching: '[brand] z dumą kompensuje emisję dwutlenku węgla z Twojego zamówienia, chroniąc drzewa [trees] w [project], czyniąc go neutralnym pod względem emisji dwutlenku węgla.',
        template_brandsContribution: 'Wkład [brand] zostanie przekazany bezpośrednio na finansowanie [project]',
        template_impact: 'Uderzenie',
        template_weHaveOffset: 'Skompensowaliśmy [total_co2_offset] funtów emisji dwutlenku węgla',
        template_wePartneredWithEcocart: 'We partnered with EcoCart to make every single order carbon neutral (aka net zero)! EcoCart will help us eliminate, capture, or mitigate all of the carbon emissions created from manufacturing and shipping our products.',
        template_learnMore: 'Learn More',
        template_shoppersOffsetTheirCarbonFootprint: '<b>[global_daily_ecocart_order_count] Shoppers</b> Have Offset Their Carbon Footprint Today',
        cta_on: 'Aktywne',
        cta_off: 'Nieaktywne',
        cta_add: 'Dodaj',
        cta_remove: 'Usuń',
        cta_close: 'Zamknij',
        cta_learnMore: 'Więcej informacji',
        info_milesDriven: 'przejechanych km',
        info_businessCards: 'wizytówek',
        info_arcticIce: 'arktyczny lód uratowany',
        info_lightbulbs: 'zasilanych żarówek',
        info_co2Emissions: 'emisji CO₂',
        info_trees: 'drzewa',
        info_lbsOfCo2: 'emisji CO₂',
        info_treesSaved: 'drzewa uratowane',
        info_carbonNeutralOrder: 'Zamówienie bez emisji CO₂',
        info_carbonNeutralOption: 'Neutralny ślad węglowy z',
        info_or: 'lub',
        info_poweredBy: 'Obsługiwane przez',
        info_yourImpact: 'Twój wpływ na klimato',
        info_joinShoppers: 'Dołącz do ponad 3 milionów klientów',
        info_ourFriendsEstimate: 'Nasi koledzy z EcoCart oszacowali emisję CO2 związaną z wysyłką i produkcją Twojego zamówienia na:'
    },
    'pt': {
        add_price_for: 'Add [price] for',
        sustainable_shipping: 'Sustainable Shipping',
        shipping_protection: 'Shipping Protection',
        loss_damage_theft_protection: 'Loss, Damage, Theft Protection',
        protect_your_order_and_the_environment: 'Protect Your Order and the Environment',
        protect_your_package_while_offseting_carbon: "Protect your package from loss, theft or damage while offsetting your order's carbon footprint",
        purchasing_this_will_offset_amount_carbon: 'Purchasing This Will Offset [co2_per_item] Of Carbon With <span class="sr-only">EcoCart</span>[ecocart_logo]',
        this_is_like: 'This is like',
        driving_amount_miles_in_an_average_gas_vehicle: '<span class="font-bold">driving [miles_per_item] miles</span> in an average gas vehicle',
        amount_tree_seedlings_grown_for_10_years: '<span class="font-bold">[seedlings_per_item] tree seedlings</span> grown for 10 years',
        template_support_forestry_projects: 'Support Global Forestry Projects',
        template_makeAClimateContribution: 'Make A Climate Contribution',
        template_removeAmtOfPlasticFromOcean: 'Remove <span class="text-primary-400">1 lb</span> of plastic from the ocean',
        template_shopSustainablyByCounteractingPlasticWaste: "Shop Sustainably By Counteracting Your Order's Plastic Waste",
        template_yourContributionWillRemoveAmtPlasticFromOcean: 'Your <span class="text-primary-400">$1.00</span> contribution will remove <span class="text-primary-400">1 lb</span> of plastic from the ocean, counteracting the waste associated with the product and its packaging',
        template_ecocartEnablesUsToPartnerWithOceanPlasticRemovalProjects: 'EcoCart enables us to partner with ocean plastic removal projects in order to help us fight against climate change and counteract the plastic waste associated with each of our orders.',
        template_byCheckingOutWithEcoCartYourOrderWillIncludeAnInvestmentFutureHealth: 'By checking out with EcoCart, your order will include an investment in the future health of the planet by helping clean plastic up from the ocean.',
        template_thisIsEquivalentToAmtPlasticBottles: 'This is equivalent to the amount of plastic found in <span class="text-primary-400">24</span> 16oz water bottles',
        template_accordingToUNEnvironment: 'According to the UN Environment Programme, the average person is responsible for 156 water bottles being discarded as waste every year. So, it would take <span class="text-primary-400">[plastic_to_purchases]</span> purchases to counteract the average consumer\'s annual water bottle waste.',
        template_yourContributionWillHelpFundCleanHub: 'Your contribution will help fund Clean Hub, a certified plastic removal project',
        template_cleanHubIsAVerifiedProject: 'Clean Hub is a verified project dedicated to promoting sustainability and environmental preservation.',
        template_brandHasPartneredWithEcocart: '[brand] has partnered with EcoCart to estimate and offset the carbon footprint of your order by funding verified carbon removal projects around the world.',
        template_yourContributionAtWork: 'Your contribution at work...',
        template_verifiedBy: 'Verified by',
        template_yourOrderWillOffsetAmountCarbon: 'Seu pedido compensará [co2] de carbono',
        template_byCheckingOutWithEcocartYourOrderWillHelp: 'Ao finalizar a compra com o EcoCart, o seu pedido ajudará a apoiar o [project]',
        template_shopSustainably: 'Shop Sustainably',
        template_ecomindedShoppersLikeYouHaveOffset: 'Way to go! Eco-minded shoppers like you have <span class="text-primary-400">offset [global_all_time_carbon_offset] of Carbon</span> when checking out with EcoCart.',
        template_ofCarbonOffset: 'of Carbon Offset',
        template_goToStore: 'Go To Store',
        template_werePlantingTreesOnOrders: "We're Planting [eco_incentives_trees] Trees On Orders Over [eco_incentives_threshold]",
        template_youreValueAwayFromPlantingTrees: "You're [amount_needed_for_incentives] Away From Planting [eco_incentives_trees] Trees",
        template_hoorayYourOrderWillPlantTreesMerchantWillAlsoOffset: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. [brand] Will Also Offset The Emissions Of Your Order At Checkout!',
        template_hoorayYourOrderWillPlantTreesMaximizeImpact: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. Maximize Your Impact By Offsetting Your Order At Checkout!',
        template_merchantWillOffsetTwiceAsMuchCarbonAsYourOrderEmits: '<span class="text-primary-400">[brand]</span> will offset twice as much carbon as your order emits',
        template_merchantWillCoverTheCostOfOffsettingYourOrder: '<span class="text-primary-400">[brand]</span> will cover the cost of offsetting your order\'s carbon footprint',
        template_selectEcoCartInYourCartOrAtCheckout: 'Select EcoCart in your cart or at checkout to effortlessly contribute towards a greener future by offsetting',
        template_offsetYourOrdersCarbonFootPrintByCheckingOutWithEcocart: "Offset your order's carbon footprint by checking out with EcoCart",
        template_ecocartEstimatesEachOrdersCarbonFootprintLifeTimeUsage: "EcoCart estimates each order's carbon footprint by calculating variables such as shipping method, distance, materials, manufacturing processes, industry averages, lifetime usage, and more.",
        template_merchantIsOffsettingYourOrdersCarbonFootprintMatchTheirContributionDouble: "[brand] is offsetting your order's carbon footprint, <strong>match their contribution to double the impact</strong>",
        template_merchantWillOffsetYourOrdersCarbonFootprint: '<span class="text-primary-400">[brand]</span> will offset your order\'s carbon footprint',
        template_merchantWillDoubleYourOrdersCarbonOffset: '<span class="text-primary-400">[brand]</span> will double your order\'s carbon offset',
        template_merchantWillDoubleTheImpactByMatching: '<strong>[brand] will double the impact</strong> by matching your contribution',
        template_merchantMadeYourOrderClimatePositive: '[brand] Made Your Order Climate Positive',
        template_offsetTwiceAsMuchCarbonAsYourOrderEmits: 'Compense o dobro do carbono emitido pelo seu pedido',
        template_merchantWillMatchQuadrupling: '<span class="text-primary-400">[brand]</span> will match your contribution, quadrupling your order\'s total carbon offset!',
        template_yourContributionTwiceAsMuch: 'Sua <span class="text-primary-400">[price]</span> contribuição compensará <span class="text-primary-400">[co2]</span> de carbono, o dobro da pegada estimada do seu pedido, causando um impacto ambiental positivo e duradouro',
        template_ecocartEstimatesEachOrderOffest2x: 'EcoCart estima a pegada de carbono de cada pedido calculando variáveis como método de envio, distância, materiais, processos de fabricação, médias da indústria, uso ao longo da vida e mais.<br><br>Ao finalizar a compra com EcoCart, seu pedido dobrará a compensação da sua pegada de carbono estimada, causando um impacto ambiental positivo e duradouro.',
        template_yourOrderWillOffset4x: 'Seu pedido compensará 4x a quantidade de carbono que foi estimado ter emitido, tornando-o um impacto ambiental positivo e duradouro.',
        template_merchantWillOffsetTwiceAsMuch: '<span class="text-primary-400">[brand]</span> compensará <span class="text-primary-400">[co2]</span> de carbono, o dobro da pegada estimada do seu pedido, criando um impacto ambiental positivo e duradouro',
        template_byContributingYourOrderWillOffset4x: 'Ao contribuir, seu pedido quadruplicará a compensação de suas emissões de carbono estimadas, alcançando quatro vezes o impacto ambiental!',
        template_matchTheirContributionToQuadruple: "Match their contribution to quadruple your order's total carbon offset!",
        template_offsetMyOrdersFootprint: "Offset My Order's Carbon Footprint",
        template_ecocartEstimatesEachOrdersCarbonFootprint: 'O EcoCart estima a pegada de carbono de cada pedido, calculando variáveis como método de envio, distância, materiais, processos de fabricação, médias da indústria e muito mais.',
        template_ecocartPartnersWithCertifiedProjects: 'O EcoCart trabalha em parceria com projetos certificados para compensar as emissões de carbono provenientes da fabricação e envio do seu pedido.',
        template_merchantOffsetYourCarbonFootprint: '<span class="text-primary-400">[brand]</span> Offset Your Carbon Footprint',
        template_merchantContributionWillHelpFund: '<span class="text-primary-400">[brand]</span><span class="text-primary-400">\'s</span> contribution will help fund <span class="text-primary-400">[project]</span>, a certified carbon offsetting program.',
        template_yourContributionWillHelpFund: 'A sua contribuição ajudará a financiar <span class="text-primary-400">[project]</span>, um programa certificado de compensação de carbono.',
        template_byContributingYouWillOffsetCarbon: 'Ao contribuir com <span class="text-primary-400">[price]</span> você compensará <span class="text-primary-400">[co2]</span> de carbono.',
        template_merchantWillOffsetCarbon: '<span class="text-primary-400">[brand]</span> compensará <span class="text-primary-400">[co2]</span> de carbono',
        template_yourOrderHasBeenOffsetMaximizeImpact: '<span class="font-semibold">Your order has been offset!</span> Maximize your impact by going climate positive!',
        template_yourContributionWillGoTowards: 'Your contribution will go towards funding the [project]',
        template_withASmallContribution: 'With a small contribution, we can mitigate the emissions calculated from the items in your cart and reduce your carbon footprint',
        template_EcoCartWillCalculateTheFootprint: 'EcoCart will calculate the carbon footprint of your order',
        template_throughCollectiveActionYouAreTakingProactive: 'Through collective action, we can make a real difference in protecting our planet. By offsetting your carbon footprint, you are taking a proactive step towards building a more sustainable future',
        template_merchantHasOffsetYourOrderWith: '[brand] Compensou o seu pedido com o',
        template_offsetYourOrderAtCheckoutWith: 'Compense seu pedido ao finalizar a compra com',
        template_yourOrderBreakdown: 'Detalhamento do seu pedido',
        template_projectIsAVerifiedProject: '<a href="[project_url]" target="_blank" rel="noopener noreferrer">[project]</a> é um projeto verificado dedicado à promoção da sustentabilidade e preservação ambiental.',
        template_yourOrderWillOffsetAtLeastCarbon: 'Your order will offset at least [co2] of carbon which is more carbon than your order produces, exceeding net zero standards!',
        template_doubleTheImpact: 'Dobre o impacto igualando a contribuição deles, dobrando o impacto da sua contribuição.',
        template_oneYearTreeAbsorbCO2Amount: 'De acordo com o Departamento de Agricultura dos Estados Unidos, em um ano, uma árvore madura pode absorver mais de 48 libras de dióxido de carbono da atmosfera e liberar oxigênio em troca.',
        template_carbonTreesCanAbsorb: 'Isso equivale à quantidade de carbono que <span class="text-primary-400">[lbs_co2_absorbed_by_one_tree_annually] árvores</span> podem absorver em um ano',
        template_ecocartProprietaryAlgo: 'O algoritmo proprietário do EcoCart estima em tempo real a pegada de carbono de cada pedido, calculando variáveis como método de envio, distância, métodos de fabricação, materiais e muito mais.',
        template_shopSustainablyByOffsetingCO2Footprint: 'Faça compras de forma sustentável compensando a pegada de carbono do seu pedido',
        template_reduceMyOrderCO2: 'Reduza a pegada de carbono do meu pedido',
        template_makeMyOrder: 'Tornar meu pedido neutro em carbono',
        template_makeMyOrderClimatePositive: 'Dobre o Impacto Climático do Meu Pedido',
        template_offsetEstimatedCo2e: 'Compense as emissões estimadas de carbono do seu pedido',
        template_merchantMatchYourContribution: '<span class="text-primary-400">[brand]</span> igualará sua contribuição, dobrando a compensação de sua pegada de carbono estimada',
        template_yourOrderHasBeen: 'Seu pedido foi feito com carbono neutro',
        template_yourOrderWillBe: 'Seu pedido será feito com carbono neutro por [brand]',
        template_addToMake: 'Adicione [price] para tornar seu pedido neutro em carbono',
        template_addToReduce: 'Adicione [price] para reduzir sua pegada de carbono',
        template_addToMatch: 'Adicione [price] para igualar sua contribuição e dobrar o impacto!',
        template_addToDouble: 'Adicione [price] para dobrar o impacto',
        template_byCheckingTheBox: 'Ao marcar a caixa, você estará protegendo [trees] árvores no [project], que estão absorvendo o CO2 criado pelo seu pedido, tornando-o neutro em carbono!',
        template_byDonating: 'Ao doar, você estará protegendo as [trees] árvores do [project], que estão absorvendo o CO2 criado pelo seu pedido, tornando-o neutro em carbono!',
        template_byDonatingMatch: 'Ao doar, você estará protegendo [trees] árvores do [project], que estão absorvendo o CO2 criado pelo seu pedido. A [brand] dobrará sua contribuição, dobrando assim o impacto e economizando* ainda mais árvores.',
        template_carbonNeutralOrderFor: 'Encomenda Carbono Neutro por [price]',
        template_carbonNeutralShipping: 'Envio Carbono Neutro',
        template_madeCarbonNeutralBy: 'Feito de carbono neutro por [brand]',
        template_brandIsMaking: '[brand] está fazendo 100% dos pedidos neutros em carbono',
        template_brandWillMatch: '[brand] corresponderá à sua contribuição e dobrará o impacto!',
        template_brandIsProudMerchantPaying: 'A [brand] se orgulha de compensar as emissões de carbono do seu pedido protegendo as [trees] árvores no [project], tornando-o neutro em carbono.',
        template_brandIsProudCustomerMatching: 'A [brand] se orgulha de compensar as emissões de carbono do seu pedido protegendo as [trees] árvores no [project]. Ao doar, você estará dobrando a contribuição da [brand] e consequentemente o impacto e a economia das árvores!',
        template_brandsContribution: 'A contribuição da [brand] irá diretamente para ajudar a financiar o [project]',
        template_impact: 'Impacto',
        template_weHaveOffset: 'Compensamos [total_co2_offset] lbs de emissões de carbono',
        template_wePartneredWithEcocart: 'We partnered with EcoCart to make every single order carbon neutral (aka net zero)! EcoCart will help us eliminate, capture, or mitigate all of the carbon emissions created from manufacturing and shipping our products.',
        template_learnMore: 'Learn More',
        template_shoppersOffsetTheirCarbonFootprint: '<b>[global_daily_ecocart_order_count] Shoppers</b> Have Offset Their Carbon Footprint Today',
        cta_on: 'On',
        cta_off: 'Off',
        cta_add: 'Adicionar',
        cta_remove: 'Remover',
        cta_close: 'Fechar',
        cta_learnMore: 'Saiba mais',
        info_milesDriven: 'km percorridas',
        info_businessCards: 'cartões de visita',
        info_arcticIce: 'gelo ártico salvo',
        info_lightbulbs: 'lightbulbs powered',
        info_co2Emissions: 'de emissão de CO₂',
        info_trees: 'árvores',
        info_lbsOfCo2: 'de emissão de CO₂',
        info_treesSaved: 'árvores salvas',
        info_carbonNeutralOrder: 'Pedido com CO₂ neutralizado',
        info_carbonNeutralOption: 'Opção de Carbono neutro com o',
        info_or: 'ou',
        info_poweredBy: 'Desenvolvido por',
        info_yourImpact: 'Seu impacto climático',
        info_joinShoppers: 'Junte-se a mais de 3 milhões de compradores e contando',
        info_ourFriendsEstimate: 'O EcoCart calculou que as emissões:'
    },
    'sk': {
        add_price_for: 'Add [price] for',
        sustainable_shipping: 'Sustainable Shipping',
        shipping_protection: 'Shipping Protection',
        loss_damage_theft_protection: 'Loss, Damage, Theft Protection',
        protect_your_order_and_the_environment: 'Protect Your Order and the Environment',
        protect_your_package_while_offseting_carbon: "Protect your package from loss, theft or damage while offsetting your order's carbon footprint",
        purchasing_this_will_offset_amount_carbon: 'Purchasing This Will Offset [co2_per_item] Of Carbon With <span class="sr-only">EcoCart</span>[ecocart_logo]',
        this_is_like: 'This is like',
        driving_amount_miles_in_an_average_gas_vehicle: '<span class="font-bold">driving [miles_per_item] miles</span> in an average gas vehicle',
        amount_tree_seedlings_grown_for_10_years: '<span class="font-bold">[seedlings_per_item] tree seedlings</span> grown for 10 years',
        template_support_forestry_projects: 'Support Global Forestry Projects',
        template_makeAClimateContribution: 'Make A Climate Contribution',
        template_removeAmtOfPlasticFromOcean: 'Remove <span class="text-primary-400">1 lb</span> of plastic from the ocean',
        template_shopSustainablyByCounteractingPlasticWaste: "Shop Sustainably By Counteracting Your Order's Plastic Waste",
        template_yourContributionWillRemoveAmtPlasticFromOcean: 'Your <span class="text-primary-400">$1.00</span> contribution will remove <span class="text-primary-400">1 lb</span> of plastic from the ocean, counteracting the waste associated with the product and its packaging',
        template_ecocartEnablesUsToPartnerWithOceanPlasticRemovalProjects: 'EcoCart enables us to partner with ocean plastic removal projects in order to help us fight against climate change and counteract the plastic waste associated with each of our orders.',
        template_byCheckingOutWithEcoCartYourOrderWillIncludeAnInvestmentFutureHealth: 'By checking out with EcoCart, your order will include an investment in the future health of the planet by helping clean plastic up from the ocean.',
        template_thisIsEquivalentToAmtPlasticBottles: 'This is equivalent to the amount of plastic found in <span class="text-primary-400">24</span> 16oz water bottles',
        template_accordingToUNEnvironment: 'According to the UN Environment Programme, the average person is responsible for 156 water bottles being discarded as waste every year. So, it would take <span class="text-primary-400">[plastic_to_purchases]</span> purchases to counteract the average consumer\'s annual water bottle waste.',
        template_yourContributionWillHelpFundCleanHub: 'Your contribution will help fund Clean Hub, a certified plastic removal project',
        template_cleanHubIsAVerifiedProject: 'Clean Hub is a verified project dedicated to promoting sustainability and environmental preservation.',
        template_brandHasPartneredWithEcocart: '[brand] has partnered with EcoCart to estimate and offset the carbon footprint of your order by funding verified carbon removal projects around the world.',
        template_yourContributionAtWork: 'Your contribution at work...',
        template_verifiedBy: 'Verified by',
        template_yourOrderWillOffsetAmountCarbon: 'Vaša objednávka vyrovná [co2] uhlíka',
        template_byCheckingOutWithEcocartYourOrderWillHelp: 'Pri objednávaní s EcoCart, vaša objednávka pomôže podporiť [project]',
        template_shopSustainably: 'Shop Sustainably',
        template_ecomindedShoppersLikeYouHaveOffset: 'Way to go! Eco-minded shoppers like you have <span class="text-primary-400">offset [global_all_time_carbon_offset] of Carbon</span> when checking out with EcoCart.',
        template_ofCarbonOffset: 'of Carbon Offset',
        template_goToStore: 'Go To Store',
        template_werePlantingTreesOnOrders: "We're Planting [eco_incentives_trees] Trees On Orders Over [eco_incentives_threshold]",
        template_youreValueAwayFromPlantingTrees: "You're [amount_needed_for_incentives] Away From Planting [eco_incentives_trees] Trees",
        template_hoorayYourOrderWillPlantTreesMerchantWillAlsoOffset: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. [brand] Will Also Offset The Emissions Of Your Order At Checkout!',
        template_hoorayYourOrderWillPlantTreesMaximizeImpact: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. Maximize Your Impact By Offsetting Your Order At Checkout!',
        template_merchantWillOffsetTwiceAsMuchCarbonAsYourOrderEmits: '<span class="text-primary-400">[brand]</span> will offset twice as much carbon as your order emits',
        template_merchantWillCoverTheCostOfOffsettingYourOrder: '<span class="text-primary-400">[brand]</span> will cover the cost of offsetting your order\'s carbon footprint',
        template_selectEcoCartInYourCartOrAtCheckout: 'Select EcoCart in your cart or at checkout to effortlessly contribute towards a greener future by offsetting',
        template_offsetYourOrdersCarbonFootPrintByCheckingOutWithEcocart: "Offset your order's carbon footprint by checking out with EcoCart",
        template_ecocartEstimatesEachOrdersCarbonFootprintLifeTimeUsage: "EcoCart estimates each order's carbon footprint by calculating variables such as shipping method, distance, materials, manufacturing processes, industry averages, lifetime usage, and more.",
        template_merchantIsOffsettingYourOrdersCarbonFootprintMatchTheirContributionDouble: "[brand] is offsetting your order's carbon footprint, <strong>match their contribution to double the impact</strong>",
        template_merchantWillOffsetYourOrdersCarbonFootprint: '<span class="text-primary-400">[brand]</span> will offset your order\'s carbon footprint',
        template_merchantWillDoubleYourOrdersCarbonOffset: '<span class="text-primary-400">[brand]</span> will double your order\'s carbon offset',
        template_merchantWillDoubleTheImpactByMatching: '<strong>[brand] will double the impact</strong> by matching your contribution',
        template_merchantMadeYourOrderClimatePositive: '[brand] Made Your Order Climate Positive',
        template_offsetTwiceAsMuchCarbonAsYourOrderEmits: 'Vykompenzujte dvojnásobne viac oxidu uhličitého, než vydáva vaša objednávka',
        template_merchantWillMatchQuadrupling: '<span class="text-primary-400">[brand]</span> will match your contribution, quadrupling your order\'s total carbon offset!',
        template_yourContributionTwiceAsMuch: 'Váš <span class="text-primary-400">[price]</span> príspevok vykompenzuje <span class="text-primary-400">[co2]</span> uhlíka, čo je dvakrát viac ako odhadovaná uhlíková stopa vašej objednávky, čím vytvoríte pozitívny a trvalý environmentálny dopad.',
        template_ecocartEstimatesEachOrderOffest2x: 'EcoCart odhaduje uhlíkovú stopu každej objednávky výpočtom premenných, ako sú spôsob dopravy, vzdialenosť, materiály, výrobné procesy, priemyselné priemery, doba používania a ďalšie.<br><br>Pri platbe s EcoCart vaša objednávka zdvojnásobí kompenzáciu odhadovanej uhlíkovej stopy, čím vytvorí pozitívny a trvalý environmentálny dopad.',
        template_yourOrderWillOffset4x: 'Vaša objednávka vykompenzuje 4x množstvo uhlíka, ktoré sa odhadovalo, že bolo emitované, čím sa vytvorí pozitívny trvalý environmentálny dopad',
        template_merchantWillOffsetTwiceAsMuch: '<span class="text-primary-400">[brand]</span> vykompenzuje <span class="text-primary-400">[co2]</span> uhlíka, čo je dvakrát viac ako odhadovaná uhlíková stopa vašej objednávky, čím vytvorí pozitívny a trvalý environmentálny dopad',
        template_byContributingYourOrderWillOffset4x: 'Prispievaním váš [order] štvornásobne zvýši kompenzáciu odhadovaných emisií uhlíka, čím dosiahne štvornásobný environmentálny dopad!',
        template_matchTheirContributionToQuadruple: "Match their contribution to quadruple your order's total carbon offset!",
        template_offsetMyOrdersFootprint: "Offset My Order's Carbon Footprint",
        template_ecocartEstimatesEachOrdersCarbonFootprint: 'EcoCart odhaduje uhlíkovú stopu každej objednávky výpočtom premenných, ako sú spôsob dodania, vzdialenosť, materiály, výrobné procesy, priemery odvetvia a ďalšie.',
        template_ecocartPartnersWithCertifiedProjects: 'EcoCart spolupracuje s certifikovanými projektmi na vyrovnávanie emisií oxidu uhličitého pri výrobe a dodávke vašej objednávky.',
        template_merchantOffsetYourCarbonFootprint: '<span class="text-primary-400">[brand]</span> Offset Your Carbon Footprint',
        template_merchantContributionWillHelpFund: '<span class="text-primary-400">[brand]</span><span class="text-primary-400">\'s</span> contribution will help fund <span class="text-primary-400">[project]</span>, a certified carbon offsetting program.',
        template_yourContributionWillHelpFund: 'Vaša príspevok pomôže financovať <span class="text-primary-400">[project]</span>, certifikovaný program na kompenzáciu emisií uhlíka.',
        template_byContributingYouWillOffsetCarbon: 'Prispievaním <span class="text-primary-400">[price]</span> vyrovnáte <span class="text-primary-400">[co2]</span> uhlíka.',
        template_merchantWillOffsetCarbon: '<span class="text-primary-400">[brand]</span> vykompenzuje <span class="text-primary-400">[co2]</span> oxidu uhličitého',
        template_yourOrderHasBeenOffsetMaximizeImpact: '<span class="font-semibold">Your order has been offset!</span> Maximize your impact by going climate positive!',
        template_yourContributionWillGoTowards: 'Your contribution will go towards funding the [project]',
        template_withASmallContribution: 'With a small contribution, we can mitigate the emissions calculated from the items in your cart and reduce your carbon footprint',
        template_EcoCartWillCalculateTheFootprint: 'EcoCart will calculate the carbon footprint of your order',
        template_throughCollectiveActionYouAreTakingProactive: 'Through collective action, we can make a real difference in protecting our planet. By offsetting your carbon footprint, you are taking a proactive step towards building a more sustainable future',
        template_merchantHasOffsetYourOrderWith: '[brand] skompensoval váš objednávku s',
        template_offsetYourOrderAtCheckoutWith: 'Znížte emisie vašej objednávky pri pokladni s',
        template_yourOrderBreakdown: 'Rozbor vašej objednávky',
        template_projectIsAVerifiedProject: '<a href="[project_url]" target="_blank" rel="noopener noreferrer">[project]</a> je overený projekt zameraný na podporu udržateľnosti a ochranu životného prostredia.',
        template_yourOrderWillOffsetAtLeastCarbon: 'Your order will offset at least [co2] of carbon which is more carbon than your order produces, exceeding net zero standards!',
        template_doubleTheImpact: 'Zdvojnásobte dopad tým, že zladíte ich príspevok, čím zdvojnásobíte dopad vášho príspevku.',
        template_oneYearTreeAbsorbCO2Amount: 'Podľa amerického Ministerstva poľnohospodárstva môže v jednom roku dospelý strom absorbovať viac ako 48 libier oxidu uhličitého z atmosféry a uvoľniť kyslík ako výmenu.',
        template_carbonTreesCanAbsorb: 'Toto je ekvivalentné s množstvom uhlíka, ktoré <span class="text-primary-400">[lbs_co2_absorbed_by_one_tree_annually] stromy</span> môžu absorbovať za rok',
        template_ecocartProprietaryAlgo: 'Vlastný algoritmus EcoCartu odhaduje uhlíkovú stopu každej objednávky v reálnom čase výpočtom premenných ako metóda doručenia, vzdialenosť, výrobné metódy, materiály a ďalšie.',
        template_shopSustainablyByOffsetingCO2Footprint: 'Nakupujte udržateľne vyrovnávaním uhlíkovej stopy vašej objednávky',
        template_reduceMyOrderCO2: 'Znížte uhlíkovú stopu mojej objednávky',
        template_makeMyOrder: 'Urob moju objednávku uhlíkovo neutrálnu',
        template_makeMyOrderClimatePositive: 'Zdvojnásobiť klimatický dopad mojej objednávky',
        template_offsetEstimatedCo2e: 'Vykompenzujte odhadované emisie uhlíka vašej objednávky',
        template_merchantMatchYourContribution: '<span class="text-primary-400">[brand]</span> zdvojnásobí váš príspevok, čím zdvojnásobí kompenzáciu jeho odhadovanej uhlíkovej stopy',
        template_yourOrderHasBeen: 'Vaša objednávka bola uhlíkovo neutrálna',
        template_yourOrderWillBe: 'Vaša objednávka bude uhlíkovo neutrálna od [brand]',
        template_addToMake: 'Pridajte [price], aby bola vaša objednávka uhlíkovo neutrálna',
        template_addToReduce: 'Pridajte [price], aby ste znížili svoju uhlíkovú stopu',
        template_addToMatch: 'Pridajte [price], aby zodpovedala ich príspevku a zdvojnásobte účinok!',
        template_addToDouble: 'Ak chcete zdvojnásobiť účinok, pridajte [price]',
        template_byCheckingTheBox: 'Zaškrtnutím políčka budete chrániť [trees] stromy v [project], ktoré absorbujú CO2 vytvorený vašou objednávkou, čím sa stáva uhlíkovo neutrálnym!',
        template_byDonating: 'Darovaním ochránite [trees] stromy v [project], ktoré absorbujú CO2 vytvorený vašou objednávkou, vďaka čomu je uhlíkovo neutrálny!',
        template_byDonatingMatch: 'Darovaním budete chrániť [trees] stromy v [project], ktoré absorbujú CO2 vytvorený vašou objednávkou. [brand] zdvojnásobí váš príspevok a zachráni ešte viac stromov.',
        template_carbonNeutralOrderFor: 'Uhlíkovo neutrálna objednávka za [price]',
        template_carbonNeutralShipping: 'Uhlíkovo neutrálna doprava',
        template_madeCarbonNeutralBy: 'Vyrobené Carbon Neutral od [brand]',
        template_brandIsMaking: '[brand] robí 100 % objednávok uhlíkovo neutrálne',
        template_brandWillMatch: '[brand] bude zodpovedať vášmu príspevku a zdvojnásobí vplyv!',
        template_brandIsProudMerchantPaying: '[brand] je hrdý na to, že kompenzuje uhlíkové emisie z vašej objednávky tým, že chráni [trees] stromy v [project], vďaka čomu je uhlíkovo neutrálny.',
        template_brandIsProudCustomerMatching: '[brand] z dumą kompensuje emisję dwutlenku węgla z Twojego zamówienia, chroniąc drzewa [trees] w [project]. Przekazując darowiznę, dopasujesz wkład [brand], podwajając wpływ i ratując jeszcze więcej drzew!',
        template_brandsContribution: 'Príspevok [brand] pôjde priamo na pomoc pri financovaní [project]',
        template_impact: 'Vplyv',
        template_weHaveOffset: 'Vykompenzovali sme [total_co2_offset] libier emisií oxidu uhličitého',
        template_wePartneredWithEcocart: 'We partnered with EcoCart to make every single order carbon neutral (aka net zero)! EcoCart will help us eliminate, capture, or mitigate all of the carbon emissions created from manufacturing and shipping our products.',
        template_learnMore: 'Learn More',
        template_shoppersOffsetTheirCarbonFootprint: '<b>[global_daily_ecocart_order_count] Shoppers</b> Have Offset Their Carbon Footprint Today',
        cta_on: 'Zapnúť',
        cta_off: 'Vypnúť',
        cta_add: 'Pridať',
        cta_remove: 'Odstrániť',
        cta_close: 'Zatvoriť',
        cta_learnMore: 'Uč sa viac',
        info_milesDriven: 'km najazdené',
        info_businessCards: 'vizitky',
        info_arcticIce: 'zachránený arktický ľad',
        info_lightbulbs: 'napájané žiarovky',
        info_co2Emissions: 'emisií CO₂',
        info_trees: 'stromy',
        info_lbsOfCo2: 'emisií CO₂',
        info_treesSaved: 'stromy zachránené',
        info_carbonNeutralOrder: 'Uhlíkovo neutrálne poradie',
        info_carbonNeutralOption: 'Uhlíkovo neutrálna možnosť s',
        info_or: 'alebo',
        info_poweredBy: 'Poháňaný',
        info_yourImpact: 'Váš vplyv na klímu',
        info_joinShoppers: 'Pridajte sa k viac ako 3 miliónom nakupujúcich a stále pribúdajú',
        info_ourFriendsEstimate: 'Naši priatelia v EcoCart odhadli uhlíkovú stopu prepravy a výroby vašej objednávky na:'
    },
    'jp': {
        add_price_for: 'Add [price] for',
        sustainable_shipping: 'Sustainable Shipping',
        shipping_protection: 'Shipping Protection',
        loss_damage_theft_protection: 'Loss, Damage, Theft Protection',
        protect_your_order_and_the_environment: 'Protect Your Order and the Environment',
        protect_your_package_while_offseting_carbon: "Protect your package from loss, theft or damage while offsetting your order's carbon footprint",
        purchasing_this_will_offset_amount_carbon: 'Purchasing This Will Offset [co2_per_item] Of Carbon With <span class="sr-only">EcoCart</span>[ecocart_logo]',
        this_is_like: 'This is like',
        driving_amount_miles_in_an_average_gas_vehicle: '<span class="font-bold">driving [miles_per_item] miles</span> in an average gas vehicle',
        amount_tree_seedlings_grown_for_10_years: '<span class="font-bold">[seedlings_per_item] tree seedlings</span> grown for 10 years',
        template_support_forestry_projects: 'Support Global Forestry Projects',
        template_makeAClimateContribution: 'Make A Climate Contribution',
        template_removeAmtOfPlasticFromOcean: 'Remove <span class="text-primary-400">1 lb</span> of plastic from the ocean',
        template_shopSustainablyByCounteractingPlasticWaste: "Shop Sustainably By Counteracting Your Order's Plastic Waste",
        template_yourContributionWillRemoveAmtPlasticFromOcean: 'Your <span class="text-primary-400">$1.00</span> contribution will remove <span class="text-primary-400">1 lb</span> of plastic from the ocean, counteracting the waste associated with the product and its packaging',
        template_ecocartEnablesUsToPartnerWithOceanPlasticRemovalProjects: 'EcoCart enables us to partner with ocean plastic removal projects in order to help us fight against climate change and counteract the plastic waste associated with each of our orders.',
        template_byCheckingOutWithEcoCartYourOrderWillIncludeAnInvestmentFutureHealth: 'By checking out with EcoCart, your order will include an investment in the future health of the planet by helping clean plastic up from the ocean.',
        template_thisIsEquivalentToAmtPlasticBottles: 'This is equivalent to the amount of plastic found in <span class="text-primary-400">24</span> 16oz water bottles',
        template_accordingToUNEnvironment: 'According to the UN Environment Programme, the average person is responsible for 156 water bottles being discarded as waste every year. So, it would take <span class="text-primary-400">[plastic_to_purchases]</span> purchases to counteract the average consumer\'s annual water bottle waste.',
        template_yourContributionWillHelpFundCleanHub: 'Your contribution will help fund Clean Hub, a certified plastic removal project',
        template_cleanHubIsAVerifiedProject: 'Clean Hub is a verified project dedicated to promoting sustainability and environmental preservation.',
        template_brandHasPartneredWithEcocart: '[brand] has partnered with EcoCart to estimate and offset the carbon footprint of your order by funding verified carbon removal projects around the world.',
        template_yourContributionAtWork: 'Your contribution at work...',
        template_verifiedBy: 'Verified by',
        template_yourOrderWillOffsetAmountCarbon: 'ご注文は[co2]の炭素を相殺します',
        template_byCheckingOutWithEcocartYourOrderWillHelp: 'EcoCartでチェックアウトすることで、ご注文は[project]の支援に役立ちます',
        template_shopSustainably: 'Shop Sustainably',
        template_ecomindedShoppersLikeYouHaveOffset: 'Way to go! Eco-minded shoppers like you have <span class="text-primary-400">offset [global_all_time_carbon_offset] of Carbon</span> when checking out with EcoCart.',
        template_ofCarbonOffset: 'of Carbon Offset',
        template_goToStore: 'Go To Store',
        template_werePlantingTreesOnOrders: "We're Planting [eco_incentives_trees] Trees On Orders Over [eco_incentives_threshold]",
        template_youreValueAwayFromPlantingTrees: "You're [amount_needed_for_incentives] Away From Planting [eco_incentives_trees] Trees",
        template_hoorayYourOrderWillPlantTreesMerchantWillAlsoOffset: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. [brand] Will Also Offset The Emissions Of Your Order At Checkout!',
        template_hoorayYourOrderWillPlantTreesMaximizeImpact: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. Maximize Your Impact By Offsetting Your Order At Checkout!',
        template_merchantWillOffsetTwiceAsMuchCarbonAsYourOrderEmits: '<span class="text-primary-400">[brand]</span> will offset twice as much carbon as your order emits',
        template_merchantWillCoverTheCostOfOffsettingYourOrder: '<span class="text-primary-400">[brand]</span> will cover the cost of offsetting your order\'s carbon footprint',
        template_selectEcoCartInYourCartOrAtCheckout: 'Select EcoCart in your cart or at checkout to effortlessly contribute towards a greener future by offsetting',
        template_offsetYourOrdersCarbonFootPrintByCheckingOutWithEcocart: "Offset your order's carbon footprint by checking out with EcoCart",
        template_ecocartEstimatesEachOrdersCarbonFootprintLifeTimeUsage: "EcoCart estimates each order's carbon footprint by calculating variables such as shipping method, distance, materials, manufacturing processes, industry averages, lifetime usage, and more.",
        template_merchantIsOffsettingYourOrdersCarbonFootprintMatchTheirContributionDouble: "[brand] is offsetting your order's carbon footprint, <strong>match their contribution to double the impact</strong>",
        template_merchantWillOffsetYourOrdersCarbonFootprint: '<span class="text-primary-400">[brand]</span> will offset your order\'s carbon footprint',
        template_merchantWillDoubleYourOrdersCarbonOffset: '<span class="text-primary-400">[brand]</span> will double your order\'s carbon offset',
        template_merchantWillDoubleTheImpactByMatching: '<strong>[brand] will double the impact</strong> by matching your contribution',
        template_merchantMadeYourOrderClimatePositive: '[brand] Made Your Order Climate Positive',
        template_offsetTwiceAsMuchCarbonAsYourOrderEmits: 'ご注文の排出量の2倍の炭素を相殺します。',
        template_merchantWillMatchQuadrupling: '<span class="text-primary-400">[brand]</span> will match your contribution, quadrupling your order\'s total carbon offset!',
        template_yourContributionTwiceAsMuch: 'あなたの <span class="text-primary-400">[price]</span> の貢献は、注文の推定フットプリントの2倍の<span class="text-primary-400">[co2]</span>の炭素を相殺し、持続的な環境へのポジティブな影響を与えます。',
        template_ecocartEstimatesEachOrderOffest2x: 'EcoCartは、配送方法、距離、材料、製造プロセス、業界平均、寿命使用量などの変数を計算することによって、各注文のカーボンフットプリントを推定します。<br><br>EcoCartでチェックアウトすることにより、注文の推定カーボンフットプリントのオフセットが2倍になります。これにより、持続的な環境へのポジティブな影響を与えることができます。',
        template_yourOrderWillOffset4x: 'ご注文は、推定された排出量の4倍の炭素を相殺し、持続的な環境へのプラスの影響を与えます。',
        template_merchantWillOffsetTwiceAsMuch: '<span class="text-primary-400">[brand]</span> は、あなたの注文の推定フットプリントの2倍の<span class="text-primary-400">[co2]</span>の炭素を相殺し、持続的なポジティブな環境影響を与えます',
        template_byContributingYourOrderWillOffset4x: '貢献することで、あなたの注文は推定炭素排出量のオフセットを4倍にし、環境への影響を4倍にします！',
        template_matchTheirContributionToQuadruple: "Match their contribution to quadruple your order's total carbon offset!",
        template_offsetMyOrdersFootprint: "Offset My Order's Carbon Footprint",
        template_ecocartEstimatesEachOrdersCarbonFootprint: 'EcoCartは、配送方法、距離、材料、製造プロセス、業界平均などの変数を計算して、各注文の炭素排出量を推定します。',
        template_ecocartPartnersWithCertifiedProjects: 'EcoCartは、製造および配送時のカーボン排出量を相殺するために、認定プロジェクトと提携しています。',
        template_merchantOffsetYourCarbonFootprint: '<span class="text-primary-400">[brand]</span> Offset Your Carbon Footprint',
        template_merchantContributionWillHelpFund: '<span class="text-primary-400">[brand]</span><span class="text-primary-400">\'s</span> contribution will help fund <span class="text-primary-400">[project]</span>, a certified carbon offsetting program.',
        template_yourContributionWillHelpFund: 'あなたの貢献は、認定された炭素オフセットプログラム<span class="text-primary-400">[project]</span>の資金調達に役立ちます。',
        template_byContributingYouWillOffsetCarbon: '<span class="text-primary-400">[price]</span>を寄付することで、<span class="text-primary-400">[co2]</span>の炭素を相殺します。',
        template_merchantWillOffsetCarbon: '<span class="text-primary-400">[brand]</span>は<span class="text-primary-400">[co2]</span>の炭素を相殺します',
        template_yourOrderHasBeenOffsetMaximizeImpact: '<span class="font-semibold">Your order has been offset!</span> Maximize your impact by going climate positive!',
        template_yourContributionWillGoTowards: 'Your contribution will go towards funding the [project]',
        template_withASmallContribution: 'With a small contribution, we can mitigate the emissions calculated from the items in your cart and reduce your carbon footprint',
        template_EcoCartWillCalculateTheFootprint: 'EcoCart will calculate the carbon footprint of your order',
        template_throughCollectiveActionYouAreTakingProactive: 'Through collective action, we can make a real difference in protecting our planet. By offsetting your carbon footprint, you are taking a proactive step towards building a more sustainable future',
        template_merchantHasOffsetYourOrderWith: '「 [Merchant] がEcoCartを使用してあなたの注文を相殺しました」',
        template_offsetYourOrderAtCheckoutWith: 'EcoCartを使用してチェックアウト時に注文を相殺する',
        template_yourOrderBreakdown: '注文の内訳',
        template_projectIsAVerifiedProject: '<a href="[project_url]" target="_blank" rel="noopener noreferrer">[project]</a>は、持続可能性と環境保護の促進を目指す検証済みのプロジェクトです。',
        template_yourOrderWillOffsetAtLeastCarbon: 'Your order will offset at least [co2] of carbon which is more carbon than your order produces, exceeding net zero standards!',
        template_doubleTheImpact: '彼らの貢献をマッチングすることで影響を倍増させ、あなたの貢献の影響を倍増させます。',
        template_oneYearTreeAbsorbCO2Amount: '米国農務省によると、成熟した木1本が1年間で大気中から48ポンド以上の二酸化炭素を吸収し、酸素を放出することができます。',
        template_carbonTreesCanAbsorb: 'これは、1年間で<span class="text-primary-400">[lbs_co2_absorbed_by_one_tree_annually] 本の木</span>が吸収できる炭素の量と同等です',
        template_ecocartProprietaryAlgo: 'EcoCartの独自のアルゴリズムは、配送方法、距離、製造方法、材料などの変数を計算して、各注文の炭素フットプリントをリアルタイムで推定します。',
        template_shopSustainablyByOffsetingCO2Footprint: '注文の炭素排出量を相殺して持続可能なショッピングをしましょう。',
        template_reduceMyOrderCO2: '私の注文の炭素フットプリントを削減してください。',
        template_makeMyOrder: '注文をカーボン ニュートラルにする',
        template_makeMyOrderClimatePositive: '私の注文の気候への影響を2倍にする',
        template_offsetEstimatedCo2e: '注文の推定炭素排出量をオフセットしてください。',
        template_merchantMatchYourContribution: '<span class="text-primary-400">[brand]</span> はあなたの貢献に一致し、推定されるカーボンフットプリントのオフセットを2倍にします',
        template_yourOrderHasBeen: 'ご注文はカーボンニュートラルになりました',
        template_yourOrderWillBe: 'ご注文は [brand] によってカーボン ニュートラルになります',
        template_addToMake: '[price] を追加して、ご注文をカーボン ニュートラルにします',
        template_addToReduce: '[price] を追加して二酸化炭素排出量を削減',
        template_addToMatch: '貢献度に応じて [price] を追加すると、効果が 2 倍になります!',
        template_addToDouble: '[price] を追加すると、効果が 2 倍になります',
        template_byCheckingTheBox: 'ボックスをチェックすると、[project] の [trees] の木が保護され、注文によって発生した CO2 を吸収し、カーボン ニュートラルになります!',
        template_byDonating: '寄付することで、[project] の [trees] の木が保護され、注文によって発生した CO2 を吸収し、カーボン ニュートラルになります!',
        template_byDonatingMatch: '寄付することで、[project] の [trees] の木が保護され、注文によって発生した CO2 を吸収します。 [brand] はあなたの貢献に同額を寄付し、影響を 2 倍にし、さらに多くの木を節約します。',
        template_carbonNeutralOrderFor: '[price] のカーボン ニュートラル注文',
        template_carbonNeutralShipping: 'カーボン ニュートラルな配送',
        template_madeCarbonNeutralBy: '[brand] によるカーボン ニュートラル化',
        template_brandIsMaking: '[brand] は注文の 100% をカーボン ニュートラルにしています',
        template_brandWillMatch: '[brand] はあなたの寄付に見合った価値を提供し、その効果を倍増させます!',
        template_brandIsProudMerchantPaying: '[brand] は、[project] の [trees] の木を保護することで、ご注文からの二酸化炭素排出量を相殺し、カーボン ニュートラルにすることを誇りに思っています。',
        template_brandIsProudCustomerMatching: '[brand] は、[project] の [trees] の木を保護することで、ご注文からの二酸化炭素排出量を相殺できることを誇りに思っています。寄付することで、[brand] の貢献と同額になり、影響が 2 倍になり、さらに多くの木を節約できます!',
        template_brandsContribution: '[brand] の寄付金は [project] の資金として直接使われます',
        template_impact: '影響',
        template_weHaveOffset: '私たちは [total_co2_offset] ポンドの炭素排出量をオフセットしました',
        template_wePartneredWithEcocart: 'We partnered with EcoCart to make every single order carbon neutral (aka net zero)! EcoCart will help us eliminate, capture, or mitigate all of the carbon emissions created from manufacturing and shipping our products.',
        template_learnMore: 'Learn More',
        template_shoppersOffsetTheirCarbonFootprint: '<b>[global_daily_ecocart_order_count] Shoppers</b> Have Offset Their Carbon Footprint Today',
        cta_on: 'オン',
        cta_off: 'オフ',
        cta_add: '追加',
        cta_remove: '削除',
        cta_close: '閉じる',
        cta_learnMore: 'もっと詳しく知る',
        info_milesDriven: 'kmの走行',
        info_businessCards: '枚の名刺',
        info_arcticIce: '北極の氷が救われた',
        info_lightbulbs: '電球を動力源',
        info_co2Emissions: 'CO₂排出量',
        info_lbsOfCo2: 'kgのCO₂排出量',
        info_treesSaved: '保存された木',
        info_carbonNeutralOrder: 'カーボンニュートラルオーダー',
        info_carbonNeutralOption: 'によるカーボンニュートラルオプション',
        info_or: 'また',
        info_trees: '木々',
        info_poweredBy: '搭載',
        info_yourImpact: 'あなたの気候変動への影響',
        info_joinShoppers: '300万人以上の買い物客に参加して数える',
        info_ourFriendsEstimate: 'EcoCartは、注文に関わる出荷と製造の二酸化炭素排出量を次のように見積もっています'
    },
    'fr': {
        add_price_for: 'Add [price] for',
        sustainable_shipping: 'Sustainable Shipping',
        shipping_protection: 'Shipping Protection',
        loss_damage_theft_protection: 'Loss, Damage, Theft Protection',
        protect_your_order_and_the_environment: 'Protect Your Order and the Environment',
        protect_your_package_while_offseting_carbon: "Protect your package from loss, theft or damage while offsetting your order's carbon footprint",
        purchasing_this_will_offset_amount_carbon: 'Purchasing This Will Offset [co2_per_item] Of Carbon With <span class="sr-only">EcoCart</span>[ecocart_logo]',
        this_is_like: 'This is like',
        driving_amount_miles_in_an_average_gas_vehicle: '<span class="font-bold">driving [miles_per_item] miles</span> in an average gas vehicle',
        amount_tree_seedlings_grown_for_10_years: '<span class="font-bold">[seedlings_per_item] tree seedlings</span> grown for 10 years',
        template_support_forestry_projects: 'Support Global Forestry Projects',
        template_makeAClimateContribution: 'Make A Climate Contribution',
        template_removeAmtOfPlasticFromOcean: 'Remove <span class="text-primary-400">1 lb</span> of plastic from the ocean',
        template_shopSustainablyByCounteractingPlasticWaste: "Shop Sustainably By Counteracting Your Order's Plastic Waste",
        template_yourContributionWillRemoveAmtPlasticFromOcean: 'Your <span class="text-primary-400">$1.00</span> contribution will remove <span class="text-primary-400">1 lb</span> of plastic from the ocean, counteracting the waste associated with the product and its packaging',
        template_ecocartEnablesUsToPartnerWithOceanPlasticRemovalProjects: 'EcoCart enables us to partner with ocean plastic removal projects in order to help us fight against climate change and counteract the plastic waste associated with each of our orders.',
        template_byCheckingOutWithEcoCartYourOrderWillIncludeAnInvestmentFutureHealth: 'By checking out with EcoCart, your order will include an investment in the future health of the planet by helping clean plastic up from the ocean.',
        template_thisIsEquivalentToAmtPlasticBottles: 'This is equivalent to the amount of plastic found in <span class="text-primary-400">24</span> 16oz water bottles',
        template_accordingToUNEnvironment: 'According to the UN Environment Programme, the average person is responsible for 156 water bottles being discarded as waste every year. So, it would take <span class="text-primary-400">[plastic_to_purchases]</span> purchases to counteract the average consumer\'s annual water bottle waste.',
        template_yourContributionWillHelpFundCleanHub: 'Your contribution will help fund Clean Hub, a certified plastic removal project',
        template_cleanHubIsAVerifiedProject: 'Clean Hub is a verified project dedicated to promoting sustainability and environmental preservation.',
        template_brandHasPartneredWithEcocart: '[brand] has partnered with EcoCart to estimate and offset the carbon footprint of your order by funding verified carbon removal projects around the world.',
        template_yourContributionAtWork: 'Your contribution at work...',
        template_verifiedBy: 'Verified by',
        template_yourOrderWillOffsetAmountCarbon: 'Votre commande compensera [co2] de carbone',
        template_byCheckingOutWithEcocartYourOrderWillHelp: 'En passant par EcoCart, votre commande contribuera à soutenir le [project]',
        template_shopSustainably: 'Shop Sustainably',
        template_ecomindedShoppersLikeYouHaveOffset: 'Way to go! Eco-minded shoppers like you have <span class="text-primary-400">offset [global_all_time_carbon_offset] of Carbon</span> when checking out with EcoCart.',
        template_ofCarbonOffset: 'of Carbon Offset',
        template_goToStore: 'Go To Store',
        template_werePlantingTreesOnOrders: "We're Planting [eco_incentives_trees] Trees On Orders Over [eco_incentives_threshold]",
        template_youreValueAwayFromPlantingTrees: "You're [amount_needed_for_incentives] Away From Planting [eco_incentives_trees] Trees",
        template_hoorayYourOrderWillPlantTreesMerchantWillAlsoOffset: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. [brand] Will Also Offset The Emissions Of Your Order At Checkout!',
        template_hoorayYourOrderWillPlantTreesMaximizeImpact: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. Maximize Your Impact By Offsetting Your Order At Checkout!',
        template_merchantWillOffsetTwiceAsMuchCarbonAsYourOrderEmits: '<span class="text-primary-400">[brand]</span> will offset twice as much carbon as your order emits',
        template_merchantWillCoverTheCostOfOffsettingYourOrder: '<span class="text-primary-400">[brand]</span> will cover the cost of offsetting your order\'s carbon footprint',
        template_selectEcoCartInYourCartOrAtCheckout: 'Select EcoCart in your cart or at checkout to effortlessly contribute towards a greener future by offsetting',
        template_offsetYourOrdersCarbonFootPrintByCheckingOutWithEcocart: "Offset your order's carbon footprint by checking out with EcoCart",
        template_ecocartEstimatesEachOrdersCarbonFootprintLifeTimeUsage: "EcoCart estimates each order's carbon footprint by calculating variables such as shipping method, distance, materials, manufacturing processes, industry averages, lifetime usage, and more.",
        template_merchantIsOffsettingYourOrdersCarbonFootprintMatchTheirContributionDouble: "[brand] is offsetting your order's carbon footprint, <strong>match their contribution to double the impact</strong>",
        template_merchantWillOffsetYourOrdersCarbonFootprint: '<span class="text-primary-400">[brand]</span> will offset your order\'s carbon footprint',
        template_merchantWillDoubleYourOrdersCarbonOffset: '<span class="text-primary-400">[brand]</span> will double your order\'s carbon offset',
        template_merchantWillDoubleTheImpactByMatching: '<strong>[brand] will double the impact</strong> by matching your contribution',
        template_merchantMadeYourOrderClimatePositive: '[brand] Made Your Order Climate Positive',
        template_offsetTwiceAsMuchCarbonAsYourOrderEmits: "Compenser deux fois plus de carbone que votre commande n'émet",
        template_merchantWillMatchQuadrupling: '<span class="text-primary-400">[brand]</span> will match your contribution, quadrupling your order\'s total carbon offset!',
        template_yourContributionTwiceAsMuch: 'Votre <span class="text-primary-400">[price]</span> contribution compensera <span class="text-primary-400">[co2]</span> de carbone, soit deux fois plus que l\'empreinte estimée de votre commande, créant ainsi un impact environnemental positif et durable.',
        template_ecocartEstimatesEachOrderOffest2x: "EcoCart estime l'empreinte carbone de chaque commande en calculant des variables telles que le mode de livraison, la distance, les matériaux, les processus de fabrication, les moyennes de l'industrie, la durée de vie d'utilisation, et plus encore.<br><br>En finalisant votre achat avec EcoCart, votre commande doublera la compensation de son empreinte carbone estimée, créant ainsi un impact environnemental positif et durable.",
        template_yourOrderWillOffset4x: "Votre commande compensera 4x la quantité de carbone qu'il a été estimé avoir émise, en faisant un impact environnemental positif et durable",
        template_merchantWillOffsetTwiceAsMuch: '<span class="text-primary-400">[brand]</span> compensera <span class="text-primary-400">[co2]</span> de carbone, soit deux fois plus que l\'empreinte estimée de votre commande, créant ainsi un impact environnemental positif et durable',
        template_byContributingYourOrderWillOffset4x: "En contribuant, votre commande quadruplera la compensation de ses émissions de carbone estimées, atteignant ainsi quatre fois l'impact environnemental !",
        template_matchTheirContributionToQuadruple: "Match their contribution to quadruple your order's total carbon offset!",
        template_offsetMyOrdersFootprint: "Offset My Order's Carbon Footprint",
        template_ecocartEstimatesEachOrdersCarbonFootprint: "EcoCart estime l'empreinte carbone de chaque commande en calculant des variables telles que la méthode d'expédition, la distance, les matériaux, les processus de fabrication, les moyennes de l'industrie et plus encore.",
        template_ecocartPartnersWithCertifiedProjects: "EcoCart s'associe à des projets certifiés pour compenser les émissions de carbone liées à la fabrication et à l'expédition de votre commande.",
        template_merchantOffsetYourCarbonFootprint: '<span class="text-primary-400">[brand]</span> Offset Your Carbon Footprint',
        template_merchantContributionWillHelpFund: '<span class="text-primary-400">[brand]</span><span class="text-primary-400">\'s</span> contribution will help fund <span class="text-primary-400">[project]</span>, a certified carbon offsetting program.',
        template_yourContributionWillHelpFund: 'Votre contribution aidera à financer <span class="text-primary-400">[projet]</span>, un programme certifié de compensation carbone.',
        template_byContributingYouWillOffsetCarbon: 'En contribuant <span class="text-primary-400">[price]</span>, vous compenserez <span class="text-primary-400">[co2]</span> de carbone.',
        template_merchantWillOffsetCarbon: '<span class="text-primary-400">[brand]</span> compensera <span class="text-primary-400">[co2]</span> de carbone',
        template_yourOrderHasBeenOffsetMaximizeImpact: '<span class="font-semibold">Your order has been offset!</span> Maximize your impact by going climate positive!',
        template_yourContributionWillGoTowards: 'Your contribution will go towards funding the [project]',
        template_withASmallContribution: 'With a small contribution, we can mitigate the emissions calculated from the items in your cart and reduce your carbon footprint',
        template_EcoCartWillCalculateTheFootprint: 'EcoCart will calculate the carbon footprint of your order',
        template_throughCollectiveActionYouAreTakingProactive: 'Through collective action, we can make a real difference in protecting our planet. By offsetting your carbon footprint, you are taking a proactive step towards building a more sustainable future',
        template_merchantHasOffsetYourOrderWith: '[brand] a compensé votre commande avec',
        template_offsetYourOrderAtCheckoutWith: 'Compensez votre commande à la caisse avec',
        template_yourOrderBreakdown: 'Détail de votre commande',
        template_projectIsAVerifiedProject: '<a href="[project_url]" target="_blank" rel="noopener noreferrer">[project]</a> est un projet vérifié dédié à la promotion de la durabilité et de la préservation de l\'environnement.',
        template_yourOrderWillOffsetAtLeastCarbon: 'Your order will offset at least [co2] of carbon which is more carbon than your order produces, exceeding net zero standards!',
        template_doubleTheImpact: "Doublez l'impact en égalant leur contribution, doublant ainsi l'impact de votre contribution.",
        template_oneYearTreeAbsorbCO2Amount: "Selon le Département de l'Agriculture des États-Unis, en une année, un arbre mature peut absorber plus de 48 livres de dioxyde de carbone de l'atmosphère et libérer de l'oxygène en échange.",
        template_carbonTreesCanAbsorb: 'Cela équivaut à la quantité de carbone que <span class="text-primary-400">[lbs_co2_absorbed_by_one_tree_annually] arbres</span> peuvent absorber en un an',
        template_ecocartProprietaryAlgo: "L'algorithme exclusif d'EcoCart estime en temps réel l'empreinte carbone de chaque commande en calculant des variables telles que le mode d'expédition, la distance, les méthodes de fabrication, les matériaux, et plus encore.",
        template_shopSustainablyByOffsetingCO2Footprint: "Faites des achats durables en compensant l'empreinte carbone de votre commande",
        template_reduceMyOrderCO2: "Réduisez l'empreinte carbone de ma commande",
        template_makeMyOrder: 'Rendre ma commande neutre en carbone',
        template_makeMyOrderClimatePositive: "Doublez l'Impact Climatique de Ma Commande",
        template_offsetEstimatedCo2e: 'Compensez les émissions de carbone estimées de votre commande',
        template_merchantMatchYourContribution: '<span class="text-primary-400">[brand]</span> égalera votre contribution, doublant la compensation de son empreinte carbone estimée',
        template_yourOrderHasBeen: 'Votre commande a été rendue neutre en carbone',
        template_yourOrderWillBe: 'Votre commande sera rendue neutre en carbone par [brand]',
        template_addToMake: 'Ajouter [price] pour rendre votre commande neutre en carbone',
        template_addToReduce: 'Ajoutez [price] pour réduire votre empreinte carbone',
        template_addToMatch: "Ajoutez [price] pour correspondre à leur contribution et doublez l'impact!",
        template_addToDouble: "Ajoutez [price] pour doubler l'impact",
        template_byCheckingTheBox: 'En cochant la case, vous protégerez les [trees] arbres du [project], qui absorbent le CO2 créé par votre commande, ce qui la rend neutre en carbone!',
        template_byDonating: 'En faisant un don, vous protégerez les [trees] arbres du [project], qui absorbent le CO2 créé par votre commande, ce qui la rend neutre en carbone!',
        template_byDonatingMatch: "En faisant un don, vous protégerez les [trees] arbres du [project], qui absorbent le CO2 créé par votre commande. [brand] égalera votre contribution pour doubler l'impact et sauver encore plus d'arbres.",
        template_carbonNeutralOrderFor: 'Commande neutre en carbone pour [price]',
        template_carbonNeutralShipping: 'Expédition neutre en carbone',
        template_madeCarbonNeutralBy: 'Fabriqué neutre en carbone par [brand]',
        template_brandIsMaking: '[brand] rend 100% des commandes neutres en carbone',
        template_brandWillMatch: "[brand] correspondra à votre contribution et doublera l'impact!",
        template_brandIsProudMerchantPaying: '[brand] est fier de compenser les émissions de carbone de votre commande en protégeant [trees] arbres dans le [project], ce qui le rend neutre en carbone.',
        template_brandIsProudCustomerMatching: "[brand] est fière de compenser les émissions de carbone de votre commande en protégeant les [trees] arbres du [project]. En faisant un don, vous égalerez la contribution de [brand], doublerez l'impact et sauverez encore plus d'arbres!",
        template_brandsContribution: 'La contribution de [brand] servira directement à financer le [project]',
        template_impact: 'Impact',
        template_weHaveOffset: "Nous avons compensé [total_co2_offset] livres d'émissions de carbone",
        template_wePartneredWithEcocart: 'We partnered with EcoCart to make every single order carbon neutral (aka net zero)! EcoCart will help us eliminate, capture, or mitigate all of the carbon emissions created from manufacturing and shipping our products.',
        template_learnMore: 'Learn More',
        template_shoppersOffsetTheirCarbonFootprint: '<b>[global_daily_ecocart_order_count] Shoppers</b> Have Offset Their Carbon Footprint Today',
        cta_on: 'Oui',
        cta_off: 'Non',
        cta_add: 'Ajouter',
        cta_remove: 'Retirer',
        cta_close: 'Fermer',
        cta_learnMore: 'En apprendre plus',
        info_milesDriven: 'km parcourus',
        info_businessCards: "cartes d'affaires",
        info_arcticIce: 'glace arctique sauvée',
        info_lightbulbs: 'ampoules',
        info_co2Emissions: "d'émissions de CO₂",
        info_lbsOfCo2: "kg d'émissions de CO₂",
        info_trees: 'arbres',
        info_treesSaved: 'arbres sauvés',
        info_carbonNeutralOrder: 'ordre neutre en carbone',
        info_carbonNeutralOption: 'Option carboneutre',
        info_or: 'ou alors',
        info_poweredBy: 'Alimenté par',
        info_yourImpact: 'Votre impact climatique',
        info_joinShoppers: 'Comme plus de 3 millions de personnes, faites le bon geste',
        info_ourFriendsEstimate: "L'empreinte carbone de la production et de l'expédition de votre commande est estimée par EcoCart à :"
    },
    'da': {
        add_price_for: 'Add [price] for',
        sustainable_shipping: 'Sustainable Shipping',
        shipping_protection: 'Shipping Protection',
        loss_damage_theft_protection: 'Loss, Damage, Theft Protection',
        protect_your_order_and_the_environment: 'Protect Your Order and the Environment',
        protect_your_package_while_offseting_carbon: "Protect your package from loss, theft or damage while offsetting your order's carbon footprint",
        purchasing_this_will_offset_amount_carbon: 'Purchasing This Will Offset [co2_per_item] Of Carbon With <span class="sr-only">EcoCart</span>[ecocart_logo]',
        this_is_like: 'This is like',
        driving_amount_miles_in_an_average_gas_vehicle: '<span class="font-bold">driving [miles_per_item] miles</span> in an average gas vehicle',
        amount_tree_seedlings_grown_for_10_years: '<span class="font-bold">[seedlings_per_item] tree seedlings</span> grown for 10 years',
        template_support_forestry_projects: 'Support Global Forestry Projects',
        template_makeAClimateContribution: 'Make A Climate Contribution',
        template_removeAmtOfPlasticFromOcean: 'Remove <span class="text-primary-400">1 lb</span> of plastic from the ocean',
        template_shopSustainablyByCounteractingPlasticWaste: "Shop Sustainably By Counteracting Your Order's Plastic Waste",
        template_yourContributionWillRemoveAmtPlasticFromOcean: 'Your <span class="text-primary-400">$1.00</span> contribution will remove <span class="text-primary-400">1 lb</span> of plastic from the ocean, counteracting the waste associated with the product and its packaging',
        template_ecocartEnablesUsToPartnerWithOceanPlasticRemovalProjects: 'EcoCart enables us to partner with ocean plastic removal projects in order to help us fight against climate change and counteract the plastic waste associated with each of our orders.',
        template_byCheckingOutWithEcoCartYourOrderWillIncludeAnInvestmentFutureHealth: 'By checking out with EcoCart, your order will include an investment in the future health of the planet by helping clean plastic up from the ocean.',
        template_thisIsEquivalentToAmtPlasticBottles: 'This is equivalent to the amount of plastic found in <span class="text-primary-400">24</span> 16oz water bottles',
        template_accordingToUNEnvironment: 'According to the UN Environment Programme, the average person is responsible for 156 water bottles being discarded as waste every year. So, it would take <span class="text-primary-400">[plastic_to_purchases]</span> purchases to counteract the average consumer\'s annual water bottle waste.',
        template_yourContributionWillHelpFundCleanHub: 'Your contribution will help fund Clean Hub, a certified plastic removal project',
        template_cleanHubIsAVerifiedProject: 'Clean Hub is a verified project dedicated to promoting sustainability and environmental preservation.',
        template_brandHasPartneredWithEcocart: '[brand] has partnered with EcoCart to estimate and offset the carbon footprint of your order by funding verified carbon removal projects around the world.',
        template_yourContributionAtWork: 'Your contribution at work...',
        template_verifiedBy: 'Verified by',
        template_yourOrderWillOffsetAmountCarbon: 'Din ordre vil udligne [co2] af kuldioxid',
        template_byCheckingOutWithEcocartYourOrderWillHelp: 'Ved at betale med EcoCart vil din ordre hjælpe med at støtte [project]',
        template_shopSustainably: 'Shop Sustainably',
        template_ecomindedShoppersLikeYouHaveOffset: 'Way to go! Eco-minded shoppers like you have <span class="text-primary-400">offset [global_all_time_carbon_offset] of Carbon</span> when checking out with EcoCart.',
        template_ofCarbonOffset: 'of Carbon Offset',
        template_goToStore: 'Go To Store',
        template_werePlantingTreesOnOrders: "We're Planting [eco_incentives_trees] Trees On Orders Over [eco_incentives_threshold]",
        template_youreValueAwayFromPlantingTrees: "You're [amount_needed_for_incentives] Away From Planting [eco_incentives_trees] Trees",
        template_hoorayYourOrderWillPlantTreesMerchantWillAlsoOffset: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. [brand] Will Also Offset The Emissions Of Your Order At Checkout!',
        template_hoorayYourOrderWillPlantTreesMaximizeImpact: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. Maximize Your Impact By Offsetting Your Order At Checkout!',
        template_merchantWillOffsetTwiceAsMuchCarbonAsYourOrderEmits: '<span class="text-primary-400">[brand]</span> will offset twice as much carbon as your order emits',
        template_merchantWillCoverTheCostOfOffsettingYourOrder: '<span class="text-primary-400">[brand]</span> will cover the cost of offsetting your order\'s carbon footprint',
        template_selectEcoCartInYourCartOrAtCheckout: 'Select EcoCart in your cart or at checkout to effortlessly contribute towards a greener future by offsetting',
        template_offsetYourOrdersCarbonFootPrintByCheckingOutWithEcocart: "Offset your order's carbon footprint by checking out with EcoCart",
        template_ecocartEstimatesEachOrdersCarbonFootprintLifeTimeUsage: "EcoCart estimates each order's carbon footprint by calculating variables such as shipping method, distance, materials, manufacturing processes, industry averages, lifetime usage, and more.",
        template_merchantIsOffsettingYourOrdersCarbonFootprintMatchTheirContributionDouble: "[brand] is offsetting your order's carbon footprint, <strong>match their contribution to double the impact</strong>",
        template_merchantWillOffsetYourOrdersCarbonFootprint: '<span class="text-primary-400">[brand]</span> will offset your order\'s carbon footprint',
        template_merchantWillDoubleYourOrdersCarbonOffset: '<span class="text-primary-400">[brand]</span> will double your order\'s carbon offset',
        template_merchantWillDoubleTheImpactByMatching: '<strong>[brand] will double the impact</strong> by matching your contribution',
        template_merchantMadeYourOrderClimatePositive: '[brand] Made Your Order Climate Positive',
        template_offsetTwiceAsMuchCarbonAsYourOrderEmits: 'Opvej dobbelt så meget kulstof som din ordre udsender',
        template_merchantWillMatchQuadrupling: '<span class="text-primary-400">[brand]</span> will match your contribution, quadrupling your order\'s total carbon offset!',
        template_yourContributionTwiceAsMuch: 'Din <span class="text-primary-400">[price]</span> bidrag vil opveje <span class="text-primary-400">[co2]</span> af kulstof, dobbelt så meget som din ordres estimerede fodaftryk, hvilket skaber en positiv varig miljøpåvirkning',
        template_ecocartEstimatesEachOrderOffest2x: 'EcoCart estimerer hver ordres CO2-aftryk ved at beregne variabler som forsendelsesmetode, afstand, materialer, fremstillingsprocesser, branchegennemsnit, levetidsbrug og mere.<br><br>Ved at tjekke ud med EcoCart vil din ordre fordoble kompensationen af dets estimerede CO2-aftryk, hvilket skaber en positiv og varig miljøpåvirkning.',
        template_yourOrderWillOffset4x: 'Din ordre vil opveje 4x den mængde kulstof, det blev estimeret at have udledt, hvilket gør det til en positiv varig miljøpåvirkning',
        template_merchantWillOffsetTwiceAsMuch: '<span class="text-primary-400">[brand]</span> vil udligne <span class="text-primary-400">[co2]</span> af kulstof, dobbelt så meget som din ordres estimerede fodaftryk, hvilket skaber en positiv varig miljøpåvirkning',
        template_byContributingYourOrderWillOffset4x: 'Ved at bidrage vil din ordre firdoble kompensationen af dens estimerede CO2-udledning, hvilket opnår fire gange den miljømæssige effekt!',
        template_matchTheirContributionToQuadruple: "Match their contribution to quadruple your order's total carbon offset!",
        template_offsetMyOrdersFootprint: "Offset My Order's Carbon Footprint",
        template_ecocartEstimatesEachOrdersCarbonFootprint: 'EcoCart samarbejder med certificerede projekter for at opveje kuldioxidemissionerne fra fremstilling og forsendelse af din ordre.',
        template_ecocartPartnersWithCertifiedProjects: 'EcoCart partners with certified projects to offset the carbon emissions from manufacturing and shipping your order.',
        template_merchantOffsetYourCarbonFootprint: '<span class="text-primary-400">[brand]</span> Offset Your Carbon Footprint',
        template_merchantContributionWillHelpFund: '<span class="text-primary-400">[brand]</span><span class="text-primary-400">\'s</span> contribution will help fund <span class="text-primary-400">[project]</span>, a certified carbon offsetting program.',
        template_yourContributionWillHelpFund: 'Din donation vil hjælpe med at finansiere <span class="text-primary-400">[project]</span>, et certificeret program til kuldioxidkompensation.',
        template_byContributingYouWillOffsetCarbon: 'Ved at bidrage med <span class="text-primary-400">[price]</span> vil du kompensere <span class="text-primary-400">[co2]</span> CO2.',
        template_merchantWillOffsetCarbon: '<span class="text-primary-400">[brand]</span> vil opveje <span class="text-primary-400">[co2]</span> af CO2',
        template_yourOrderHasBeenOffsetMaximizeImpact: '<span class="font-semibold">Your order has been offset!</span> Maximize your impact by going climate positive!',
        template_yourContributionWillGoTowards: 'Your contribution will go towards funding the [project]',
        template_withASmallContribution: 'With a small contribution, we can mitigate the emissions calculated from the items in your cart and reduce your carbon footprint',
        template_EcoCartWillCalculateTheFootprint: 'EcoCart will calculate the carbon footprint of your order',
        template_throughCollectiveActionYouAreTakingProactive: 'Through collective action, we can make a real difference in protecting our planet. By offsetting your carbon footprint, you are taking a proactive step towards building a more sustainable future',
        template_merchantHasOffsetYourOrderWith: '[brand] har kompenseret din ordre med',
        template_offsetYourOrderAtCheckoutWith: 'Offset din ordre ved kassen med',
        template_yourOrderBreakdown: 'Opdeling af din ordre',
        template_projectIsAVerifiedProject: '<a href="[project_url]" target="_blank" rel="noopener noreferrer">[project]</a> er et verificeret projekt dedikeret til at fremme bæredygtighed og miljøbevarelse.',
        template_yourOrderWillOffsetAtLeastCarbon: 'Your order will offset at least [co2] of carbon which is more carbon than your order produces, exceeding net zero standards!',
        template_doubleTheImpact: 'Forøg effekten ved at matche deres bidrag og fordoble effekten af dit bidrag.',
        template_oneYearTreeAbsorbCO2Amount: 'Ifølge det amerikanske landbrugsministerium kan et voksent træ på et år absorbere mere end 48 pund kuldioxid fra atmosfæren og frigive ilt som bytte.',
        template_carbonTreesCanAbsorb: 'Dette svarer til mængden af ​​kulstof <span class="text-primary-400">[lbs_co2_absorbed_by_one_tree_annually] træer</span> kan absorbere på ét år',
        template_ecocartProprietaryAlgo: 'EcoCarts proprietære algoritme estimerer hvert ords klimaaftryk i realtid ved at beregne variabler som forsendelsesmetode, afstand, fremstillingsmetoder, materialer og mere.',
        template_shopSustainablyByOffsetingCO2Footprint: 'Køb bæredygtigt ved at opveje din ords klimaaftryk',
        template_reduceMyOrderCO2: 'Reducer min ordres klimafodaftryk ',
        template_makeMyOrder: 'Make my order carbon neutral',
        template_makeMyOrderClimatePositive: 'Forøg klimaeffekten af min ordre to gange',
        template_offsetEstimatedCo2e: 'Opvej de estimerede CO2-udledninger fra din ordre',
        template_merchantMatchYourContribution: '<span class="text-primary-400">[brand]</span> vil matche dit bidrag, hvilket fordobler udligningen af dets estimerede CO2-aftryk',
        template_yourOrderHasBeen: 'Your order has been made carbon neutral',
        template_yourOrderWillBe: 'Your order will be made carbon neutral by [brand]',
        template_addToMake: 'Add [price] to make your order carbon neutral',
        template_addToReduce: 'Add [price] to reduce your carbon footprint',
        template_addToMatch: 'Add [price] to match their contribution and double the impact!',
        template_addToDouble: 'Add [price] to double the impact',
        template_byCheckingTheBox: "By checking the box, you'll be protecting [trees] trees in the [project], which are absorbing the CO2 created by your order, making it carbon neutral!",
        template_byDonating: "By donating, you'll be protecting [trees] trees in the [project], which are absorbing the CO2 created by your order, making it carbon neutral!",
        template_byDonatingMatch: "By donating, you'll be protecting [trees] trees in the [project], which are absorbing the CO2 created by your order. [brand] will match your contribution to double the impact and save even more trees.",
        template_carbonNeutralOrderFor: 'Carbon Neutral Order for [price]',
        template_carbonNeutralShipping: 'Carbon Neutral Shipping',
        template_madeCarbonNeutralBy: 'Made Carbon Neutral by [brand]',
        template_brandIsMaking: '[brand] is Making 100% of Orders Carbon Neutral',
        template_brandWillMatch: "[brand] will match your contribution, doubling your order's impact",
        template_brandIsProudMerchantPaying: '[brand] is proud to offset the carbon emissions from your order by protecting [trees] trees in the [project], making it carbon neutral.',
        template_brandIsProudCustomerMatching: "[brand] is proud to offset the carbon emissions from your order by protecting [trees] trees in the [project]. By donating, you'll be matching [brand]'s contribution, doubling the impact and saving even more trees!",
        template_brandsContribution: "[brand]'s contribution will go directly to help fund the [project]",
        template_impact: 'Impact',
        template_weHaveOffset: 'Vi har udlignet [total_co2_offset] lbs af kulstofemissioner',
        template_wePartneredWithEcocart: 'We partnered with EcoCart to make every single order carbon neutral (aka net zero)! EcoCart will help us eliminate, capture, or mitigate all of the carbon emissions created from manufacturing and shipping our products.',
        template_learnMore: 'Learn More',
        template_shoppersOffsetTheirCarbonFootprint: '<b>[global_daily_ecocart_order_count] Shoppers</b> Have Offset Their Carbon Footprint Today',
        cta_on: 'On',
        cta_off: 'Off',
        cta_add: 'Add',
        cta_remove: 'Remove',
        cta_close: 'Close',
        cta_learnMore: 'Learn More',
        info_milesDriven: 'km driven',
        info_businessCards: 'business cards',
        info_arcticIce: 'arctic ice saved',
        info_lightbulbs: 'lightbulbs powered',
        info_co2Emissions: 'of CO₂ emissions',
        info_lbsOfCo2: 'kg of CO₂ offset',
        info_treesSaved: 'trees saved',
        info_carbonNeutralOrder: 'Carbon Neutral Order',
        info_carbonNeutralOption: 'Carbon neutral option with',
        info_or: 'or',
        info_trees: 'trees',
        info_poweredBy: 'Powered by',
        info_yourImpact: 'Your Climate Impact',
        info_joinShoppers: 'Join over 3 million shoppers and counting',
        info_ourFriendsEstimate: 'Our friends at EcoCart have estimated the carbon footprint of shipping and manufacturing your specific order to be:'
    },
    'de': {
        add_price_for: 'Add [price] for',
        sustainable_shipping: 'Sustainable Shipping',
        shipping_protection: 'Shipping Protection',
        loss_damage_theft_protection: 'Loss, Damage, Theft Protection',
        protect_your_order_and_the_environment: 'Protect Your Order and the Environment',
        protect_your_package_while_offseting_carbon: "Protect your package from loss, theft or damage while offsetting your order's carbon footprint",
        purchasing_this_will_offset_amount_carbon: 'Purchasing This Will Offset [co2_per_item] Of Carbon With <span class="sr-only">EcoCart</span>[ecocart_logo]',
        this_is_like: 'This is like',
        driving_amount_miles_in_an_average_gas_vehicle: '<span class="font-bold">driving [miles_per_item] miles</span> in an average gas vehicle',
        amount_tree_seedlings_grown_for_10_years: '<span class="font-bold">[seedlings_per_item] tree seedlings</span> grown for 10 years',
        template_support_forestry_projects: 'Unterstützung von globalen Forstprojekten',
        template_makeAClimateContribution: 'Machen Sie einen Klimabeitrag',
        template_removeAmtOfPlasticFromOcean: 'Entfernen <span class="text-primary-400">1 lb</span> von Plastik aus dem Ozean',
        template_shopSustainablyByCounteractingPlasticWaste: 'Nachhaltig einkaufen, indem Sie den Plastikmüll Ihrer Bestellung ausgleichen',
        template_yourContributionWillRemoveAmtPlasticFromOcean: 'Ihr <span class="text-primary-400">$1.00</span> Beitrag wird <span class="text-primary-400">1 lb</span> Plastik aus dem Ozean entfernen und den mit dem Produkt und seiner Verpackung verbundenen Abfall ausgleichen.',
        template_ecocartEnablesUsToPartnerWithOceanPlasticRemovalProjects: 'EcoCart ermöglicht es uns, mit Meeresplastik-Entfernungsprojekten zusammenzuarbeiten, um uns im Kampf gegen den Klimawandel zu unterstützen und den mit jeder unserer Bestellungen verbundenen Plastikabfall auszugleichen.',
        template_byCheckingOutWithEcoCartYourOrderWillIncludeAnInvestmentFutureHealth: 'Durch das Auschecken mit EcoCart wird Ihre Bestellung eine Investition in die zukünftige Gesundheit des Planeten beinhalten, indem Plastik aus dem Ozean gereinigt wird.',
        template_thisIsEquivalentToAmtPlasticBottles: 'Dies entspricht der Menge an Plastik, die in <span class="text-primary-400">24</span> 16-Unzen-Wasserflaschen gefunden wurde.',
        template_accordingToUNEnvironment: 'Nach Angaben des UN-Umweltprogramms ist der durchschnittliche Mensch jedes Jahr für die Entsorgung von 156 Wasserflaschen als Abfall verantwortlich. Es würde also <span class="text-primary-400">[plastic_to_purchases]</span> Einkäufe erfordern, um den jährlichen Wasserflaschenabfall des durchschnittlichen Verbrauchers auszugleichen.',
        template_yourContributionWillHelpFundCleanHub: 'Ihr Beitrag wird Clean Hub, ein zertifiziertes Plastikentfernungs [project] finanzieren.',
        template_cleanHubIsAVerifiedProject: 'Clean Hub ist ein verifiziertes [project], das sich der Förderung von Nachhaltigkeit und Umweltschutz widmet.',
        template_brandHasPartneredWithEcocart: '[brand] hat sich mit EcoCart zusammengetan, um den CO2-Fußabdruck Ihrer Bestellung zu schätzen und auszugleichen, indem verifizierte Projekte zur CO2-Entfernung auf der ganzen Welt finanziert werden.',
        template_yourContributionAtWork: 'Ihre Beitrag bei der Arbeit...',
        template_verifiedBy: 'Verifiziert von',
        template_yourOrderWillOffsetAmountCarbon: 'Ihre Bestellung gleicht [co2] Kohlenstoff aus',
        template_byCheckingOutWithEcocartYourOrderWillHelp: 'Durch den Kauf mit EcoCart unterstützt Ihre Bestellung das [project]',
        template_shopSustainably: 'Nachhaltig einkaufen',
        template_ecomindedShoppersLikeYouHaveOffset: 'Way to go! Umweltbewusste Käufer wie Sie haben <span class="text-primary-400">[global_all_time_carbon_offset] von Kohlenstoff</span> ausgeglichen, wenn sie mit EcoCart auschecken.',
        template_ofCarbonOffset: 'von CO2-Kompensation',
        template_goToStore: 'Gehen Sie zum Geschäft',
        template_werePlantingTreesOnOrders: 'Wir Pflanzen [eco_incentives_trees] Bäume bei Bestellungen über [eco_incentives_threshold]',
        template_youreValueAwayFromPlantingTrees: 'Du bist [amount_needed_for_incentives] entfernt davon, [eco_incentives_trees] Bäume zu pflanzen.',
        template_hoorayYourOrderWillPlantTreesMerchantWillAlsoOffset: 'Hooray! Ihre Bestellung wird [eco_incentives_trees] Bäume pflanzen. [brand] wird auch die Emissionen Ihrer Bestellung an der Kasse ausgleichen!',
        template_hoorayYourOrderWillPlantTreesMaximizeImpact: 'Hooray! Ihre Bestellung wird [eco_incentives_trees] Bäume pflanzen. Maximieren Sie Ihre Wirkung, indem Sie Ihre Bestellung an der Kasse ausgleichen!',
        template_merchantWillOffsetTwiceAsMuchCarbonAsYourOrderEmits: '<span class="text-primary-400">[brand]</span> wird doppelt so viel Kohlenstoff ausgleichen, wie Ihre Bestellung emittiert.',
        template_merchantWillCoverTheCostOfOffsettingYourOrder: '<span class="text-primary-400">[brand]</span> übernimmt die Kosten für die Kompensation des CO2-Fußabdrucks Ihrer Bestellung.',
        template_selectEcoCartInYourCartOrAtCheckout: 'Wählen Sie EcoCart in Ihrem Warenkorb oder an der Kasse aus, um mühelos zu einer grüneren Zukunft beizutragen, indem Sie ausgleichen.',
        template_offsetYourOrdersCarbonFootPrintByCheckingOutWithEcocart: "Offset your order's carbon footprint by checking out with EcoCart\nAusgleichen Sie den CO2-Fußabdruck Ihrer Bestellung, indem Sie mit EcoCart auschecken",
        template_ecocartEstimatesEachOrdersCarbonFootprintLifeTimeUsage: "EcoCart estimates each order's carbon footprint by calculating variables such as shipping method, distance, materials, manufacturing processes, industry averages, lifetime usage, and more.",
        template_merchantIsOffsettingYourOrdersCarbonFootprintMatchTheirContributionDouble: '[brand] gleicht den CO2-Fußabdruck Ihrer Bestellung aus, <strong>gleichen Sie ihren Beitrag aus, um die Auswirkungen zu verdoppeln</strong>',
        template_merchantWillOffsetYourOrdersCarbonFootprint: '<span class="text-primary-400">[brand]</span> wird den CO2-Fußabdruck Ihrer Bestellung ausgleichen.',
        template_merchantWillDoubleYourOrdersCarbonOffset: '<span class="text-primary-400">[brand]</span> verdoppelt den CO2-Ausgleich Ihrer Bestellung',
        template_merchantWillDoubleTheImpactByMatching: '<strong>[brand] wird die Auswirkung verdoppeln</strong>, indem sie Ihren Beitrag anpasst.',
        template_merchantMadeYourOrderClimatePositive: '[brand] hat Ihre Bestellung klimapositiv gemacht.',
        template_offsetTwiceAsMuchCarbonAsYourOrderEmits: 'Offset zweimal so viel Kohlenstoff wie Ihre Bestellung emittiert.',
        template_merchantWillMatchQuadrupling: '<span class="text-primary-400">[brand]</span> wird Ihren Beitrag verdoppeln und damit die Gesamtkohlenstoffkompensation Ihrer Bestellung vervierfachen!',
        template_yourContributionTwiceAsMuch: 'Dein <span class="text-primary-400">[price]</span> Beitrag wird <span class="text-primary-400">[co2]</span> Kohlenstoff ausgleichen, doppelt so viel wie der geschätzte Fußabdruck deiner Bestellung, und somit einen positiven, nachhaltigen Umwelteinfluss bewirken.',
        template_ecocartEstimatesEachOrderOffest2x: 'EcoCart schätzt den CO2-Fußabdruck jeder Bestellung, indem es Variablen wie Versandmethode, Entfernung, Materialien, Herstellungsprozesse, Branchendurchschnitte, Lebensdauer und mehr berechnet.<br><br>Durch den Checkout mit EcoCart wird Ihre Bestellung den Ausgleich ihres geschätzten CO2-Fußabdrucks verdoppeln und einen positiven, nachhaltigen Umwelteinfluss bewirken.',
        template_yourOrderWillOffset4x: 'Ihre Bestellung wird das 4-fache der geschätzten Kohlenstoffemissionen ausgleichen, was einen positiven und nachhaltigen Umwelteinfluss bewirkt.',
        template_merchantWillOffsetTwiceAsMuch: '<span class="text-primary-400">[brand]</span> wird <span class="text-primary-400">[co2]</span> Kohlenstoff ausgleichen, doppelt so viel wie der geschätzte Fußabdruck Ihrer Bestellung, und damit einen positiven, nachhaltigen Umwelteinfluss bewirken',
        template_byContributingYourOrderWillOffset4x: 'Durch Ihren Beitrag wird Ihre Bestellung die Kompensation ihrer geschätzten CO2-Emissionen vervierfachen und somit die vierfache Umweltwirkung erzielen!',
        template_matchTheirContributionToQuadruple: 'Gleichen Sie ihren Beitrag aus, um die Gesamtkompensation des CO2-Fußabdrucks Ihrer Bestellung zu vervierfachen!',
        template_offsetMyOrdersFootprint: 'Ausgleichen Sie den CO2-Fußabdruck meiner Bestellung',
        template_ecocartEstimatesEachOrdersCarbonFootprint: 'EcoCart schätzt den CO2-Fußabdruck jeder Bestellung, indem Variablen wie Versandmethode, Entfernung, Materialien, Herstellungsprozesse, Branchendurchschnitte und mehr berechnet werden.',
        template_ecocartPartnersWithCertifiedProjects: 'EcoCart arbeitet mit zertifizierten Projekten zusammen, um die Kohlenstoffemissionen aus der Herstellung und dem Versand Ihrer Bestellung auszugleichen.',
        template_merchantOffsetYourCarbonFootprint: '<span class="text-primary-400">[brand]</span> Kompensieren Sie Ihren CO2-Fußabdruck',
        template_merchantContributionWillHelpFund: '<span class="text-primary-400">[brand]</span><span class="text-primary-400">\'s</span> Beitrag wird dazu beitragen, [project], ein zertifiziertes Programm zur Kompensation von Kohlenstoffemissionen, zu finanzieren.',
        template_yourContributionWillHelpFund: 'Ihr Beitrag wird dazu beitragen, <span class="text-primary-400">[project]</span>, ein zertifiziertes Programm zur CO2-Kompensation, zu finanzieren.',
        template_byContributingYouWillOffsetCarbon: 'Durch einen Beitrag von <span class="text-primary-400">[price]</span> kompensieren Sie <span class="text-primary-400">[co2]</span> CO2.',
        template_merchantWillOffsetCarbon: '<span class="text-primary-400">[brand]</span> wird <span class="text-primary-400">[co2]</span> Kohlenstoff ausgleichen',
        template_yourOrderHasBeenOffsetMaximizeImpact: '<span class="font-semibold">Ihre Bestellung wurde ausgeglichen!</span> Maximieren Sie Ihre Auswirkungen, indem Sie klimapositiv werden!',
        template_yourContributionWillGoTowards: 'Ihr Beitrag wird zur Finanzierung des [project] beitragen.',
        template_withASmallContribution: 'Mit einem kleinen Beitrag können wir die Emissionen, die aus den Artikeln in Ihrem Warenkorb berechnet werden, mildern und Ihren CO2-Fußabdruck reduzieren.',
        template_EcoCartWillCalculateTheFootprint: 'EcoCart wird den CO2-Fußabdruck Ihrer Bestellung berechnen.',
        template_throughCollectiveActionYouAreTakingProactive: 'Durch kollektive Maßnahmen können wir einen echten Unterschied beim Schutz unseres Planeten machen. Durch die Kompensation Ihres CO2-Fußabdrucks gehen Sie proaktiv einen Schritt in Richtung einer nachhaltigeren Zukunft.',
        template_merchantHasOffsetYourOrderWith: '[brand] hat Ihre Bestellung mit',
        template_offsetYourOrderAtCheckoutWith: 'Kompensiere deine Bestellung an der Kasse mit',
        template_yourOrderBreakdown: 'Aufschlüsselung Ihrer Bestellung',
        template_projectIsAVerifiedProject: '<a href="[project_url]" target="_blank" rel="noopener noreferrer">[project]</a> ist ein verifiziertes Projekt, das sich der Förderung von Nachhaltigkeit und Umweltschutz widmet.',
        template_yourOrderWillOffsetAtLeastCarbon: 'Ihr Auftrag wird mindestens [co2] Kohlenstoff ausgleichen, was mehr Kohlenstoff ist, als Ihr Auftrag produziert, und die Netto-Null-Standards übertrifft!',
        template_doubleTheImpact: 'Verdoppeln Sie die Wirkung, indem Sie ihren Beitrag anpassen und die Wirkung Ihres Beitrags verdoppeln.',
        template_oneYearTreeAbsorbCO2Amount: 'Laut dem US-Landwirtschaftsministerium kann ein ausgewachsener Baum in einem Jahr mehr als 48 Pfund Kohlendioxid aus der Atmosphäre aufnehmen und dafür Sauerstoff freisetzen.',
        template_carbonTreesCanAbsorb: 'Dies entspricht der Menge an Kohlenstoff, die <span class="text-primary-400">[lbs_co2_absorbed_by_one_tree_annually] Bäume</span> in einem Jahr absorbieren können',
        template_ecocartProprietaryAlgo: 'Der proprietäre Algorithmus von EcoCart schätzt den CO2-Fußabdruck jeder Bestellung in Echtzeit, indem er Variablen wie Versandmethode, Entfernung, Herstellungsmethoden, Materialien und mehr berechnet.',
        template_shopSustainablyByOffsetingCO2Footprint: 'Einkaufen Sie nachhaltig, indem Sie den CO2-Fußabdruck Ihrer Bestellung ausgleichen',
        template_reduceMyOrderCO2: 'Reduzieren Sie den CO2-Fußabdruck meiner Bestellung',
        template_makeMyOrder: 'Machen Sie meine Bestellung klimaneutral',
        template_makeMyOrderClimatePositive: 'Verdoppeln Sie die Klimaauswirkungen meiner Bestellung',
        template_offsetEstimatedCo2e: 'Kompensieren Sie die geschätzten Kohlenstoffemissionen Ihrer Bestellung',
        template_merchantMatchYourContribution: '<span class="text-primary-400">[brand]</span> wird Ihren Beitrag verdoppeln und damit den Ausgleich seines geschätzten CO2-Fußabdrucks verdoppeln',
        template_yourOrderHasBeen: 'Ihre Bestellung wurde klimaneutral gestellt',
        template_yourOrderWillBe: 'Ihre Bestellung wird von [brand] klimaneutral gemacht',
        template_addToMake: 'Fügen Sie [price] hinzu, um Ihre Bestellung CO2-neutral zu machen',
        template_addToReduce: 'Fügen Sie [price] hinzu, um Ihren CO2-Fußabdruck zu reduzieren',
        template_addToMatch: 'Fügen Sie [price] entsprechend ihrem Beitrag hinzu und verdoppeln Sie die Wirkung!',
        template_addToDouble: 'Fügen Sie [price] hinzu, um die Wirkung zu verdoppeln',
        template_byCheckingTheBox: 'Indem Sie das Kästchen ankreuzen, schützen Sie [trees] Bäume im [project], die das durch Ihre Bestellung entstehende CO2 absorbieren und es klimaneutral machen!',
        template_byDonating: 'Durch Ihre Spende schützen Sie [trees] Bäume im [project], die das durch Ihre Bestellung entstehende CO2 absorbieren und somit CO2-neutral machen!',
        template_byDonatingMatch: 'Durch Ihre Spende schützen Sie [trees] Bäume im [project], die das durch Ihre Bestellung entstehende CO2 absorbieren. [brand] wird Ihren Beitrag verdoppeln, um die Wirkung zu verdoppeln und noch mehr Bäume zu retten.',
        template_carbonNeutralOrderFor: 'Klimaneutrale Bestellung für [price]',
        template_carbonNeutralShipping: 'Klimaneutraler Versand',
        template_madeCarbonNeutralBy: 'Klimaneutral gemacht von [brand]',
        template_brandIsMaking: '[brand] macht 100% der Bestellungen CO2-neutral',
        template_brandWillMatch: '[brand] wird Ihrem Beitrag entsprechen und die Wirkung verdoppeln!',
        template_brandIsProudMerchantPaying: '[brand] ist stolz darauf, die CO2-Emissionen Ihrer Bestellung durch den Schutz von [trees] Bäumen im [project] auszugleichen, wodurch es klimaneutral wird.',
        template_brandIsProudCustomerMatching: '[brand] ist stolz darauf, die CO2-Emissionen Ihrer Bestellung durch den Schutz von [trees] Bäumen im [project] auszugleichen. Durch Ihre Spende gleichen Sie den Beitrag von [brand] an, verdoppeln die Wirkung und retten noch mehr Bäume!',
        template_brandsContribution: 'Der Beitrag von [brand] fließt direkt in die Finanzierung des [project]',
        template_impact: 'Einfluss',
        template_weHaveOffset: 'Wir haben [total_co2_offset] lbs an Kohlenstoffemissionen ausgeglichen',
        template_wePartneredWithEcocart: 'We partnered with EcoCart to make every single order carbon neutral (aka net zero)! EcoCart will help us eliminate, capture, or mitigate all of the carbon emissions created from manufacturing and shipping our products.',
        template_learnMore: 'Erfahren Sie mehr',
        template_shoppersOffsetTheirCarbonFootprint: '<b>[global_daily_ecocart_order_count] Käufer</b> Haben heute ihren CO2-Fußabdruck ausgeglichen.',
        cta_on: 'An',
        cta_off: 'Aus',
        cta_add: 'Hinzufügen',
        cta_remove: 'Entfernen',
        cta_close: 'Schließen',
        cta_learnMore: 'Erfahre mehr',
        info_milesDriven: 'Km gefahrene',
        info_businessCards: 'Visitenkarten',
        info_arcticIce: 'arktisches Eis gerettet',
        info_lightbulbs: 'Glühbirnen angetrieben',
        info_co2Emissions: 'von CO₂ Emissionen',
        info_lbsOfCo2: 'Kilogramm von CO₂ ausgeglichen',
        info_treesSaved: 'Bäume gerettet',
        info_carbonNeutralOrder: 'klimaneutral bestellen',
        info_carbonNeutralOption: 'Klimaneutrale Option mit',
        info_or: 'oder',
        info_trees: 'Bäume',
        info_poweredBy: 'Unterstützt von',
        info_yourImpact: 'Dein Klimaeinfluss',
        info_joinShoppers: 'Schließe dich mehr als 3 Millionen Käufern an, und es werden mehr',
        info_ourFriendsEstimate: 'Unsere Freunde bei EcoCart haben den CO2-Fußabdruck für den Versand und die Herstellung deiner Bestellung geschätzt: '
    },
    'nl': {
        add_price_for: 'Add [price] for',
        sustainable_shipping: 'Sustainable Shipping',
        shipping_protection: 'Shipping Protection',
        loss_damage_theft_protection: 'Loss, Damage, Theft Protection',
        protect_your_order_and_the_environment: 'Protect Your Order and the Environment',
        protect_your_package_while_offseting_carbon: "Protect your package from loss, theft or damage while offsetting your order's carbon footprint",
        purchasing_this_will_offset_amount_carbon: 'Purchasing This Will Offset [co2_per_item] Of Carbon With <span class="sr-only">EcoCart</span>[ecocart_logo]',
        this_is_like: 'This is like',
        driving_amount_miles_in_an_average_gas_vehicle: '<span class="font-bold">driving [miles_per_item] miles</span> in an average gas vehicle',
        amount_tree_seedlings_grown_for_10_years: '<span class="font-bold">[seedlings_per_item] tree seedlings</span> grown for 10 years',
        template_support_forestry_projects: 'Support Global Forestry Projects',
        template_makeAClimateContribution: 'Make A Climate Contribution',
        template_removeAmtOfPlasticFromOcean: 'Remove <span class="text-primary-400">1 lb</span> of plastic from the ocean',
        template_shopSustainablyByCounteractingPlasticWaste: "Shop Sustainably By Counteracting Your Order's Plastic Waste",
        template_yourContributionWillRemoveAmtPlasticFromOcean: 'Your <span class="text-primary-400">$1.00</span> contribution will remove <span class="text-primary-400">1 lb</span> of plastic from the ocean, counteracting the waste associated with the product and its packaging',
        template_ecocartEnablesUsToPartnerWithOceanPlasticRemovalProjects: 'EcoCart enables us to partner with ocean plastic removal projects in order to help us fight against climate change and counteract the plastic waste associated with each of our orders.',
        template_byCheckingOutWithEcoCartYourOrderWillIncludeAnInvestmentFutureHealth: 'By checking out with EcoCart, your order will include an investment in the future health of the planet by helping clean plastic up from the ocean.',
        template_thisIsEquivalentToAmtPlasticBottles: 'This is equivalent to the amount of plastic found in <span class="text-primary-400">24</span> 16oz water bottles',
        template_accordingToUNEnvironment: 'According to the UN Environment Programme, the average person is responsible for 156 water bottles being discarded as waste every year. So, it would take <span class="text-primary-400">[plastic_to_purchases]</span> purchases to counteract the average consumer\'s annual water bottle waste.',
        template_yourContributionWillHelpFundCleanHub: 'Your contribution will help fund Clean Hub, a certified plastic removal project',
        template_cleanHubIsAVerifiedProject: 'Clean Hub is a verified project dedicated to promoting sustainability and environmental preservation.',
        template_brandHasPartneredWithEcocart: '[brand] has partnered with EcoCart to estimate and offset the carbon footprint of your order by funding verified carbon removal projects around the world.',
        template_yourContributionAtWork: 'Your contribution at work...',
        template_verifiedBy: 'Verified by',
        template_yourOrderWillOffsetAmountCarbon: 'Uw bestelling compenseert [co2] koolstof',
        template_byCheckingOutWithEcocartYourOrderWillHelp: 'Door af te rekenen met EcoCart, zal je bestelling helpen bij het ondersteunen van het [project]',
        template_shopSustainably: 'Shop Sustainably',
        template_ecomindedShoppersLikeYouHaveOffset: 'Way to go! Eco-minded shoppers like you have <span class="text-primary-400">offset [global_all_time_carbon_offset] of Carbon</span> when checking out with EcoCart.',
        template_ofCarbonOffset: 'of Carbon Offset',
        template_goToStore: 'Go To Store',
        template_werePlantingTreesOnOrders: "We're Planting [eco_incentives_trees] Trees On Orders Over [eco_incentives_threshold]",
        template_youreValueAwayFromPlantingTrees: "You're [amount_needed_for_incentives] Away From Planting [eco_incentives_trees] Trees",
        template_hoorayYourOrderWillPlantTreesMerchantWillAlsoOffset: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. [brand] Will Also Offset The Emissions Of Your Order At Checkout!',
        template_hoorayYourOrderWillPlantTreesMaximizeImpact: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. Maximize Your Impact By Offsetting Your Order At Checkout!',
        template_merchantWillOffsetTwiceAsMuchCarbonAsYourOrderEmits: '<span class="text-primary-400">[brand]</span> will offset twice as much carbon as your order emits',
        template_merchantWillCoverTheCostOfOffsettingYourOrder: '<span class="text-primary-400">[brand]</span> will cover the cost of offsetting your order\'s carbon footprint',
        template_selectEcoCartInYourCartOrAtCheckout: 'Select EcoCart in your cart or at checkout to effortlessly contribute towards a greener future by offsetting',
        template_offsetYourOrdersCarbonFootPrintByCheckingOutWithEcocart: "Offset your order's carbon footprint by checking out with EcoCart",
        template_ecocartEstimatesEachOrdersCarbonFootprintLifeTimeUsage: "EcoCart estimates each order's carbon footprint by calculating variables such as shipping method, distance, materials, manufacturing processes, industry averages, lifetime usage, and more.",
        template_merchantIsOffsettingYourOrdersCarbonFootprintMatchTheirContributionDouble: "[brand] is offsetting your order's carbon footprint, <strong>match their contribution to double the impact</strong>",
        template_merchantWillOffsetYourOrdersCarbonFootprint: '<span class="text-primary-400">[brand]</span> will offset your order\'s carbon footprint',
        template_merchantWillDoubleYourOrdersCarbonOffset: '<span class="text-primary-400">[brand]</span> will double your order\'s carbon offset',
        template_merchantWillDoubleTheImpactByMatching: '<strong>[brand] will double the impact</strong> by matching your contribution',
        template_merchantMadeYourOrderClimatePositive: '[brand] Made Your Order Climate Positive',
        template_offsetTwiceAsMuchCarbonAsYourOrderEmits: 'Compenseer twee keer zoveel koolstof als je bestelling uitstoot',
        template_merchantWillMatchQuadrupling: '<span class="text-primary-400">[brand]</span> will match your contribution, quadrupling your order\'s total carbon offset!',
        template_yourContributionTwiceAsMuch: 'Uw <span class="text-primary-400">[price]</span> bijdrage zal <span class="text-primary-400">[co2]</span> koolstof compenseren, twee keer zoveel als de geschatte voetafdruk van uw bestelling, waardoor een positieve blijvende milieueffect wordt gecreëerd.',
        template_ecocartEstimatesEachOrderOffest2x: 'EcoCart schat de koolstofvoetafdruk van elke bestelling door variabelen te berekenen zoals verzendmethode, afstand, materialen, productieprocessen, industriële gemiddelden, levensduurgebruik en meer.<br><br>Door af te rekenen met EcoCart, zal uw bestelling de compensatie van de geschatte koolstofvoetafdruk verdubbelen, wat een positieve blijvende milieueffect heeft.',
        template_yourOrderWillOffset4x: 'Uw bestelling zal 4x de hoeveelheid koolstof compenseren die naar schatting is uitgestoten, waardoor het een positieve blijvende milieueffect heeft.',
        template_merchantWillOffsetTwiceAsMuch: '<span class="text-primary-400">[brand]</span> zal <span class="text-primary-400">[co2]</span> aan koolstof compenseren, twee keer zoveel als de geschatte voetafdruk van je bestelling, wat een positieve blijvende milieueffect heeft',
        template_byContributingYourOrderWillOffset4x: 'Door bij te dragen, zal uw bestelling de compensatie van de geschatte koolstofemissies verviervoudigen, waardoor vier keer de milieueffecten worden bereikt!',
        template_matchTheirContributionToQuadruple: "Match their contribution to quadruple your order's total carbon offset!",
        template_offsetMyOrdersFootprint: "Offset My Order's Carbon Footprint",
        template_ecocartEstimatesEachOrdersCarbonFootprint: 'EcoCart schat de CO2-voetafdruk van elke bestelling door variabelen zoals verzendmethode, afstand, materialen, productieprocessen, branchegegevens en meer te berekenen.',
        template_ecocartPartnersWithCertifiedProjects: 'EcoCart werkt samen met gecertificeerde projecten om de koolstofemissies van de productie en verzending van uw bestelling te compenseren.',
        template_merchantOffsetYourCarbonFootprint: '<span class="text-primary-400">[brand]</span> Offset Your Carbon Footprint',
        template_merchantContributionWillHelpFund: '<span class="text-primary-400">[brand]</span><span class="text-primary-400">\'s</span> contribution will help fund <span class="text-primary-400">[project]</span>, a certified carbon offsetting program.',
        template_yourContributionWillHelpFund: 'Jouw bijdrage zal helpen om <span class="text-primary-400">[project]</span>, een gecertificeerd programma voor koolstofcompensatie, te financieren.',
        template_byContributingYouWillOffsetCarbon: 'Door een bijdrage van <span class="text-primary-400">[price]</span> compenseer je <span class="text-primary-400">[co2]</span> aan koolstof.',
        template_merchantWillOffsetCarbon: '<span class="text-primary-400">[brand]</span> zal <span class="text-primary-400">[co2]</span> aan koolstof compenseren',
        template_yourOrderHasBeenOffsetMaximizeImpact: '<span class="font-semibold">Your order has been offset!</span> Maximize your impact by going climate positive!',
        template_yourContributionWillGoTowards: 'Your contribution will go towards funding the [project]',
        template_withASmallContribution: 'With a small contribution, we can mitigate the emissions calculated from the items in your cart and reduce your carbon footprint',
        template_EcoCartWillCalculateTheFootprint: 'EcoCart will calculate the carbon footprint of your order',
        template_throughCollectiveActionYouAreTakingProactive: 'Through collective action, we can make a real difference in protecting our planet. By offsetting your carbon footprint, you are taking a proactive step towards building a more sustainable future',
        template_merchantHasOffsetYourOrderWith: '[brand] heeft uw bestelling gecompenseerd met',
        template_offsetYourOrderAtCheckoutWith: 'Compenseer uw bestelling bij het afrekenen met',
        template_yourOrderBreakdown: 'Uitsplitsing van uw bestelling',
        template_projectIsAVerifiedProject: '<a href="[project_url]" target="_blank" rel="noopener noreferrer">[project]</a> is een geverifieerd project dat zich inzet voor het bevorderen van duurzaamheid en milieubehoud.',
        template_yourOrderWillOffsetAtLeastCarbon: 'Your order will offset at least [co2] of carbon which is more carbon than your order produces, exceeding net zero standards!',
        template_doubleTheImpact: 'Verdubbel de impact door hun bijdrage te evenaren, waardoor de impact van uw bijdrage wordt verdubbeld.',
        template_oneYearTreeAbsorbCO2Amount: 'Volgens het Amerikaanse Ministerie van Landbouw kan een volwassen boom in één jaar meer dan 48 pond koolstofdioxide uit de atmosfeer opnemen en zuurstof vrijgeven als ruil.',
        template_carbonTreesCanAbsorb: 'Dit komt overeen met de hoeveelheid koolstof die <span class="text-primary-400">[lbs_co2_absorbed_by_one_tree_annually] bomen</span> in één jaar kunnen absorberen',
        template_ecocartProprietaryAlgo: 'Het gepatenteerde algoritme van EcoCart schat in real-time de CO2-voetafdruk van elke bestelling door variabelen zoals verzendmethode, afstand, productiemethoden, materialen en meer te berekenen.',
        template_shopSustainablyByOffsetingCO2Footprint: 'Winkel duurzaam door de CO2-voetafdruk van je bestelling te compenseren',
        template_reduceMyOrderCO2: 'Verminder de koolstofvoetafdruk van mijn bestelling',
        template_makeMyOrder: 'Maak mijn bestelling CO2-neutraal',
        template_makeMyOrderClimatePositive: 'Verdubbel de Klimaatimpact van Mijn Bestelling',
        template_offsetEstimatedCo2e: 'Compenseer de geschatte koolstofemissies van uw bestelling',
        template_merchantMatchYourContribution: '<span class="text-primary-400">[brand]</span> zal uw bijdrage evenaren, waardoor de compensatie van de geschatte CO2-voetafdruk wordt verdubbeld',
        template_yourOrderHasBeen: 'Uw bestelling is klimaatneutraal gemaakt',
        template_yourOrderWillBe: 'Uw bestelling is klimaatneutraal gemaakt',
        template_addToMake: 'Voeg [price] toe om uw bestelling CO2-neutraal te maken',
        template_addToReduce: 'Voeg [price] toe om uw ecologische voetafdruk te verkleinen',
        template_addToMatch: 'Voeg [price] toe om hun bijdrage te matchen en verdubbel de impact!',
        template_addToDouble: 'Voeg [price] toe om de impact te verdubbelen',
        template_byCheckingTheBox: 'Door het vakje aan te vinken, beschermt u [trees] bomen in het [project], die de CO2 absorberen die door uw bestelling wordt gecreëerd, waardoor het CO2-neutraal wordt!',
        template_byDonating: 'Door te doneren, bescherm je [trees] bomen in het [project], die de CO2 absorberen die door je bestelling wordt gecreëerd, waardoor het CO2-neutraal wordt!',
        template_byDonatingMatch: 'Door te doneren, beschermt u [trees] bomen in het [project], die de CO2 absorberen die door uw bestelling wordt gecreëerd. [brand] matcht jouw bijdrage om de impact te verdubbelen en nog meer bomen te redden.',
        template_carbonNeutralOrderFor: 'CO2-neutraal Bestel voor [price]',
        template_carbonNeutralShipping: 'CO2-neutrale verzending',
        template_madeCarbonNeutralBy: 'Gemaakt CO2-neutraal door [brand]',
        template_brandIsMaking: '[brand] maakt 100% van de bestellingen CO2-neutraal',
        template_brandWillMatch: '[brand] matcht jouw bijdrage en verdubbelt de impact!',
        template_brandIsProudMerchantPaying: '[brand] is er trots op de CO2-uitstoot van uw bestelling te compenseren door [trees] bomen in het [project] te beschermen, waardoor het klimaatneutraal wordt.',
        template_brandIsProudCustomerMatching: '[brand] is er trots op de CO2-uitstoot van uw bestelling te compenseren door [trees] bomen in het [project] te beschermen. Door te doneren, verdubbelt u de bijdrage van [brand], verdubbelt u de impact en bespaart u nog meer bomen!',
        template_brandsContribution: 'De bijdrage van [brand] gaat rechtstreeks naar de financiering van het [project]',
        template_impact: 'Invloed',
        template_weHaveOffset: 'We hebben [total_co2_offset] lbs aan koolstofemissies gecompenseerd',
        template_wePartneredWithEcocart: 'We partnered with EcoCart to make every single order carbon neutral (aka net zero)! EcoCart will help us eliminate, capture, or mitigate all of the carbon emissions created from manufacturing and shipping our products.',
        template_learnMore: 'Learn More',
        template_shoppersOffsetTheirCarbonFootprint: '<b>[global_daily_ecocart_order_count] Shoppers</b> Have Offset Their Carbon Footprint Today',
        cta_on: 'Aan',
        cta_off: 'Uit',
        cta_add: 'Toevoegen',
        cta_remove: 'Verwijderen',
        cta_close: 'Sluiten',
        cta_learnMore: 'Meer info',
        info_milesDriven: 'gereden kilometers',
        info_businessCards: 'bedrijfskaarten',
        info_arcticIce: 'poolijs gered',
        info_lightbulbs: 'gloeilampen aangedreven',
        info_co2Emissions: 'van CO₂ uitstoot',
        info_lbsOfCo2: 'kilogram CO₂-uitstoot',
        info_treesSaved: 'bomen gered',
        info_carbonNeutralOrder: 'CO2-neutrale bestelling',
        info_carbonNeutralOption: ' CO2-neutrale optie met',
        info_or: 'of',
        info_trees: 'Bomen',
        info_poweredBy: 'Mogelijk gemaakt door',
        info_yourImpact: 'Jouw klimaat impact',
        info_joinShoppers: 'Vertrouwd door meer dan 3 miljoen consumenten',
        info_ourFriendsEstimate: 'De experts van EcoCart hebben geschat dat de CO₂-voetafdruk van jouw specifieke bestelling gelijk is aan:'
    },
    'nb': {
        add_price_for: 'Add [price] for',
        sustainable_shipping: 'Sustainable Shipping',
        shipping_protection: 'Shipping Protection',
        loss_damage_theft_protection: 'Loss, Damage, Theft Protection',
        protect_your_order_and_the_environment: 'Protect Your Order and the Environment',
        protect_your_package_while_offseting_carbon: "Protect your package from loss, theft or damage while offsetting your order's carbon footprint",
        purchasing_this_will_offset_amount_carbon: 'Purchasing This Will Offset [co2_per_item] Of Carbon With <span class="sr-only">EcoCart</span>[ecocart_logo]',
        this_is_like: 'This is like',
        driving_amount_miles_in_an_average_gas_vehicle: '<span class="font-bold">driving [miles_per_item] miles</span> in an average gas vehicle',
        amount_tree_seedlings_grown_for_10_years: '<span class="font-bold">[seedlings_per_item] tree seedlings</span> grown for 10 years',
        template_support_forestry_projects: 'Support Global Forestry Projects',
        template_makeAClimateContribution: 'Make A Climate Contribution',
        template_removeAmtOfPlasticFromOcean: 'Remove <span class="text-primary-400">1 lb</span> of plastic from the ocean',
        template_shopSustainablyByCounteractingPlasticWaste: "Shop Sustainably By Counteracting Your Order's Plastic Waste",
        template_yourContributionWillRemoveAmtPlasticFromOcean: 'Your <span class="text-primary-400">$1.00</span> contribution will remove <span class="text-primary-400">1 lb</span> of plastic from the ocean, counteracting the waste associated with the product and its packaging',
        template_ecocartEnablesUsToPartnerWithOceanPlasticRemovalProjects: 'EcoCart enables us to partner with ocean plastic removal projects in order to help us fight against climate change and counteract the plastic waste associated with each of our orders.',
        template_byCheckingOutWithEcoCartYourOrderWillIncludeAnInvestmentFutureHealth: 'By checking out with EcoCart, your order will include an investment in the future health of the planet by helping clean plastic up from the ocean.',
        template_thisIsEquivalentToAmtPlasticBottles: 'This is equivalent to the amount of plastic found in <span class="text-primary-400">24</span> 16oz water bottles',
        template_accordingToUNEnvironment: 'According to the UN Environment Programme, the average person is responsible for 156 water bottles being discarded as waste every year. So, it would take <span class="text-primary-400">[plastic_to_purchases]</span> purchases to counteract the average consumer\'s annual water bottle waste.',
        template_yourContributionWillHelpFundCleanHub: 'Your contribution will help fund Clean Hub, a certified plastic removal project',
        template_cleanHubIsAVerifiedProject: 'Clean Hub is a verified project dedicated to promoting sustainability and environmental preservation.',
        template_brandHasPartneredWithEcocart: '[brand] has partnered with EcoCart to estimate and offset the carbon footprint of your order by funding verified carbon removal projects around the world.',
        template_yourContributionAtWork: 'Your contribution at work...',
        template_verifiedBy: 'Verified by',
        template_yourOrderWillOffsetAmountCarbon: 'Bestillingen din vil kompensere [co2] av karbon',
        template_byCheckingOutWithEcocartYourOrderWillHelp: 'Ved å sjekke ut med EcoCart, vil bestillingen din bidra til å støtte [project]',
        template_shopSustainably: 'Shop Sustainably',
        template_ecomindedShoppersLikeYouHaveOffset: 'Way to go! Eco-minded shoppers like you have <span class="text-primary-400">offset [global_all_time_carbon_offset] of Carbon</span> when checking out with EcoCart.',
        template_ofCarbonOffset: 'of Carbon Offset',
        template_goToStore: 'Go To Store',
        template_werePlantingTreesOnOrders: "We're Planting [eco_incentives_trees] Trees On Orders Over [eco_incentives_threshold]",
        template_youreValueAwayFromPlantingTrees: "You're [amount_needed_for_incentives] Away From Planting [eco_incentives_trees] Trees",
        template_hoorayYourOrderWillPlantTreesMerchantWillAlsoOffset: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. [brand] Will Also Offset The Emissions Of Your Order At Checkout!',
        template_hoorayYourOrderWillPlantTreesMaximizeImpact: 'Hooray! Your Order Will Plant [eco_incentives_trees] Trees. Maximize Your Impact By Offsetting Your Order At Checkout!',
        template_merchantWillOffsetTwiceAsMuchCarbonAsYourOrderEmits: '<span class="text-primary-400">[brand]</span> will offset twice as much carbon as your order emits',
        template_merchantWillCoverTheCostOfOffsettingYourOrder: '<span class="text-primary-400">[brand]</span> will cover the cost of offsetting your order\'s carbon footprint',
        template_selectEcoCartInYourCartOrAtCheckout: 'Select EcoCart in your cart or at checkout to effortlessly contribute towards a greener future by offsetting',
        template_offsetYourOrdersCarbonFootPrintByCheckingOutWithEcocart: "Offset your order's carbon footprint by checking out with EcoCart",
        template_ecocartEstimatesEachOrdersCarbonFootprintLifeTimeUsage: "EcoCart estimates each order's carbon footprint by calculating variables such as shipping method, distance, materials, manufacturing processes, industry averages, lifetime usage, and more.",
        template_merchantIsOffsettingYourOrdersCarbonFootprintMatchTheirContributionDouble: "[brand] is offsetting your order's carbon footprint, <strong>match their contribution to double the impact</strong>",
        template_merchantWillOffsetYourOrdersCarbonFootprint: '<span class="text-primary-400">[brand]</span> will offset your order\'s carbon footprint',
        template_merchantWillDoubleYourOrdersCarbonOffset: '<span class="text-primary-400">[brand]</span> will double your order\'s carbon offset',
        template_merchantWillDoubleTheImpactByMatching: '<strong>[brand] will double the impact</strong> by matching your contribution',
        template_merchantMadeYourOrderClimatePositive: '[brand] Made Your Order Climate Positive',
        template_offsetTwiceAsMuchCarbonAsYourOrderEmits: 'Kompenser dobbelt så mye karbon som din bestilling slipper ut',
        template_merchantWillMatchQuadrupling: '<span class="text-primary-400">[brand]</span> will match your contribution, quadrupling your order\'s total carbon offset!',
        template_yourContributionTwiceAsMuch: 'Din <span class="text-primary-400">[price]</span> bidrag vil kompensere <span class="text-primary-400">[co2]</span> av karbon, dobbelt så mye som din bestillings estimerte fotavtrykk, og skape en positiv varig miljøpåvirkning',
        template_ecocartEstimatesEachOrderOffest2x: 'EcoCart estimerer hvert ordres karbonavtrykk ved å beregne variabler som fraktmetode, avstand, materialer, produksjonsprosesser, bransjegjennomsnitt, levetidsbruk og mer.<br><br>Ved å sjekke ut med EcoCart, vil din ordre doble kompensasjonen for det estimerte karbonavtrykket, og skape en positiv varig miljøpåvirkning.',
        template_yourOrderWillOffset4x: 'Bestillingen din vil kompensere 4x mengden karbon det ble estimert å ha utslippet, noe som gjør det til en positiv varig miljøpåvirkning',
        template_merchantWillOffsetTwiceAsMuch: '<span class="text-primary-400">[brand]</span> vil kompensere <span class="text-primary-400">[co2]</span> av karbon, dobbelt så mye som din bestillings estimerte fotavtrykk, og skape en positiv varig miljøpåvirkning',
        template_byContributingYourOrderWillOffset4x: 'Ved å bidra, vil bestillingen din firedoble kompensasjonen for de estimerte karbonutslippene, og oppnå fire ganger så stor miljøpåvirkning!',
        template_matchTheirContributionToQuadruple: "Match their contribution to quadruple your order's total carbon offset!",
        template_offsetMyOrdersFootprint: "Offset My Order's Carbon Footprint",
        template_ecocartEstimatesEachOrdersCarbonFootprint: 'EcoCart estimerer klimaavtrykket til hver bestilling ved å beregne variabler som fraktmetode, avstand, materialer, produksjonsprosesser, bransjesnitt og mer.',
        template_ecocartPartnersWithCertifiedProjects: 'EcoCart samarbeider med sertifiserte prosjekter for å kompensere for klimagassutslippene fra produksjonen og forsendelsen av bestillingen din.',
        template_merchantOffsetYourCarbonFootprint: '<span class="text-primary-400">[brand]</span> Offset Your Carbon Footprint',
        template_merchantContributionWillHelpFund: '<span class="text-primary-400">[brand]</span><span class="text-primary-400">\'s</span> contribution will help fund <span class="text-primary-400">[project]</span>, a certified carbon offsetting program.',
        template_yourContributionWillHelpFund: 'Ditt bidrag vil bidra til å finansiere <span class="text-primary-400">[project]</span>, et sertifisert karbonkompensasjonsprogram.',
        template_byContributingYouWillOffsetCarbon: 'Ved å bidra med <span class="text-primary-400">[price]</span> vil du kompensere <span class="text-primary-400">[co2]</span> karbon.',
        template_merchantWillOffsetCarbon: '<span class="text-primary-400">[brand]</span> vil kompensere <span class="text-primary-400">[co2]</span> av karbon',
        template_yourOrderHasBeenOffsetMaximizeImpact: '<span class="font-semibold">Your order has been offset!</span> Maximize your impact by going climate positive!',
        template_yourContributionWillGoTowards: 'Your contribution will go towards funding the [project]',
        template_withASmallContribution: 'With a small contribution, we can mitigate the emissions calculated from the items in your cart and reduce your carbon footprint',
        template_EcoCartWillCalculateTheFootprint: 'EcoCart will calculate the carbon footprint of your order',
        template_throughCollectiveActionYouAreTakingProactive: 'Through collective action, we can make a real difference in protecting our planet. By offsetting your carbon footprint, you are taking a proactive step towards building a more sustainable future',
        template_merchantHasOffsetYourOrderWith: '[brand] har kompensert din ordre med',
        template_offsetYourOrderAtCheckoutWith: 'Kompenser bestillingen din ved utsjekking med',
        template_yourOrderBreakdown: 'Oversikt over bestillingen din',
        template_projectIsAVerifiedProject: '<a href="[project_url]" target="_blank" rel="noopener noreferrer">[project]</a> er et verifisert prosjekt dedikert til å fremme bærekraft og miljøbevaring.',
        template_yourOrderWillOffsetAtLeastCarbon: 'Your order will offset at least [co2] of carbon which is more carbon than your order produces, exceeding net zero standards!',
        template_doubleTheImpact: 'Dobbel effekten ved å matche deres bidrag og doble effekten av ditt bidrag.',
        template_oneYearTreeAbsorbCO2Amount: 'Ifølge USAs landbruksdepartement kan et modent tre i løpet av ett år absorbere mer enn 48 pund karbondioksid fra atmosfæren og frigjøre oksygen som kompensasjon.',
        template_carbonTreesCanAbsorb: 'Dette tilsvarer mengden karbon <span class="text-primary-400">[lbs_co2_absorbed_by_one_tree_annually] trær</span> kan absorbere på ett år',
        template_ecocartProprietaryAlgo: 'EcoCarts proprietære algoritme estimerer hvert ordres karbonavtrykk i sanntid ved å beregne variabler som fraktmetode, avstand, produksjonsmetoder, materialer og mer.',
        template_shopSustainablyByOffsetingCO2Footprint: 'Handle bærekraftig ved å kompensere for klimaavtrykket til bestillingen din',
        template_reduceMyOrderCO2: 'Reduser klimaavtrykket til bestillingen min',
        template_makeMyOrder: 'Make my order carbon neutral',
        template_makeMyOrderClimatePositive: 'Dobbel klimaeffekten av min bestilling',
        template_offsetEstimatedCo2e: 'Kompenser for de estimerte karbonutslippene fra bestillingen din',
        template_merchantMatchYourContribution: '<span class="text-primary-400">[brand]</span> vil matche ditt bidrag, og doble kompensasjonen for dets estimerte karbonavtrykk',
        template_yourOrderHasBeen: 'Your order has been made carbon neutral',
        template_yourOrderWillBe: 'Your order will be made carbon neutral by [brand]',
        template_addToMake: 'Add [price] to make your order carbon neutral',
        template_addToReduce: 'Add [price] to reduce your carbon footprint',
        template_addToMatch: 'Add [price] to match their contribution and double the impact!',
        template_addToDouble: 'Add [price] to double the impact',
        template_byCheckingTheBox: "By checking the box, you'll be protecting [trees] trees in the [project], which are absorbing the CO2 created by your order, making it carbon neutral!",
        template_byDonating: "By donating, you'll be protecting [trees] trees in the [project], which are absorbing the CO2 created by your order, making it carbon neutral!",
        template_byDonatingMatch: "By donating, you'll be protecting [trees] trees in the [project], which are absorbing the CO2 created by your order. [brand] will match your contribution to double the impact and save even more trees.",
        template_carbonNeutralOrderFor: 'Carbon Neutral Order for [price]',
        template_carbonNeutralShipping: 'Carbon Neutral Shipping',
        template_madeCarbonNeutralBy: 'Made Carbon Neutral by [brand]',
        template_brandIsMaking: '[brand] is Making 100% of Orders Carbon Neutral',
        template_brandWillMatch: "[brand] will match your contribution, doubling your order's impact",
        template_brandIsProudMerchantPaying: '[brand] is proud to offset the carbon emissions from your order by protecting [trees] trees in the [project], making it carbon neutral.',
        template_brandIsProudCustomerMatching: "[brand] is proud to offset the carbon emissions from your order by protecting [trees] trees in the [project]. By donating, you'll be matching [brand]'s contribution, doubling the impact and saving even more trees!",
        template_brandsContribution: "[brand]'s contribution will go directly to help fund the [project]",
        template_impact: 'Impact',
        template_weHaveOffset: 'Vi har utlignet [total_co2_offset] lbs med karbonutslipp',
        template_wePartneredWithEcocart: 'We partnered with EcoCart to make every single order carbon neutral (aka net zero)! EcoCart will help us eliminate, capture, or mitigate all of the carbon emissions created from manufacturing and shipping our products.',
        template_learnMore: 'Learn More',
        template_shoppersOffsetTheirCarbonFootprint: '<b>[global_daily_ecocart_order_count] Shoppers</b> Have Offset Their Carbon Footprint Today',
        cta_on: 'On',
        cta_off: 'Off',
        cta_add: 'Add',
        cta_remove: 'Remove',
        cta_close: 'Close',
        cta_learnMore: 'Learn More',
        info_milesDriven: 'km driven',
        info_businessCards: 'business cards',
        info_arcticIce: 'arctic ice saved',
        info_lightbulbs: 'lightbulbs powered',
        info_co2Emissions: 'of CO₂ emissions',
        info_lbsOfCo2: 'kg of CO₂ offset',
        info_treesSaved: 'trees saved',
        info_carbonNeutralOrder: 'Carbon Neutral Order',
        info_carbonNeutralOption: 'Carbon neutral option with',
        info_or: 'or',
        info_trees: 'trees',
        info_poweredBy: 'Powered by',
        info_yourImpact: 'Your Climate Impact',
        info_joinShoppers: 'Join over 3 million shoppers and counting',
        info_ourFriendsEstimate: 'Our friends at EcoCart have estimated the carbon footprint of shipping and manufacturing your specific order to be:'
    },
    'zh-CN': {
        add_price_for: 'Add [price] for',
        sustainable_shipping: 'Sustainable Shipping',
        shipping_protection: 'Shipping Protection',
        loss_damage_theft_protection: 'Loss, Damage, Theft Protection',
        protect_your_order_and_the_environment: 'Protect Your Order and the Environment',
        protect_your_package_while_offseting_carbon: "Protect your package from loss, theft or damage while offsetting your order's carbon footprint",
        purchasing_this_will_offset_amount_carbon: 'Purchasing This Will Offset [co2_per_item] Of Carbon With <span class="sr-only">EcoCart</span>[ecocart_logo]',
        this_is_like: 'This is like',
        driving_amount_miles_in_an_average_gas_vehicle: '<span class="font-bold">driving [miles_per_item] miles</span> in an average gas vehicle',
        amount_tree_seedlings_grown_for_10_years: '<span class="font-bold">[seedlings_per_item] tree seedlings</span> grown for 10 years',
        template_support_forestry_projects: 'Support Global Forestry Projects',
        template_makeAClimateContribution: 'Make A Climate Contribution',
        template_removeAmtOfPlasticFromOcean: 'Remove <span class="text-primary-400">1 lb</span> of plastic from the ocean',
        template_shopSustainablyByCounteractingPlasticWaste: "Shop Sustainably By Counteracting Your Order's Plastic Waste",
        template_yourContributionWillRemoveAmtPlasticFromOcean: 'Your <span class="text-primary-400">$1.00</span> contribution will remove <span class="text-primary-400">1 lb</span> of plastic from the ocean, counteracting the waste associated with the product and its packaging',
        template_ecocartEnablesUsToPartnerWithOceanPlasticRemovalProjects: 'EcoCart enables us to partner with ocean plastic removal projects in order to help us fight against climate change and counteract the plastic waste associated with each of our orders.',
        template_byCheckingOutWithEcoCartYourOrderWillIncludeAnInvestmentFutureHealth: 'By checking out with EcoCart, your order will include an investment in the future health of the planet by helping clean plastic up from the ocean.',
        template_thisIsEquivalentToAmtPlasticBottles: 'This is equivalent to the amount of plastic found in <span class="text-primary-400">24</span> 16oz water bottles',
        template_accordingToUNEnvironment: 'According to the UN Environment Programme, the average person is responsible for 156 water bottles being discarded as waste every year. So, it would take <span class="text-primary-400">[plastic_to_purchases]</span> purchases to counteract the average consumer\'s annual water bottle waste.',
        template_yourContributionWillHelpFundCleanHub: 'Your contribution will help fund Clean Hub, a certified plastic removal project',
        template_cleanHubIsAVerifiedProject: 'Clean Hub is a verified project dedicated to promoting sustainability and environmental preservation.',
        template_brandHasPartneredWithEcocart: '[brand] has partnered with EcoCart to estimate and offset the carbon footprint of your order by funding verified carbon removal projects around the world.',
        template_yourContributionAtWork: 'Your contribution at work...',
        template_verifiedBy: 'Verified by',
        template_yourOrderWillOffsetAmountCarbon: '您的订单将抵消[co2]的碳排放',
        template_byCheckingOutWithEcocartYourOrderWillHelp: '通过使用EcoCart结账，您的订单将帮助支持[project]',
        template_shopSustainably: '可持续购物',
        template_ecomindedShoppersLikeYouHaveOffset: '干得好！像您这样的环保购物者在使用EcoCart结账时已经抵消了[global_all_time_carbon_offset]的碳排放。',
        template_ofCarbonOffset: '的碳抵消',
        template_goToStore: '前往商店',
        template_werePlantingTreesOnOrders: '我们将在订单金额超过[eco_incentives_threshold]时种植[eco_incentives_trees]棵树',
        template_youreValueAwayFromPlantingTrees: '您距离种植[eco_incentives_trees]棵树还有[amount_needed_for_incentives]',
        template_hoorayYourOrderWillPlantTreesMerchantWillAlsoOffset: '太棒了！您的订单将种植[eco_incentives_trees]棵树。[brand]还将在结账时抵消您的订单的排放！',
        template_hoorayYourOrderWillPlantTreesMaximizeImpact: '太棒了！您的订单将种植[eco_incentives_trees]棵树。通过在结账时抵消您的订单，最大化您的影响力！',
        template_merchantWillOffsetTwiceAsMuchCarbonAsYourOrderEmits: '<span class="text-primary-400">[brand]</span> 将抵消您的订单排放的两倍碳排放量',
        template_merchantWillCoverTheCostOfOffsettingYourOrder: '<span class="text-primary-400">[brand]</span> 将支付您订单的碳足迹抵消费用',
        template_selectEcoCartInYourCartOrAtCheckout: '在购物车或结账时选择EcoCart，通过抵消轻松为更绿色的未来做出贡献',
        template_offsetYourOrdersCarbonFootPrintByCheckingOutWithEcocart: '通过使用EcoCart结账来抵消您订单的碳足迹',
        template_ecocartEstimatesEachOrdersCarbonFootprintLifeTimeUsage: 'EcoCart通过计算运输方式、距离、材料、制造工艺、行业平均值、使用寿命等变量来估算每个订单的碳足迹。',
        template_merchantIsOffsettingYourOrdersCarbonFootprintMatchTheirContributionDouble: '[brand]正在抵消您订单的碳足迹，<strong>与他们的贡献相匹配，使影响加倍</strong>',
        template_merchantWillOffsetYourOrdersCarbonFootprint: '<span class="text-primary-400">[brand]</span> 将抵消您的订单的碳足迹',
        template_merchantWillDoubleYourOrdersCarbonOffset: '<span class="text-primary-400">[brand]</span> 将使您的订单的碳抵消翻倍',
        template_merchantWillDoubleTheImpactByMatching: '<strong>[brand] 将通过与您的捐款相匹配来加倍影响力</strong>',
        template_merchantMadeYourOrderClimatePositive: '[brand] 让您的订单对气候产生积极影响',
        template_offsetTwiceAsMuchCarbonAsYourOrderEmits: '抵消您订单排放的两倍碳排放量',
        template_merchantWillMatchQuadrupling: '<span class="text-primary-400">[brand]</span> 将与您的捐款相匹配，将您的订单总碳抵消量增加四倍！',
        template_yourContributionTwiceAsMuch: '您的 <span class="text-primary-400">[price]</span> 贡献将抵消 <span class="text-primary-400">[co2]</span> 的碳排放量，是您订单估计碳足迹的两倍，从而产生积极持久的环境影响。',
        template_ecocartEstimatesEachOrderOffest2x: 'EcoCart 通过计算运输方式、距离、材料、制造过程、行业平均值、使用寿命等变量来估算每个订单的碳足迹。<br><br>通过使用 EcoCart 结账，您的订单将使其估算的碳足迹抵消量翻倍，从而对环境产生积极而持久的影响。',
        template_yourOrderWillOffset4x: '您的订单将抵消其估计排放量的4倍碳排放，从而产生积极的持久环境影响',
        template_merchantWillOffsetTwiceAsMuch: '<span class="text-primary-400">[brand]</span> 将抵消 <span class="text-primary-400">[co2]</span> 的碳排放量，是您订单估计碳足迹的两倍，从而产生积极持久的环境影响',
        template_byContributingYourOrderWillOffset4x: '通过贡献，您的订单将使其估算的碳排放量抵消增加四倍，实现四倍的环境影响！',
        template_matchTheirContributionToQuadruple: '匹配他们的捐款，使您的订单总碳抵消量增加四倍！',
        template_offsetMyOrdersFootprint: '抵消我的订单的碳足迹',
        template_ecocartEstimatesEachOrdersCarbonFootprint: 'EcoCart 通过计算运输方式、距离、材料、制造工艺、行业平均值等变量来估算每个订单的碳足迹。',
        template_ecocartPartnersWithCertifiedProjects: 'EcoCart 与认证项目合作，抵消制造和运送您的订单产生的碳排放。',
        template_merchantOffsetYourCarbonFootprint: '<span class="text-primary-400">[brand]</span> 抵消您的碳足迹',
        template_merchantContributionWillHelpFund: '<span class="text-primary-400">[brand]</span><span class="text-primary-400">\'s</span> 贡献将有助于资助<span class="text-primary-400">[project]</span>，一个经过认证的碳抵消项目。',
        template_yourContributionWillHelpFund: '您的捐款将有助于资助<span class="text-primary-400">[project]</span>，一个经过认证的碳抵消计划。',
        template_byContributingYouWillOffsetCarbon: '通过贡献<span class="text-primary-400">[price]</span>，您将抵消<span class="text-primary-400">[co2]</span>的碳排放',
        template_merchantWillOffsetCarbon: '<span class="text-primary-400">[brand]</span> 将抵消 <span class="text-primary-400">[co2]</span> 的碳排放',
        template_yourOrderHasBeenOffsetMaximizeImpact: '<span class="font-semibold">您的订单已抵消！</span> 通过实现气候积极，最大化您的影响力！',
        template_yourContributionWillGoTowards: '您的捐款将用于资助[project]',
        template_withASmallContribution: '通过小额捐款，我们可以减少购物车中物品计算出的排放量，减少您的碳足迹',
        template_EcoCartWillCalculateTheFootprint: 'EcoCart 将计算您订单的碳足迹',
        template_throughCollectiveActionYouAreTakingProactive: '通过集体行动，我们可以在保护地球方面产生真正的影响。通过抵消您的碳足迹，您正在积极地迈出建设更加可持续未来的一步。<span class="text-green-500"></span>',
        template_merchantHasOffsetYourOrderWith: '[brand] 已经抵消了您的订单',
        template_offsetYourOrderAtCheckoutWith: '在结账时抵消您的订单',
        template_yourOrderBreakdown: '您的订单明细',
        template_projectIsAVerifiedProject: '<a href="[project_url]" target="_blank" rel="noopener noreferrer">[project]</a> 是一个致力于推动可持续发展和环境保护的经过验证的项目。',
        template_yourOrderWillOffsetAtLeastCarbon: '您的订单将抵消至少[co2]的碳排放量，这比您的订单产生的碳排放量更多，超过了净零标准！',
        template_doubleTheImpact: '通过匹配他们的贡献来加倍影响，从而加倍您的贡献的影响。',
        template_oneYearTreeAbsorbCO2Amount: '根据美国农业部的数据，一棵成熟的树在一年内可以吸收超过48磅的二氧化碳，并释放氧气作为交换。',
        template_carbonTreesCanAbsorb: '这相当于一年内<span class="text-primary-400">[lbs_co2_absorbed_by_one_tree_annually]</span>棵树木可以吸收的碳量',
        template_ecocartProprietaryAlgo: 'EcoCart的专有算法通过计算运输方式、距离、制造方法、材料等变量，实时估算每个订单的碳足迹。',
        template_shopSustainablyByOffsetingCO2Footprint: '通过抵消您订单的碳足迹来可持续购物',
        template_reduceMyOrderCO2: '减少我的订单的碳足迹',
        template_makeMyOrder: '使我的订单碳中和',
        template_makeMyOrderClimatePositive: '将我的订单的气候影响加倍',
        template_offsetEstimatedCo2e: '抵消您订单的估计碳排放量',
        template_merchantMatchYourContribution: '<span class="text-primary-400">[brand]</span> 将匹配您的贡献，双倍抵消其估算的碳足迹',
        template_yourOrderHasBeen: '您的订单已经实现碳中和',
        template_yourOrderWillBe: '您的订单将由[brand]进行碳中和',
        template_addToMake: '添加 [price] 以使您的订单达到碳中和',
        template_addToReduce: '添加 [price] 以减少您的碳足迹',
        template_addToMatch: '添加 [price] 以匹配他们的贡献并加倍影响力！',
        template_addToDouble: '添加 [price] 以加倍影响',
        template_byCheckingTheBox: '勾选此框，您将保护[project]中的[trees]棵树木，这些树木正在吸收您的订单产生的CO2，使其达到碳中和！',
        template_byDonating: '通过捐赠，您将保护[project]中的[trees]棵树木，它们正在吸收您的订单产生的CO2，使其达到碳中和！',
        template_byDonatingMatch: '通过捐赠，您将保护<span class="text-green-500">[trees]</span>在<span class="text-green-500">[project]</span>中的树木，这些树木正在吸收您订单产生的二氧化碳。<span class="text-green-500">[brand]</span>将匹配您的捐款，使影响加倍，拯救更多的树木。',
        template_carbonNeutralOrderFor: '碳中和订单 [price]',
        template_carbonNeutralShipping: '碳中和运输',
        template_madeCarbonNeutralBy: '由[brand]实现碳中和',
        template_brandIsMaking: '[brand] 正在使100%的订单达到碳中和',
        template_brandWillMatch: '[brand] 将匹配您的捐款，使您的订单的影响力翻倍',
        template_brandIsProudMerchantPaying: '[brand] 为您的订单抵消碳排放，通过保护[project]中的[trees]棵树木，使其达到碳中和。',
        template_brandIsProudCustomerMatching: '[brand] 自豪地通过保护 [trees] 棵树木在 [project] 中抵消您订单的碳排放。通过捐赠，您将与 [brand] 的贡献相匹配，使影响翻倍，拯救更多的树木！',
        template_brandsContribution: '[brand]的捐款将直接用于资助[project]',
        template_impact: '影响',
        template_weHaveOffset: '我们已经抵消了[total_co2_offset]的CO2排放',
        template_wePartneredWithEcocart: '我们与EcoCart合作，使每个订单都达到碳中和（也称为净零）！EcoCart将帮助我们消除、捕获或减轻制造和运输产品所产生的所有碳排放。',
        template_learnMore: '了解更多',
        template_shoppersOffsetTheirCarbonFootprint: '<b>[global_daily_ecocart_order_count] 购物者</b> 今天抵消了他们的碳足迹',
        cta_on: '开',
        cta_off: '关',
        cta_add: '添加',
        cta_remove: '删除',
        cta_close: '关闭',
        cta_learnMore: '了解更多',
        info_milesDriven: '行驶里程',
        info_businessCards: '名片',
        info_arcticIce: '北极冰保存',
        info_lightbulbs: '灯泡供电',
        info_co2Emissions: '的CO₂排放',
        info_lbsOfCo2: 'CO₂抵消磅',
        info_treesSaved: '节约的树木',
        info_carbonNeutralOrder: '碳中和订单',
        info_carbonNeutralOption: '具有碳中和选项的',
        info_or: '或',
        info_trees: '树木',
        info_poweredBy: '由',
        info_yourImpact: '您的气候影响',
        info_joinShoppers: '加入超过300万的购物者并持续增长',
        info_ourFriendsEstimate: '我们的朋友EcoCart估计了您的特定订单的运输和制造碳足迹为： '
    },
    'zh-TW': {
        add_price_for: 'Add [price] for',
        sustainable_shipping: 'Sustainable Shipping',
        shipping_protection: 'Shipping Protection',
        loss_damage_theft_protection: 'Loss, Damage, Theft Protection',
        protect_your_order_and_the_environment: 'Protect Your Order and the Environment',
        protect_your_package_while_offseting_carbon: "Protect your package from loss, theft or damage while offsetting your order's carbon footprint",
        purchasing_this_will_offset_amount_carbon: 'Purchasing This Will Offset [co2_per_item] Of Carbon With <span class="sr-only">EcoCart</span>[ecocart_logo]',
        this_is_like: 'This is like',
        driving_amount_miles_in_an_average_gas_vehicle: '<span class="font-bold">driving [miles_per_item] miles</span> in an average gas vehicle',
        amount_tree_seedlings_grown_for_10_years: '<span class="font-bold">[seedlings_per_item] tree seedlings</span> grown for 10 years',
        template_support_forestry_projects: 'Support Global Forestry Projects',
        template_makeAClimateContribution: 'Make A Climate Contribution',
        template_removeAmtOfPlasticFromOcean: 'Remove <span class="text-primary-400">1 lb</span> of plastic from the ocean',
        template_shopSustainablyByCounteractingPlasticWaste: "Shop Sustainably By Counteracting Your Order's Plastic Waste",
        template_yourContributionWillRemoveAmtPlasticFromOcean: 'Your <span class="text-primary-400">$1.00</span> contribution will remove <span class="text-primary-400">1 lb</span> of plastic from the ocean, counteracting the waste associated with the product and its packaging',
        template_ecocartEnablesUsToPartnerWithOceanPlasticRemovalProjects: 'EcoCart enables us to partner with ocean plastic removal projects in order to help us fight against climate change and counteract the plastic waste associated with each of our orders.',
        template_byCheckingOutWithEcoCartYourOrderWillIncludeAnInvestmentFutureHealth: 'By checking out with EcoCart, your order will include an investment in the future health of the planet by helping clean plastic up from the ocean.',
        template_thisIsEquivalentToAmtPlasticBottles: 'This is equivalent to the amount of plastic found in <span class="text-primary-400">24</span> 16oz water bottles',
        template_accordingToUNEnvironment: 'According to the UN Environment Programme, the average person is responsible for 156 water bottles being discarded as waste every year. So, it would take <span class="text-primary-400">[plastic_to_purchases]</span> purchases to counteract the average consumer\'s annual water bottle waste.',
        template_yourContributionWillHelpFundCleanHub: 'Your contribution will help fund Clean Hub, a certified plastic removal project',
        template_cleanHubIsAVerifiedProject: 'Clean Hub is a verified project dedicated to promoting sustainability and environmental preservation.',
        template_brandHasPartneredWithEcocart: '[brand] has partnered with EcoCart to estimate and offset the carbon footprint of your order by funding verified carbon removal projects around the world.',
        template_yourContributionAtWork: 'Your contribution at work...',
        template_verifiedBy: 'Verified by',
        template_yourOrderWillOffsetAmountCarbon: '您的訂單將抵消[co2]的碳排放',
        template_byCheckingOutWithEcocartYourOrderWillHelp: '透過使用EcoCart結帳，您的訂單將有助於支持[project]',
        template_shopSustainably: '永續購物',
        template_ecomindedShoppersLikeYouHaveOffset: '幹得好！像您這樣環保的購物者在使用EcoCart結帳時已經抵消了[global_all_time_carbon_offset]的碳排放。',
        template_ofCarbonOffset: '的碳抵銷',
        template_goToStore: '前往商店',
        template_werePlantingTreesOnOrders: '我們將在訂單金額超過[eco_incentives_threshold]時種植[eco_incentives_trees]棵樹',
        template_youreValueAwayFromPlantingTrees: '您距離種植[eco_incentives_trees]棵樹還有[amount_needed_for_incentives]',
        template_hoorayYourOrderWillPlantTreesMerchantWillAlsoOffset: '太棒了！您的訂單將種植[eco_incentives_trees]棵樹。[brand]還將在結帳時抵消您的訂單的排放！',
        template_hoorayYourOrderWillPlantTreesMaximizeImpact: '太棒了！您的訂單將種植[eco_incentives_trees]棵樹。通過在結帳時抵消您的訂單，最大化您的影響力！',
        template_merchantWillOffsetTwiceAsMuchCarbonAsYourOrderEmits: '<span class="text-primary-400">[brand]</span> 將抵消您的訂單排放的兩倍碳排放量',
        template_merchantWillCoverTheCostOfOffsettingYourOrder: '<span class="text-primary-400">[brand]</span> 將支付您訂單的碳足跡抵銷費用',
        template_selectEcoCartInYourCartOrAtCheckout: '在購物車或結帳時選擇EcoCart，輕鬆抵銷以為更綠色的未來做出貢獻',
        template_offsetYourOrdersCarbonFootPrintByCheckingOutWithEcocart: '透過使用EcoCart結帳來抵消您訂單的碳足跡',
        template_ecocartEstimatesEachOrdersCarbonFootprintLifeTimeUsage: 'EcoCart通過計算運輸方式、距離、材料、製造工藝、行業平均值、使用壽命等變量來估算每個訂單的碳足跡。',
        template_merchantIsOffsettingYourOrdersCarbonFootprintMatchTheirContributionDouble: '[brand]正在抵消您訂單的碳足跡，<strong>與他們的貢獻相匹配，使影響加倍</strong>',
        template_merchantWillOffsetYourOrdersCarbonFootprint: '<span class="text-primary-400">[brand]</span> 將抵消您的訂單的碳足跡',
        template_merchantWillDoubleYourOrdersCarbonOffset: '<span class="text-primary-400">[brand]</span> 將使您的訂單的碳抵消翻倍',
        template_merchantWillDoubleTheImpactByMatching: '<strong>[brand] 將透過與您的捐款相匹配來加倍影響力</strong>',
        template_merchantMadeYourOrderClimatePositive: '[brand] 讓您的訂單對氣候產生積極影響',
        template_offsetTwiceAsMuchCarbonAsYourOrderEmits: '抵消您訂單排放的兩倍碳排放量',
        template_merchantWillMatchQuadrupling: '<span class="text-primary-400">[brand]</span> 將與您的捐款相匹配，將您的訂單總碳抵消量增加四倍！',
        template_yourContributionTwiceAsMuch: '您的 <span class="text-primary-400">[price]</span> 貢獻將抵消 <span class="text-primary-400">[co2]</span> 的碳排放量，是您訂單估計碳足跡的兩倍，從而產生積極且持久的環境影響。',
        template_ecocartEstimatesEachOrderOffest2x: 'EcoCart 通過計算運輸方式、距離、材料、製造過程、行業平均值、使用壽命等變量來估算每個訂單的碳足跡。<br><br>通過使用 EcoCart 結帳，您的訂單將抵消其估算碳足跡的兩倍，從而對環境產生積極而持久的影響。',
        template_yourOrderWillOffset4x: '您的訂單將抵消估計排放量的4倍碳排放，從而產生積極且持久的環境影響',
        template_merchantWillOffsetTwiceAsMuch: '<span class="text-primary-400">[brand]</span> 將抵消 <span class="text-primary-400">[co2]</span> 的碳排放量，是您訂單估計碳足跡的兩倍，從而產生積極且持久的環境影響',
        template_byContributingYourOrderWillOffset4x: '通過貢獻，您的訂單將使其預估碳排放量的抵消增加四倍，實現四倍的環境影響！',
        template_matchTheirContributionToQuadruple: '匹配他們的捐款，使您的訂單總碳抵消量增加四倍！',
        template_offsetMyOrdersFootprint: '抵銷我的訂單的碳足跡',
        template_ecocartEstimatesEachOrdersCarbonFootprint: 'EcoCart 透過計算變數，如運送方式、距離、材料、製造過程、行業平均值等，來估算每個訂單的碳足跡。',
        template_ecocartPartnersWithCertifiedProjects: 'EcoCart 與認證專案合作，抵銷製造和運送您的訂單所產生的碳排放。',
        template_merchantOffsetYourCarbonFootprint: '<span class="text-primary-400">[brand]</span> 抵銷您的碳足跡',
        template_merchantContributionWillHelpFund: '<span class="text-primary-400">[brand]</span><span class="text-primary-400">\'s</span> 貢獻將有助於資助<span class="text-primary-400">[project]</span>，一個經過認證的碳抵消計劃。',
        template_yourContributionWillHelpFund: '您的捐款將有助於資助<span class="text-primary-400">[project]</span>，一個經過認證的碳抵消計劃。',
        template_byContributingYouWillOffsetCarbon: '透過貢獻<span class="text-primary-400">[price]</span>，您將抵消<span class="text-primary-400">[co2]</span>的碳排放',
        template_merchantWillOffsetCarbon: '<span class="text-primary-400">[brand]</span> 將抵消 <span class="text-primary-400">[co2]</span> 的碳排放',
        template_yourOrderHasBeenOffsetMaximizeImpact: '<span class="font-semibold">您的訂單已抵消！</span> 透過實現氣候正向，最大化您的影響力！',
        template_yourContributionWillGoTowards: '您的捐款將用於資助[project]',
        template_withASmallContribution: '透過小額捐款，我們可以減少您購物車中物品所計算出的排放量，並降低您的碳足跡',
        template_EcoCartWillCalculateTheFootprint: 'EcoCart 將計算您訂單的碳足跡',
        template_throughCollectiveActionYouAreTakingProactive: '透過集體行動，我們可以在保護我們的星球方面產生真正的影響。透過抵銷您的碳足跡，您正在積極邁出建立更可持續未來的一步。',
        template_merchantHasOffsetYourOrderWith: '[brand] 已抵消您的訂單',
        template_offsetYourOrderAtCheckoutWith: '在結帳時抵消您的訂單',
        template_yourOrderBreakdown: '您的訂單明細',
        template_projectIsAVerifiedProject: '<a href="[project_url]" target="_blank" rel="noopener noreferrer">[project]</a> 是一個致力於推動可持續發展和環境保護的經過驗證的項目。',
        template_yourOrderWillOffsetAtLeastCarbon: '您的訂單將抵消至少[co2]的碳排放量，這比您的訂單產生的碳排放量更多，超過了淨零標準！',
        template_doubleTheImpact: '通過匹配他們的貢獻來加倍影響，從而加倍您貢獻的影響。',
        template_oneYearTreeAbsorbCO2Amount: '根據美國農業部的資料，一棵成熟的樹在一年內可以從大氣中吸收超過48磅的二氧化碳，並釋放氧氣作為交換。',
        template_carbonTreesCanAbsorb: '這相當於一年內<span class="text-primary-400">[lbs_co2_absorbed_by_one_tree_annually]</span>棵樹木可以吸收的碳量',
        template_ecocartProprietaryAlgo: 'EcoCart的專有演算法通過計算運送方式、距離、製造方法、材料等變數，即時估算每個訂單的碳足跡。',
        template_shopSustainablyByOffsetingCO2Footprint: '透過抵銷您訂單的碳足跡來永續購物',
        template_reduceMyOrderCO2: '減少我的訂單的碳足跡',
        template_makeMyOrder: '使我的訂單碳中和',
        template_makeMyOrderClimatePositive: '將我的訂單對氣候的影響加倍',
        template_offsetEstimatedCo2e: '抵消您訂單的估計碳排放量',
        template_merchantMatchYourContribution: '<span class="text-primary-400">[brand]</span> 將匹配您的貢獻，將其估計的碳足跡抵消加倍',
        template_yourOrderHasBeen: '您的訂單已經實現碳中和',
        template_yourOrderWillBe: '您的訂單將由[brand]進行碳中和',
        template_addToMake: '添加 [price] 以使您的訂單達到碳中和',
        template_addToReduce: '添加 [price] 以減少您的碳足跡',
        template_addToMatch: '添加 [price] 以匹配他們的貢獻並加倍影響力！',
        template_addToDouble: '添加 [price] 以加倍影響',
        template_byCheckingTheBox: '勾選此框，您將保護[project]中的[trees]棵樹木，這些樹木正在吸收您的訂單產生的CO2，使其達到碳中和！',
        template_byDonating: '透過捐贈，您將保護[project]中的[trees]棵樹木，它們正在吸收您的訂單產生的CO2，使其達到碳中和！',
        template_byDonatingMatch: '通過捐贈，您將保護<span class="text-green-500">[trees]</span>在<span class="text-green-500">[project]</span>中的樹木，這些樹木正在吸收您訂單產生的二氧化碳。<span class="text-green-500">[brand]</span>將與您的捐款相配對，以加倍影響並拯救更多樹木。',
        template_carbonNeutralOrderFor: '碳中和訂單 [price]',
        template_carbonNeutralShipping: '碳中和運輸',
        template_madeCarbonNeutralBy: '由[brand]實現碳中和',
        template_brandIsMaking: '[brand] 正在使100%的訂單達到碳中和',
        template_brandWillMatch: '[brand] 將匹配您的捐款，使您的訂單的影響力翻倍',
        template_brandIsProudMerchantPaying: '[brand] 為您的訂單抵消碳排放，透過保護[project]中的[trees]棵樹木，使其達到碳中和。',
        template_brandIsProudCustomerMatching: '[brand] 為了抵銷您訂單的碳排放，並保護 [trees] 棵樹在 [project] 中。通過捐款，您將與 [brand] 的貢獻相匹配，使影響加倍，並節省更多樹木！',
        template_brandsContribution: '[brand]的捐款將直接用於資助[project]',
        template_impact: '影響',
        template_weHaveOffset: '我們已經抵消了[total_co2_offset]的CO2排放',
        template_wePartneredWithEcocart: '我們與 EcoCart 合作，使每一筆訂單都達到碳中和（即淨零排放）！EcoCart 將協助我們消除、捕捉或減輕製造和運送產品所產生的所有碳排放。',
        template_learnMore: '了解更多',
        template_shoppersOffsetTheirCarbonFootprint: '<b>[global_daily_ecocart_order_count] 購物者</b> 今天抵消了他們的碳足跡',
        cta_on: '開',
        cta_off: '關',
        cta_add: '新增',
        cta_remove: '移除',
        cta_close: '關閉',
        cta_learnMore: '了解更多',
        info_milesDriven: '行駛里程',
        info_businessCards: '名片',
        info_arcticIce: '北極冰保存',
        info_lightbulbs: '燈泡供電',
        info_co2Emissions: '的CO₂排放',
        info_lbsOfCo2: 'CO₂抵消磅',
        info_treesSaved: '節省的樹木',
        info_carbonNeutralOrder: '碳中和訂單',
        info_carbonNeutralOption: '具有碳中和選項的',
        info_or: '或',
        info_trees: '樹木',
        info_poweredBy: '由',
        info_yourImpact: '您的氣候影響',
        info_joinShoppers: '加入超過300萬的購物者並持續增長',
        info_ourFriendsEstimate: '我們的朋友在 EcoCart 估計了運送和製造您的特定訂單的碳足跡為：'
    }
};
const setTranslationsLocale = (locale) => {
    _locale = locale;
};
exports.setTranslationsLocale = setTranslationsLocale;
const getTranslationsLocale = () => {
    return _locale;
};
exports.getTranslationsLocale = getTranslationsLocale;
const t = (key) => {
    return exports.translations[_locale][key];
};
exports.t = t;
