"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ecoCookies = exports.ecoStorage = void 0;
const universal_utils_1 = require("@ecocart/universal-utils");
const ecoStorage = (type = localStorage) => ({
    get: (key) => {
        const item = type.getItem(key);
        if (item) {
            return (0, universal_utils_1.isValidJSONString)(item) ? JSON.parse(item) : item;
        }
        else {
            return null;
        }
    },
    set: (key, value) => {
        if (!key)
            throw new Error('Missing required param "key"');
        const safeValue = ((0, universal_utils_1.isValidJSONString)(value) ? value : JSON.stringify(value));
        type.setItem(key, safeValue);
    },
    remove: (key) => {
        if (!key)
            throw new Error('Missing required params "key"');
        type.removeItem(key);
    }
});
exports.ecoStorage = ecoStorage;
const decode = decodeURIComponent;
const pairSplitRegExp = /; */;
// Try decoding a string using a decoding function.
function tryDecode(str, decode) {
    try {
        return typeof decode === 'boolean' ? decodeURIComponent(str) : decode(str);
    }
    catch (e) {
        return str;
    }
}
var CookieSameSite;
(function (CookieSameSite) {
    CookieSameSite["strict"] = "strict";
    CookieSameSite["lax"] = "lax";
    CookieSameSite["none"] = "none";
})(CookieSameSite || (CookieSameSite = {}));
const parseCookies = (str) => {
    if (typeof str !== 'string') {
        throw new TypeError('argument str must be a string');
    }
    const obj = [];
    const pairs = str.split(pairSplitRegExp);
    const dec = decode;
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i];
        let eqIdx = pair.indexOf('=');
        // skip things that don't look like key=value
        if (eqIdx < 0) {
            continue;
        }
        const key = pair.substr(0, eqIdx).trim();
        let val = pair.substr(++eqIdx, pair.length).trim();
        // quoted values
        if ('"' == val[0]) {
            val = val.slice(1, -1);
        }
        // only assign once
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (undefined == obj[key]) {
            obj.push({
                name: key,
                value: tryDecode(val, dec)
            });
        }
    }
    return obj;
};
exports.ecoCookies = {
    get: (key) => { var _a; return (!key ? '' : (_a = exports.ecoCookies.getAll().find((cookie) => cookie.name === key)) === null || _a === void 0 ? void 0 : _a.value); },
    getAll: () => parseCookies(document.cookie)
};
