"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertCheckoutLineItemToShopifyItem = exports.convertCheckoutLineItemToVariantItem = exports.convertVariantItemToCheckoutLineItem = exports.convertProductToFullCart = exports.convertCheckoutToCart = exports.convertWebhookCartToFullCart = exports.convertToShopifyAPIItems = exports.convertToShopifyAPIItem = exports.convertToShopifyAPIItemUpdates = exports.ShopifyItem = exports.ShopifyWebhookCart = exports.ShopifyCart = exports.ShopifyAPIItem = void 0;
/* -------------------------------- generics -------------------------------- */
class ShopifyAPIItem {
    constructor() {
        this.id = '';
        this.quantity = 0;
    }
}
exports.ShopifyAPIItem = ShopifyAPIItem;
/* ---------------------------------- cart ---------------------------------- */
class ShopifyCart {
    constructor() {
        this.token = '';
        this.note = null;
        this.attributes = {};
        this.original_total_price = 0;
        this.total_price = 0;
        this.total_discount = 0;
        this.total_weight = 0;
        this.item_count = 0;
        this.items = [new ShopifyItem()];
        this.requires_shipping = true;
        this.currency = '';
        this.items_subtotal_price = 0;
        this.cart_level_discount_applications = [];
    }
}
exports.ShopifyCart = ShopifyCart;
class ShopifyWebhookCart {
    constructor() {
        this.note = '';
        this.created_at = new Date();
        this.updated_at = new Date();
        this.id = '';
        this.token = '';
        this.line_items = [];
    }
}
exports.ShopifyWebhookCart = ShopifyWebhookCart;
/* ---------------------------------- item ---------------------------------- */
class ShopifyItem {
    constructor() {
        this.id = 0;
        this.properties = {};
        this.quantity = 0;
        this.variant_id = '';
        this.key = '';
        this.title = '';
        this.price = 0;
        this.original_price = 0;
        this.discounted_price = 0;
        this.line_price = 0;
        this.original_line_price = 0;
        this.total_discount = 0;
        this.discounts = [];
        this.sku = '';
        this.grams = 0;
        this.vendor = '';
        this.taxable = true;
        this.product_id = 0;
        this.product_has_only_default_variant = false;
        this.gift_card = false;
        this.final_price = 0;
        this.final_line_price = 0;
        this.url = '';
        this.featured_image = {
            aspect_ratio: 0,
            alt: '',
            height: 0,
            url: '',
            width: 0
        };
        this.image = '';
        this.handle = '';
        this.requires_shipping = true;
        this.product_type = '';
        this.product_title = '';
        this.product_description = '';
        this.variant_title = null;
        this.variant_options = [];
        this.options_with_values = {};
        this.line_level_discount_allocations = [];
        this.line_level_total_discount = 0;
    }
}
exports.ShopifyItem = ShopifyItem;
/* ----------------------------------- api ---------------------------------- */
const convertToShopifyAPIItemUpdates = (items) => {
    return items.reduce((acc, item) => {
        acc[item.variantId] = item.quantity;
        return acc;
    }, {});
};
exports.convertToShopifyAPIItemUpdates = convertToShopifyAPIItemUpdates;
const convertToShopifyAPIItem = (item) => {
    return {
        id: String(item.variantId),
        quantity: Math.round(item.quantity)
    };
};
exports.convertToShopifyAPIItem = convertToShopifyAPIItem;
const convertToShopifyAPIItems = (items) => {
    return (items || []).map(exports.convertToShopifyAPIItem);
};
exports.convertToShopifyAPIItems = convertToShopifyAPIItems;
/* ---------------------------------- cart ---------------------------------- */
const convertWebhookCartToFullCart = (webhookCart) => {
    return {
        updated_at: webhookCart.updated_at,
        id: webhookCart.id,
        token: webhookCart.token,
        total_weight: webhookCart.line_items.reduce((acc, item) => {
            acc = acc + item.grams * item.quantity;
            return acc;
        }, 0),
        items: webhookCart.line_items
    };
};
exports.convertWebhookCartToFullCart = convertWebhookCartToFullCart;
const convertCheckoutToCart = (checkout) => {
    const cart = {
        token: checkout.token,
        note: null,
        attributes: Object.assign({}, (checkout.note_attributes || checkout.attributes || {})),
        original_total_price: Number(checkout.total_price) * 100,
        total_price: Number(checkout.total_price) * 100,
        total_discount: 0,
        total_weight: checkout.line_items.reduce((acc, item) => {
            acc = acc + Number(item.grams) * Number(item.quantity);
            return acc;
        }, 0),
        item_count: checkout.line_items.reduce((acc, item) => {
            acc = acc + Number(item.quantity);
            return acc;
        }, 0),
        items: checkout.line_items.map(exports.convertCheckoutLineItemToShopifyItem),
        requires_shipping: checkout.requires_shipping,
        currency: checkout.currency,
        items_subtotal_price: Number(checkout.subtotal_price),
        cart_level_discount_applications: []
    };
    return cart;
};
exports.convertCheckoutToCart = convertCheckoutToCart;
const convertProductToFullCart = (product) => {
    var _a;
    const firstVariant = (_a = product === null || product === void 0 ? void 0 : product.variants) === null || _a === void 0 ? void 0 : _a[0];
    return {
        total_weight: firstVariant === null || firstVariant === void 0 ? void 0 : firstVariant.grams,
        items: [
            {
                quantity: 1,
                product_title: String(firstVariant === null || firstVariant === void 0 ? void 0 : firstVariant.title),
                variant_id: String(firstVariant === null || firstVariant === void 0 ? void 0 : firstVariant.id),
                price: Number(firstVariant === null || firstVariant === void 0 ? void 0 : firstVariant.price)
            }
        ]
    };
};
exports.convertProductToFullCart = convertProductToFullCart;
/* ---------------------------------- items --------------------------------- */
const convertVariantItemToCheckoutLineItem = (variantItem) => {
    return {
        id: 0,
        key: '',
        sku: '',
        grams: '',
        price: '',
        title: '',
        vendor: '',
        properties: {},
        image_url: '',
        gift_card: false,
        tax_lines: [],
        taxable: null,
        quantity: String(variantItem.quantity),
        line_price: '',
        product_id: null,
        variant_id: Number(variantItem.variantId),
        variant_title: '',
        compare_at_price: '',
        applied_discounts: null,
        requires_shipping: null,
        fulfillment_service: '',
        discount_allocations: null
    };
};
exports.convertVariantItemToCheckoutLineItem = convertVariantItemToCheckoutLineItem;
const convertCheckoutLineItemToVariantItem = (lineItem) => {
    return {
        variantId: String(lineItem.variant_id),
        quantity: Number(lineItem.quantity)
    };
};
exports.convertCheckoutLineItemToVariantItem = convertCheckoutLineItemToVariantItem;
const convertCheckoutLineItemToShopifyItem = (lineItem) => {
    return {
        id: Number(lineItem.id),
        properties: lineItem.properties,
        quantity: Number(lineItem.quantity),
        variant_id: String(lineItem.variant_id),
        key: '',
        title: lineItem.title,
        price: Number(lineItem.price) * 100,
        original_price: Number(lineItem.price) * 100,
        discounted_price: Number(lineItem.price) * 100,
        line_price: Number(lineItem.line_price) * 100,
        original_line_price: Number(lineItem.line_price) * 100,
        total_discount: 0,
        discounts: [],
        sku: lineItem.sku,
        grams: Number(lineItem.grams),
        vendor: lineItem.vendor,
        taxable: lineItem.taxable,
        product_id: lineItem.product_id,
        product_has_only_default_variant: false,
        gift_card: false,
        final_price: Number(lineItem.price) * 100,
        final_line_price: Number(lineItem.line_price) * 100,
        url: '',
        featured_image: {
            aspect_ratio: 0,
            alt: '',
            height: 0,
            url: '',
            width: 0
        },
        image: '',
        handle: '',
        requires_shipping: lineItem.requires_shipping,
        product_type: '',
        product_title: lineItem.title,
        product_description: '',
        variant_title: lineItem.variant_title,
        variant_options: [],
        options_with_values: {},
        line_level_discount_allocations: [],
        line_level_total_discount: 0
    };
};
exports.convertCheckoutLineItemToShopifyItem = convertCheckoutLineItemToShopifyItem;
