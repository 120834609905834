"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OFFSET_UNITS = exports.WEIGHT_UNITS = exports.OFFSET_TYPES = void 0;
/* --------------------------------- consts --------------------------------- */
// ~ below list should stay 1-1 synced w MAGIC_STRING_OFFSETS, since
// ~ "'const' assertions can only be applied to references to enum members, or string, number, boolean, array, or object literals"
// ~ and so we cannot impute values from MAGIC_STRING_OFFSETS list.
// ~ e.g.
// ~ export const OFFSET_TYPES         = [..., 'newOffset']
// ~ -- make sure to also add --
// ~ export const MAGIC_STRING_OFFSETS = [..., '[newOffset]']
exports.OFFSET_TYPES = [
    'co2_per_item',
    'miles_per_item',
    'seedlings_per_item',
    'co2',
    'lbs_co2_absorbed_by_one_tree_annually',
    'trees',
    'miles',
    'businessCards',
    'arcticIce',
    'lightbulbs'
];
exports.WEIGHT_UNITS = ['lb', 'kg'];
// keeping 'lbs' in additional to 'lb' for backward compatibility
exports.OFFSET_UNITS = [...exports.WEIGHT_UNITS, 'lbs', 'miles', 'km', 'sq ft'];
