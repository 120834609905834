"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOffsetConfig = void 0;
const universal_utils_1 = require("@ecocart/universal-utils");
const getOffsetConfig = (offset, locale = (0, universal_utils_1.getTranslationsLocale)()) => {
    switch (offset.type) {
        case 'lbs_co2_absorbed_by_one_tree_annually':
        case 'trees':
            return Object.assign(Object.assign({}, offset), { img: `${universal_utils_1.GLOBAL_PUBLIC_CONFIG.S3_IMAGE_BUCKET}/tree.png`, label: (0, universal_utils_1.t)('info_trees'), svgPaths: [
                    `M635 2511 c-142 -37 -242 -156 -253 -300 l-5 -69 -41 -12 c-133 -40
        -220 -135 -246 -269 -12 -62 -5 -118 22 -182 l19 -46 -31 -34 c-18 -19 -46
        -63 -63 -99 -28 -58 -32 -75 -32 -155 0 -78 4 -98 28 -149 15 -33 43 -79 62
        -102 l35 -42 -22 -49 c-29 -65 -28 -167 2 -246 26 -69 95 -143 165 -177 46
        -23 63 -25 223 -28 l172 -4 0 -233 0 -234 -77 -3 c-78 -3 -78 -3 -81 -32 -2
        -16 2 -32 10 -37 17 -11 1459 -11 1476 0 8 5 12 21 10 37 -3 29 -3 29 -80 32
        l-78 3 0 234 0 233 173 4 c159 3 176 5 222 28 70 34 139 108 165 177 30 79 31
        181 2 246 l-22 49 35 42 c19 23 47 69 62 102 24 51 28 71 28 149 0 80 -4 97
        -32 155 -17 36 -45 80 -63 99 l-31 34 19 46 c27 64 34 120 22 182 -26 134
        -113 229 -246 269 l-41 12 -5 68 c-9 122 -74 217 -186 272 -58 29 -76 33 -147
        33 -96 0 -169 -29 -233 -93 l-40 -40 -38 40 c-22 21 -64 51 -94 66 -47 23 -68
        27 -140 27 -72 0 -93 -4 -140 -27 -30 -15 -72 -45 -94 -66 l-38 -40 -40 40
        c-75 75 -215 115 -313 89z m694 -87 c36 -10 69 -30 102 -61 62 -58 84 -119 76
        -213 -6 -75 1 -90 43 -90 50 0 126 -30 167 -65 98 -84 116 -228 41 -337 -10
        -14 -18 -31 -18 -38 0 -6 21 -30 46 -53 90 -81 123 -197 90 -311 -13 -45 -30
        -70 -76 -119 -33 -34 -60 -65 -60 -69 0 -5 14 -33 31 -64 74 -130 22 -285
        -116 -350 -46 -22 -63 -24 -202 -24 l-153 0 0 98 0 97 125 125 c102 102 125
        130 123 150 -2 18 -10 26 -28 28 -20 2 -43 -16 -115 -88 -49 -49 -93 -90 -97
        -90 -5 0 -8 127 -8 283 l0 282 130 130 c134 134 151 163 110 185 -28 15 -40 6
        -145 -100 l-90 -90 -5 156 c-4 135 -7 158 -22 168 -13 8 -23 8 -35 0 -16 -10
        -18 -40 -23 -328 l-5 -316 -115 115 c-119 119 -148 136 -170 95 -7 -13 -7 -26
        -1 -38 5 -9 73 -79 150 -155 l141 -137 0 -285 0 -285 -152 0 c-140 0 -157 2
        -203 24 -98 46 -148 126 -148 233 0 51 6 72 32 117 17 31 31 59 31 64 0 4 -27
        35 -60 69 -46 49 -63 74 -76 119 -33 114 0 230 90 311 25 23 46 47 46 53 0 7
        -8 24 -18 38 -42 61 -56 129 -42 197 22 105 112 188 219 201 31 3 61 11 68 16
        8 6 10 31 6 77 -12 141 63 249 194 280 55 13 64 13 122 -5z m-499 -21 c29 -16
        66 -45 83 -65 l29 -37 -12 -65 c-7 -36 -10 -72 -7 -80 4 -10 -8 -17 -41 -26
        -148 -36 -260 -189 -249 -340 2 -36 13 -85 24 -110 l19 -45 -42 -51 c-23 -29
        -49 -72 -59 -98 l-18 -46 -58 55 c-57 55 -84 66 -107 43 -23 -23 -11 -44 72
        -127 76 -76 84 -88 90 -131 8 -59 55 -156 95 -194 l29 -28 -21 -46 c-17 -37
        -22 -66 -21 -132 0 -96 19 -151 74 -214 l32 -36 -178 0 c-199 0 -235 6 -296
        53 -103 79 -129 234 -56 339 27 39 22 61 -22 97 -50 40 -87 103 -101 173 -20
        98 27 222 107 283 37 28 43 58 18 86 -25 28 -43 89 -44 147 -1 130 96 232 237
        250 l58 7 0 90 c1 59 6 102 16 125 60 133 221 190 349 123z m1085 3 c98 -45
        139 -118 140 -251 l0 -90 55 -7 c69 -8 94 -18 146 -56 57 -41 94 -118 94 -191
        -1 -61 -19 -122 -44 -150 -25 -28 -19 -58 18 -86 80 -61 125 -181 107 -281
        -13 -71 -50 -134 -101 -175 -44 -36 -49 -58 -22 -97 92 -132 27 -324 -127
        -377 -33 -11 -89 -15 -225 -15 l-180 0 26 28 c35 36 76 118 84 169 5 35 21 56
        110 144 102 102 116 123 92 147 -25 25 -54 11 -130 -65 -43 -42 -79 -75 -80
        -72 -2 2 -11 20 -20 40 -16 36 -16 37 12 64 16 15 43 55 62 89 31 58 33 66 33
        166 0 97 -2 110 -30 165 l-31 60 103 106 c57 58 103 113 103 122 0 18 -26 47
        -42 47 -6 0 -58 -46 -116 -102 -57 -57 -102 -96 -99 -88 49 128 48 206 -4 307
        -43 85 -115 146 -199 169 -41 12 -57 21 -53 31 3 7 0 43 -7 79 l-12 65 29 37
        c71 88 203 117 308 68z m-697 -2093 l2 -233 -230 0 -230 0 0 235 0 235 228 -2
        227 -3 3 -232z m542 2 l0 -235 -230 0 -230 0 0 228 c0 126 3 232 7 235 3 4
        107 7 230 7 l223 0 0 -235z`
                ] });
        case 'miles':
            return Object.assign(Object.assign({}, offset), { img: `${universal_utils_1.GLOBAL_PUBLIC_CONFIG.S3_IMAGE_BUCKET}/car.png`, label: (0, universal_utils_1.t)('info_milesDriven'), svgPaths: [
                    `M915 2129 c-191 -27 -339 -118 -439 -271 -40 -61 -81 -178 -91 -260
          l-7 -57 -56 -22 c-89 -36 -135 -85 -169 -179 -9 -25 -13 -109 -13 -290 0 -235
          2 -257 20 -287 21 -36 52 -58 92 -67 l28 -6 0 302 c0 285 1 305 20 336 11 18
          34 41 52 52 32 19 52 20 737 20 776 0 765 1 886 -63 154 -82 265 -266 265
          -441 l0 -56 -69 0 -69 0 -26 44 c-77 132 -258 188 -399 123 -240 -110 -250
          -446 -17 -565 128 -65 248 -50 357 43 46 39 103 144 103 189 0 26 1 26 78 26
          88 0 129 18 162 72 44 72 17 276 -55 418 -53 106 -173 224 -284 279 -46 22
          -101 44 -122 47 -35 6 -45 15 -96 88 -31 44 -113 138 -182 207 -173 173 -319
          268 -461 302 -89 21 -173 26 -245 16z m240 -159 c84 -35 106 -47 171 -91 40
          -27 101 -73 137 -103 65 -56 197 -206 197 -226 0 -7 -123 -10 -375 -10 l-375
          0 0 226 0 227 108 -5 c59 -3 121 -11 137 -18z m-385 -220 l0 -210 -140 0 -140
          0 0 28 c0 81 51 199 118 273 41 46 134 117 155 118 4 1 7 -93 7 -209z m1124
          -894 c86 -50 111 -153 57 -238 -37 -58 -80 -82 -146 -81 -85 0 -142 44 -167
          125 -13 45 0 112 30 154 45 63 155 82 226 40z`,
                    `M1050 1260 l0 -70 105 0 105 0 0 70 0 70 -105 0 -105 0 0 -70z`,
                    `M637 1030 c-172 -29 -292 -223 -243 -393 47 -161 233 -273 377 -226
          131 42 214 128 237 244 l8 40 183 5 184 5 3 35 c2 19 6 50 9 68 l6 32 -205 0
          -205 0 -16 35 c-22 45 -95 111 -148 134 -53 23 -128 31 -190 21z m143 -165
          c49 -25 83 -80 88 -141 6 -68 -9 -105 -60 -148 -70 -60 -158 -55 -231 13 -36
          34 -41 44 -45 94 -2 31 -2 67 2 81 9 35 54 86 91 102 41 18 119 17 155 -1z`
                ] });
        case 'businessCards':
            return Object.assign(Object.assign({}, offset), { img: `${universal_utils_1.GLOBAL_PUBLIC_CONFIG.S3_IMAGE_BUCKET}/business-cards.png`, label: (0, universal_utils_1.t)('info_businessCards'), svgPaths: [
                    `M195 2200 c-80 -14 -130 -52 -174 -133 -21 -37 -21 -45 -21 -802 0
          -498 3 -766 10 -770 6 -3 10 -13 10 -22 0 -25 47 -81 93 -111 l42 -27 1095 0
          1095 0 45 27 c44 25 72 57 98 108 10 20 12 193 10 807 l-3 781 -30 43 c-17 24
          -53 57 -80 74 l-48 30 -1051 1 c-578 1 -1069 -2 -1091 -6z m2130 -176 l25 -27
          0 -727 c0 -721 0 -728 -21 -754 l-20 -26 -1055 0 c-1031 0 -1054 0 -1073 19
          -11 11 -23 35 -26 53 -3 18 -5 349 -3 735 l3 701 24 26 24 26 1049 0 1049 0
          24 -26z`,
                    `M515 1728 c-48 -27 -58 -75 -25 -118 l19 -25 745 0 c733 0 745 0 760
          20 26 33 20 90 -10 114 -26 21 -33 21 -748 21 -523 -1 -726 -4 -741 -12z`,
                    `M520 1338 c-24 -9 -50 -47 -50 -76 0 -12 11 -34 25 -47 l24 -25 734
          0 734 0 21 23 c14 15 22 36 22 60 0 70 38 67 -769 70 -397 1 -730 -1 -741 -5z`,
                    `M491 924 c-27 -34 -27 -64 0 -98 l20 -26 504 0 504 0 20 22 c31 33
          34 55 14 91 l-19 32 -511 3 -511 2 -21 -26z`
                ] });
        case 'arcticIce':
            return Object.assign(Object.assign({}, offset), { img: `${universal_utils_1.GLOBAL_PUBLIC_CONFIG.S3_IMAGE_BUCKET}/arctic-ice.png`, label: (0, universal_utils_1.t)('info_arcticIce'), unit: 'sq ft', svgPaths: [
                    `M195 2200 c-80 -14 -130 -52 -174 -133 -21 -37 -21 -45 -21 -802 0
            -498 3 -766 10 -770 6 -3 10 -13 10 -22 0 -25 47 -81 93 -111 l42 -27 1095 0
            1095 0 45 27 c44 25 72 57 98 108 10 20 12 193 10 807 l-3 781 -30 43 c-17 24
            -53 57 -80 74 l-48 30 -1051 1 c-578 1 -1069 -2 -1091 -6z m2130 -176 l25 -27
            0 -727 c0 -721 0 -728 -21 -754 l-20 -26 -1055 0 c-1031 0 -1054 0 -1073 19
            -11 11 -23 35 -26 53 -3 18 -5 349 -3 735 l3 701 24 26 24 26 1049 0 1049 0
            24 -26z`,
                    `M515 1728 c-48 -27 -58 -75 -25 -118 l19 -25 745 0 c733 0 745 0 760
            20 26 33 20 90 -10 114 -26 21 -33 21 -748 21 -523 -1 -726 -4 -741 -12z`,
                    `M520 1338 c-24 -9 -50 -47 -50 -76 0 -12 11 -34 25 -47 l24 -25 734
            0 734 0 21 23 c14 15 22 36 22 60 0 70 38 67 -769 70 -397 1 -730 -1 -741 -5z`,
                    `M491 924 c-27 -34 -27 -64 0 -98 l20 -26 504 0 504 0 20 22 c31 33
            34 55 14 91 l-19 32 -511 3 -511 2 -21 -26z`
                ] });
        case 'lightbulbs':
            return Object.assign(Object.assign({}, offset), { img: `${universal_utils_1.GLOBAL_PUBLIC_CONFIG.S3_IMAGE_BUCKET}/light-bulb.png`, label: (0, universal_utils_1.t)('info_lightbulbs'), svgPaths: [
                    `M1080 2340 c-190 -52 -302 -124 -424 -275 -55 -67 -118 -191 -142
          -280 -24 -91 -29 -246 -11 -336 24 -117 53 -181 134 -302 124 -182 160 -276
          181 -462 l7 -60 68 -3 67 -3 0 33 c0 45 -28 225 -41 268 -19 60 -99 212 -148
          280 -112 156 -147 260 -138 420 9 173 68 304 192 425 121 119 262 176 433 177
          203 1 383 -85 499 -237 93 -121 133 -241 133 -393 0 -151 -27 -231 -126 -372
          -78 -111 -118 -182 -148 -262 -24 -62 -56 -240 -56 -307 l0 -31 69 0 68 0 7
          73 c18 183 43 247 166 432 116 176 149 264 157 430 9 184 -46 366 -155 505
          -87 111 -226 211 -357 259 -122 44 -318 53 -435 21z`,
                    `M1124 1669 c-88 -88 -114 -119 -114 -139 0 -30 27 -60 55 -60 25 0
          245 220 245 246 0 27 -19 50 -48 59 -20 6 -40 -10 -138 -106z`,
                    `M1132 1339 c-62 -62 -112 -115 -110 -120 2 -4 28 -34 58 -66 30 -32
          74 -79 98 -105 l42 -48 0 -189 0 -189 51 -4 c28 -1 53 -1 55 1 2 2 4 101 4
          219 l0 214 -62 66 c-89 93 -88 93 -88 101 0 3 32 39 70 79 74 76 85 105 52
          135 -10 10 -27 17 -38 17 -11 0 -68 -49 -132 -111z`,
                    `M810 460 c-24 -24 -25 -38 -4 -68 l15 -22 439 0 439 0 15 22 c21 29
          20 40 -4 66 -21 22 -21 22 -450 22 -417 0 -431 -1 -450 -20z`,
                    `M946 254 c-19 -18 -21 -65 -4 -82 17 -17 619 -17 636 0 18 18 14 63
          -6 81 -16 15 -53 17 -315 17 -255 0 -298 -2 -311 -16z`
                ] });
        case 'co2':
        default:
            return Object.assign(Object.assign({}, offset), { img: `${universal_utils_1.GLOBAL_PUBLIC_CONFIG.S3_IMAGE_BUCKET}/cloud.png`, unit: (0, universal_utils_1.getWeightUnit)(locale), label: (0, universal_utils_1.t)('info_co2Emissions'), svgPaths: [
                    `M1075 2505 c-745 -119 -1218 -832 -1034 -1559 172 -676 870 -1082
        1549 -900 118 31 150 48 150 79 0 22 -23 55 -38 55 -4 0 -42 -11 -85 -25 -234
        -75 -500 -74 -727 3 -148 50 -320 148 -320 182 0 15 17 112 35 197 3 12 14 25
        26 28 38 13 88 66 99 106 9 31 8 52 -4 93 -10 33 -13 70 -10 102 5 40 2 59
        -15 92 -25 49 -61 70 -150 89 -56 12 -80 25 -178 98 -62 47 -121 85 -131 85
        -10 0 -45 -9 -77 -20 -32 -11 -60 -20 -62 -20 -1 0 -3 30 -3 67 0 63 1 65 18
        50 22 -20 45 -21 147 -6 112 17 131 49 75 123 -17 22 -30 44 -30 50 0 6 20 24
        45 41 35 23 57 53 111 149 38 66 89 144 115 173 52 60 76 128 64 185 -9 44
        -50 113 -78 133 l-22 16 30 25 c78 64 236 143 356 177 71 21 222 47 271 47 34
        0 38 -2 29 -17 -5 -10 -26 -36 -48 -59 -34 -36 -56 -46 -193 -93 -85 -29 -162
        -61 -172 -70 -17 -17 -108 -230 -108 -254 0 -8 31 -58 69 -112 l69 -98 -41
        -56 c-22 -31 -57 -75 -78 -99 -109 -125 -116 -284 -17 -397 73 -83 189 -116
        298 -85 54 15 67 6 72 -49 4 -36 14 -60 37 -90 29 -37 32 -46 27 -84 -22 -157
        -25 -140 53 -332 59 -142 101 -178 180 -155 74 21 171 118 171 171 0 9 13 33
        30 54 16 21 30 43 30 50 0 6 20 38 45 69 37 49 45 66 45 102 0 23 -5 55 -11
        70 -16 45 -3 71 80 158 104 108 121 161 66 206 -8 7 -36 17 -62 21 -72 14 -91
        38 -162 210 -43 103 -74 133 -175 165 -85 27 -126 26 -157 -1 -13 -13 -21 -14
        -43 -4 -15 7 -26 20 -26 31 0 60 -54 88 -170 88 -78 0 -80 1 -70 21 17 31 71
        59 115 59 48 0 65 14 65 52 0 37 -30 52 -93 45 -54 -7 -127 -45 -152 -80 l-16
        -22 -24 29 c-51 64 -52 68 -14 161 l33 85 116 40 c63 22 137 48 164 58 41 14
        61 30 110 91 108 132 89 121 181 105 364 -64 672 -290 838 -616 24 -47 54
        -117 66 -156 l23 -72 -31 -35 c-17 -20 -31 -43 -32 -53 0 -9 -12 7 -25 37 -30
        65 -95 146 -135 166 -15 9 -55 15 -89 15 -51 0 -65 -4 -85 -25 -27 -26 -32
        -64 -12 -84 15 -15 -8 -35 -94 -81 -44 -23 -59 -27 -67 -17 -26 33 -92 180
        -92 207 0 23 -12 44 -47 82 -79 84 -198 125 -223 78 -18 -33 -3 -57 48 -77 63
        -24 122 -78 122 -111 0 -22 115 -256 142 -289 18 -22 49 -15 143 31 115 57
        185 116 185 157 0 77 85 -13 130 -140 39 -107 54 -131 83 -131 23 0 74 46 95
        85 10 18 11 18 18 -9 10 -43 -3 -272 -21 -346 -39 -163 -107 -319 -192 -438
        -25 -37 -91 -111 -146 -164 -87 -85 -98 -101 -93 -123 8 -33 48 -43 82 -20 58
        37 196 190 260 285 334 502 269 1165 -157 1590 -279 280 -692 416 -1074 355z
        m-577 -421 c34 -23 55 -72 49 -111 -4 -20 -23 -53 -48 -81 -23 -26 -71 -99
        -108 -162 -59 -102 -74 -121 -134 -165 -74 -55 -83 -79 -47 -125 11 -14 20
        -31 20 -37 0 -22 -54 -14 -84 13 -26 23 -28 29 -22 74 14 106 92 295 168 410
        52 77 157 200 171 200 7 0 23 -7 35 -16z m592 -444 c0 -14 21 -34 70 -66 87
        -56 130 -65 170 -35 35 25 43 26 104 5 44 -15 51 -22 73 -73 67 -151 97 -208
        121 -234 32 -34 73 -57 102 -57 11 0 20 -4 20 -8 0 -4 -27 -34 -61 -67 -95
        -93 -112 -137 -95 -248 8 -56 8 -58 -27 -96 -19 -21 -38 -49 -42 -62 -5 -13
        -18 -37 -31 -54 -12 -16 -31 -52 -41 -80 -13 -36 -30 -57 -61 -79 -54 -38 -57
        -35 -117 114 -48 119 -54 155 -36 203 23 60 10 141 -31 184 -12 13 -24 45 -29
        75 -7 43 -16 60 -45 87 l-36 33 -114 -7 c-104 -6 -116 -5 -151 15 -62 35 -88
        82 -88 160 0 56 4 73 27 106 70 99 148 189 169 195 13 4 52 7 87 8 56 1 62 -1
        62 -19z m-753 -590 c92 -71 113 -82 171 -95 111 -23 119 -31 107 -105 -4 -25
        -1 -57 11 -94 20 -65 14 -78 -52 -107 -23 -9 -44 -22 -48 -27 -5 -8 -46 -206
        -46 -221 0 -10 -66 59 -121 127 -105 130 -181 281 -219 432 -35 141 -37 126
        28 148 31 11 58 21 60 21 2 1 51 -35 109 -79z`
                ] });
    }
};
exports.getOffsetConfig = getOffsetConfig;
