"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.abbreviateNumber = exports.formatCurrency = exports.formatNumber = exports.adjustPctPx = exports.addPx = void 0;
const addPx = (val, px) => {
    if (!val || typeof px !== 'number')
        return '';
    return Number(val.replace('px', '')) + px + 'px';
};
exports.addPx = addPx;
const adjustPctPx = (val, pct) => {
    if (!val || typeof pct !== 'number')
        return '';
    return Math.round(Number(val.replace('px', '')) * pct) + 'px';
};
exports.adjustPctPx = adjustPctPx;
/**
 * @param number The number to be formatted with language-sensitive number formatting.
 * @param options The built in options specified in the Intl.NumberFormat https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 * @returns string
 */
const formatNumber = (number, options) => {
    const _a = options || {}, { locale } = _a, restOptions = __rest(_a, ["locale"]);
    return new Intl.NumberFormat(locale, Object.assign({}, restOptions)).format(number);
};
exports.formatNumber = formatNumber;
const formatCurrency = (value, currency = 'USD') => {
    if (!currency)
        return '';
    const _currency = currency.toUpperCase().trim();
    return new Intl.NumberFormat(navigator.language, { style: 'currency', currency: _currency }).format(Number(value));
};
exports.formatCurrency = formatCurrency;
// To abbreviate number that is greater than million to display in the format of "M" or "B"
// For example, 3.5M or 8.2B for 3.5 million and 8.2 billion
const abbreviateNumber = (num) => {
    const absNum = Math.abs(num);
    if (typeof absNum !== 'number' || isNaN(absNum))
        return '';
    if (absNum >= 1e9) {
        return (num / 1e9).toFixed(1) + 'B';
    }
    if (absNum >= 1e6) {
        return (num / 1e6).toFixed(1) + 'M';
    }
    // We do 'k' only when it’s > 10,000. Example: 9,767 would be that, but 10,278 would be 10.3k
    if (absNum >= 1e4) {
        return (num / 1e3).toFixed(1) + 'k';
    }
    return num.toString();
};
exports.abbreviateNumber = abbreviateNumber;
