"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sampleSize = exports.sample = void 0;
// Simpler implementation https://lodash.com/docs/4.17.15#sample
// Gets a random element from array
function sample(arr) {
    if (!Array.isArray(arr))
        return undefined;
    return sampleSize(arr, 1)[0] || undefined;
}
exports.sample = sample;
// Simpler implementation of https://lodash.com/docs/4.17.15#sampleSize
// Gets n (size) random elements at unique keys from array up to the size of array
function sampleSize(arr, size = 1) {
    if (!Array.isArray(arr))
        return [];
    const shuffled = arr.slice().sort(() => Math.random() - 0.5);
    // Take the first `size` elements.
    return shuffled.slice(0, size);
}
exports.sampleSize = sampleSize;
