"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EcoNetworkListItem = exports.EcoNetworkMerchant = exports.EcoNetworkProduct = void 0;
class EcoNetworkProduct {
    constructor() {
        this.id = '';
        this.name = '';
        this.description = '';
        this.price = 0;
        this.imageUrl = '';
        this.productUrl = '';
    }
}
exports.EcoNetworkProduct = EcoNetworkProduct;
class EcoNetworkMerchant {
    constructor() {
        this.shopName = '';
        this.name = '';
        this.displayName = '';
        this.logoUrl = '';
        this.currency = 'USD';
        this.url = '';
        this.totalCO2Offset = 0;
    }
}
exports.EcoNetworkMerchant = EcoNetworkMerchant;
// Converted schema from AirTable
class EcoNetworkListItem {
    constructor() {
        this.merchant = new EcoNetworkMerchant();
        this.products = [new EcoNetworkProduct()];
    }
}
exports.EcoNetworkListItem = EcoNetworkListItem;
