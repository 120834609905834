"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.decrypt = exports.getQueryStringOverride = exports.getBucketRanges = exports.chooseVariation = exports.inRange = exports.getEqualWeights = exports.hash = void 0;
function hashFnv32a(str) {
    let hval = 0x811c9dc5;
    const l = str.length;
    for (let i = 0; i < l; i++) {
        hval ^= str.charCodeAt(i);
        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    return hval >>> 0;
}
function hash(seed, value, version) {
    // New unbiased hashing algorithm
    if (version === 2) {
        return (hashFnv32a(hashFnv32a(seed + value) + '') % 10000) / 10000;
    }
    // Original biased hashing algorithm (keep for backwards compatibility)
    if (version === 1) {
        return (hashFnv32a(value + seed) % 1000) / 1000;
    }
    // Unknown hash version
    return null;
}
exports.hash = hash;
function getEqualWeights(n) {
    if (n <= 0)
        return [];
    return new Array(n).fill(1 / n);
}
exports.getEqualWeights = getEqualWeights;
function inRange(n, range) {
    return n >= range[0] && n < range[1];
}
exports.inRange = inRange;
function chooseVariation(n, ranges) {
    for (let i = 0; i < ranges.length; i++) {
        if (inRange(n, ranges[i])) {
            return i;
        }
    }
    return -1;
}
exports.chooseVariation = chooseVariation;
function getBucketRanges(numVariations, coverage, weights) {
    coverage = coverage === undefined ? 1 : coverage;
    // Make sure coverage is within bounds
    if (coverage < 0) {
        if (process.env.NODE_ENV !== 'production') {
            console.error('Experiment.coverage must be greater than or equal to 0');
        }
        coverage = 0;
    }
    else if (coverage > 1) {
        if (process.env.NODE_ENV !== 'production') {
            console.error('Experiment.coverage must be less than or equal to 1');
        }
        coverage = 1;
    }
    // Default to equal weights if missing or invalid
    const equal = getEqualWeights(numVariations);
    weights = weights || equal;
    if (weights.length !== numVariations) {
        if (process.env.NODE_ENV !== 'production') {
            console.error('Experiment.weights array must be the same length as Experiment.variations');
        }
        weights = equal;
    }
    // If weights don't add up to 1 (or close to it), default to equal weights
    const totalWeight = weights.reduce((w, sum) => sum + w, 0);
    if (totalWeight < 0.99 || totalWeight > 1.01) {
        if (process.env.NODE_ENV !== 'production') {
            console.error('Experiment.weights must add up to 1');
        }
        weights = equal;
    }
    // Covert weights to ranges
    let cumulative = 0;
    return weights.map((w) => {
        const start = cumulative;
        cumulative += w;
        return [start, start + coverage * w];
    });
}
exports.getBucketRanges = getBucketRanges;
function getQueryStringOverride(id, url, numVariations) {
    if (!url) {
        return null;
    }
    const search = url.split('?')[1];
    if (!search) {
        return null;
    }
    const match = search
        .replace(/#.*/, '') // Get rid of anchor
        .split('&') // Split into key/value pairs
        .map((kv) => kv.split('=', 2))
        .filter(([k]) => k === id) // Look for key that matches the experiment id
        .map(([, v]) => parseInt(v)); // Parse the value into an integer
    if (match.length > 0 && match[0] >= 0 && match[0] < numVariations)
        return match[0];
    return null;
}
exports.getQueryStringOverride = getQueryStringOverride;
const base64ToBuf = (b) => Uint8Array.from(atob(b), (c) => c.charCodeAt(0));
function decrypt(encryptedString, decryptionKey, subtle) {
    return __awaiter(this, void 0, void 0, function* () {
        decryptionKey = decryptionKey || '';
        subtle = subtle || (globalThis.crypto && globalThis.crypto.subtle);
        if (!subtle) {
            throw new Error('No SubtleCrypto implementation found');
        }
        try {
            const key = yield subtle.importKey('raw', base64ToBuf(decryptionKey), { name: 'AES-CBC', length: 128 }, true, ['encrypt', 'decrypt']);
            const [iv, cipherText] = encryptedString.split('.');
            const plainTextBuffer = yield subtle.decrypt({ name: 'AES-CBC', iv: base64ToBuf(iv) }, key, base64ToBuf(cipherText));
            return new TextDecoder().decode(plainTextBuffer);
        }
        catch (e) {
            throw new Error('Failed to decrypt');
        }
    });
}
exports.decrypt = decrypt;
