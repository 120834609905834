"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isEncodedURIComponent = exports.stripHtml = exports.capitalizeAllWords = exports.kebabToPascal = exports.pascalToKebab = exports.pascalToSpaced = exports.normalizeString = exports.decodeString = exports.encodeString = exports.isValidJSONString = exports.lastChar = exports.isBase64Encodable = exports.isBase64Decodable = exports.isValidBase64 = exports.toBase64 = exports.fromBase64 = void 0;
const js_base64_1 = require("js-base64");
/* ---------------------------- encoding/decoding --------------------------- */
exports.fromBase64 = js_base64_1.fromBase64;
exports.toBase64 = js_base64_1.toBase64;
const isValidBase64 = (origStr) => {
    if (!(0, exports.isBase64Decodable)(origStr))
        return false;
    // js-base64's isValidBase64() doesn't catch everything, so try to re-encode and compare for deeper check
    const fromStr = (0, exports.fromBase64)(origStr);
    const backToStr = (0, exports.toBase64)(fromStr);
    return origStr === backToStr;
};
exports.isValidBase64 = isValidBase64;
const isBase64Decodable = (str) => {
    if (!str)
        return false;
    try {
        (0, exports.fromBase64)(str);
    }
    catch (e) {
        return false;
    }
    return true;
};
exports.isBase64Decodable = isBase64Decodable;
const isBase64Encodable = (str) => {
    try {
        (0, exports.toBase64)(str);
    }
    catch (e) {
        return false;
    }
    return true;
};
exports.isBase64Encodable = isBase64Encodable;
const lastChar = (str) => {
    if (!str || typeof str !== 'string' || !str.length)
        return '';
    return str.charAt(str.length - 1);
};
exports.lastChar = lastChar;
const isValidJSONString = (str) => {
    if (!str)
        return false;
    try {
        JSON.parse(str);
    }
    catch (e) {
        return false;
    }
    return true;
};
exports.isValidJSONString = isValidJSONString;
const encodeString = (obj) => {
    return (0, exports.toBase64)(JSON.stringify(obj));
};
exports.encodeString = encodeString;
const decodeString = (str) => {
    return JSON.parse((0, exports.fromBase64)(str));
};
exports.decodeString = decodeString;
const normalizeString = (str) => ((0, exports.isValidBase64)(str) ? (0, exports.fromBase64)(str) : str);
exports.normalizeString = normalizeString;
/* --------------------------- string manipulation -------------------------- */
const pascalToSpaced = (text) => {
    // Check if the first character is already uppercase
    const firstCharIsUppercase = /^[A-Z]/.test(text);
    // Replace uppercase letters with a space before them, except the first character
    const result = text.replace(/(?<!\b[A-Z])\B([A-Z])/g, ' $1');
    // Capitalize the first character if it's not already uppercase
    const capitalizedResult = firstCharIsUppercase ? result : result.charAt(0).toUpperCase() + result.slice(1);
    return capitalizedResult;
};
exports.pascalToSpaced = pascalToSpaced;
const pascalToKebab = (text) => {
    const result = text.replace(/(?<=[a-z])([A-Z])/g, '-$1').toLowerCase();
    return result.charAt(0) === '-' ? result.slice(1) : result;
};
exports.pascalToKebab = pascalToKebab;
const kebabToPascal = (text) => {
    return text
        .split('-')
        .map((word) => word[0].toUpperCase() + word.substring(1))
        .join('');
};
exports.kebabToPascal = kebabToPascal;
const capitalizeAllWords = (phrase = '') => {
    return phrase
        .split(' ')
        .map((word) => word[0].toUpperCase() + word.substring(1))
        .join(' ');
};
exports.capitalizeAllWords = capitalizeAllWords;
const stripHtml = (html) => {
    if (!html)
        return '';
    return html.replace(/<[^>]*>/g, '');
};
exports.stripHtml = stripHtml;
/* --------------------------- URI encoding/decoding -------------------------- */
// Check if the string is an already encoded URI
const isEncodedURIComponent = (inputString) => {
    if (!inputString)
        return false;
    try {
        return decodeURIComponent(inputString) !== inputString;
    }
    catch (e) {
        return false;
    }
};
exports.isEncodedURIComponent = isEncodedURIComponent;
