"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertWidgetElementToTextElements = exports.convertTextToTextElements = void 0;
const entities_1 = require("@ecocart/entities");
const universal_utils_1 = require("@ecocart/universal-utils");
const offset_1 = require("./offset");
const widget_1 = require("./widget");
const convertTextToTextElements = (text, { merchant }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const decodedText = (0, universal_utils_1.isValidBase64)(text) ? (0, universal_utils_1.fromBase64)(text) : text;
    // all replacements below are "static" values (no soft/live update, typically "merchant settings")
    const decodedReplacedText = decodedText
        .replace('[brand]', (merchant === null || merchant === void 0 ? void 0 : merchant.companyName) || '')
        .replace('[project]', ((_a = merchant === null || merchant === void 0 ? void 0 : merchant.project) === null || _a === void 0 ? void 0 : _a.name) || '')
        .replace('[project_location]', ((_b = merchant === null || merchant === void 0 ? void 0 : merchant.project) === null || _b === void 0 ? void 0 : _b.location) || '')
        .replace('[project_url]', ((_c = merchant === null || merchant === void 0 ? void 0 : merchant.project) === null || _c === void 0 ? void 0 : _c.url) || '')
        .replace('[global_all_time_carbon_offset]', (0, universal_utils_1.calculateOffsetAmount)({ co2: ((_f = (_e = (_d = merchant === null || merchant === void 0 ? void 0 : merchant.stats) === null || _d === void 0 ? void 0 : _d.global) === null || _e === void 0 ? void 0 : _e.all_time) === null || _f === void 0 ? void 0 : _f.carbon_offset) || 0, co2Unit: 'kg' }, // co2Unit is hardcoded as kg as it's the unit returned from backend
    { type: 'co2', unit: (0, universal_utils_1.getWeightUnit)((0, universal_utils_1.getTranslationsLocale)()) }, (0, universal_utils_1.getTranslationsLocale)()))
        .replace('[global_daily_ecocart_order_count]', (0, universal_utils_1.formatNumber)(((_j = (_h = (_g = merchant === null || merchant === void 0 ? void 0 : merchant.stats) === null || _g === void 0 ? void 0 : _g.global) === null || _h === void 0 ? void 0 : _h.daily) === null || _j === void 0 ? void 0 : _j.ecocart_orders) || 0))
        .replace('[eco_incentives_trees]', String(merchant === null || merchant === void 0 ? void 0 : merchant.ecoIncentivesNumberOfTrees) || '');
    const textEls = decodedReplacedText.split(entities_1.MAGIC_STRING_REGEX).map((chunk) => {
        switch (chunk) {
            case '[price]':
                return {
                    type: 'eco-live-calculation',
                    value: ''
                };
            case '[miles_per_item]':
                return {
                    type: 'eco-live-miles-per-item',
                    value: ''
                };
            case '[seedlings_per_item]':
                return {
                    type: 'eco-live-seedlings-per-item',
                    value: ''
                };
            case '[co2_per_item]':
                return {
                    type: 'eco-live-offset-per-item',
                    value: ''
                };
            case '[amount_needed_for_incentives]':
                return {
                    type: 'eco-live-amount-needed-for-incentives',
                    value: ''
                };
            case '[eco_incentives_threshold]':
                return {
                    type: 'eco-live-threshold-amount',
                    value: ''
                };
            case '[total_co2_offset]':
                return {
                    type: 'eco-live-total-offset-amount',
                    value: ''
                };
            case '[ecocart_logo]':
                return {
                    type: 'ecocart_logo',
                    value: ''
                };
            case '[ecocart_logo_black]':
                return {
                    type: 'ecocart_logo_black',
                    value: ''
                };
            case '[ecocart_logo_white]':
                return {
                    type: 'ecocart_logo_white',
                    value: ''
                };
            case '[ecocart_logo_white_text_green_plane]':
                return {
                    type: 'ecocart_logo_white_text_green_plane',
                    value: ''
                };
            case '[ecocart_logo_black_text_green_plane]':
                return {
                    type: 'ecocart_logo_black_text_green_plane',
                    value: ''
                };
            case '[info_icon]':
                return {
                    type: 'info_icon',
                    value: ''
                };
            default:
                if (entities_1.MAGIC_STRING_OFFSETS.includes(chunk)) {
                    const offsetType = chunk.replace('[', '').replace(']', '');
                    return {
                        type: 'eco-live-offset-amount',
                        value: (0, offset_1.getOffsetConfig)({ type: offsetType })
                    };
                }
                else {
                    return {
                        type: 'text',
                        value: chunk
                            .replace('CO2', 'CO₂')
                            .replace('co2', 'CO₂')
                            .replace('Co2', 'CO₂')
                            .replace('cO2', 'CO₂')
                            .replace('[br]', '<br>')
                            .replace(/<script/g, '<')
                            .replace(/<\/script>/g, '</>')
                    };
                }
        }
    });
    return textEls.filter((el) => !(el.type === 'text' && el.value === '') || el == undefined);
};
exports.convertTextToTextElements = convertTextToTextElements;
const convertWidgetElementToTextElements = (widgetElement, { widget, merchant }) => {
    var _a, _b, _c;
    if (!widgetElement)
        return [];
    const { template, elements } = widget || {};
    const locale = (0, universal_utils_1.getTranslationsLocale)();
    const text = ((_b = (_a = elements === null || elements === void 0 ? void 0 : elements[widgetElement]) === null || _a === void 0 ? void 0 : _a.text) === null || _b === void 0 ? void 0 : _b[locale]) || ((_c = (0, widget_1.getDefaultTextValues)(template, merchant)) === null || _c === void 0 ? void 0 : _c[widgetElement]) || '';
    return (0, exports.convertTextToTextElements)(text, { merchant });
};
exports.convertWidgetElementToTextElements = convertWidgetElementToTextElements;
