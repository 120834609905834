"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.launchModal = exports.CloseModalEvent = void 0;
const object_1 = require("./object");
var CloseModalEvent;
(function (CloseModalEvent) {
    CloseModalEvent["ADD"] = "add";
    CloseModalEvent["REMOVE"] = "remove";
})(CloseModalEvent || (exports.CloseModalEvent = CloseModalEvent = {}));
const launchModal = (tag, options = {}) => {
    var _a;
    const { onClose, direction } = options;
    // name: the tag name
    // rest: inline props of the web component (if any)
    const [tagName, ...rest] = tag.replace('<', '').replace(' />', '').split(' ');
    const ariaLabel = tagName.replace('eco-', '').replace(/-/g, ' ') + ' dialog';
    const animDuration = 200;
    const dialogStyles = {
        display: 'flex',
        visibility: 'visible',
        zIndex: 2147483647,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        justifyContent: direction === 'slideLeft' ? 'flex-end' : 'center',
        alignItems: 'center',
        opacity: 0,
        transition: `all ${animDuration}ms ease-in-out`
    };
    const stylesInlineStr = (0, object_1.convertStylesObjToString)(dialogStyles);
    const restPropsString = rest.join(' ');
    const element = `<${tagName} role="dialog" aria-modal="true" aria-label="${ariaLabel}" style="${stylesInlineStr}" id="backdrop" ${restPropsString} />`;
    document.body.insertAdjacentHTML('beforeend', element);
    // prevent background scrolling. Track original overflow value for when modal is closed
    // const bodyStyle = window.getComputedStyle(document.body);
    // const overflowOrigValue = bodyStyle.getPropertyValue('overflow');
    // document.body.style.overflow = 'hidden';
    const modalEl = document.querySelector(tagName);
    if (modalEl) {
        let immediateChildrenEls = [];
        // fade-in backdrop
        modalEl.style.opacity = '1';
        const closeModal = (event) => {
            // document.body.style.overflow = overflowOrigValue;
            immediateChildrenEls.forEach((el) => {
                if (el) {
                    if (direction === 'slideLeft')
                        el.style.transform = 'translateX(100%)';
                    else
                        el.style.transform = 'translateY(50px)';
                }
            });
            modalEl.style.opacity = '0';
            setTimeout(() => {
                modalEl.remove();
            }, animDuration * 2);
            if (onClose)
                onClose(event);
        };
        const handleBackdropClick = (event) => {
            var _a, _b;
            const interactiveElId = (_b = (_a = event === null || event === void 0 ? void 0 : event.composedPath()) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.id;
            if (interactiveElId === 'backdrop') {
                closeModal(event);
            }
        };
        modalEl.addEventListener('click', handleBackdropClick);
        modalEl.closeModal = (event) => {
            closeModal(event);
        };
        // fade-in immediate children - aka the outermost content of the dialog
        // timeout is needed for animation to work
        setTimeout(() => {
            var _a, _b;
            immediateChildrenEls = Array.from(((_b = (_a = document === null || document === void 0 ? void 0 : document.querySelector(tagName)) === null || _a === void 0 ? void 0 : _a.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelectorAll(':scope > *, #content')) || []).filter((el) => (el === null || el === void 0 ? void 0 : el.tagName) !== 'STYLE');
            immediateChildrenEls.forEach((el) => {
                if (el) {
                    el.style.transform = 'translate(0px)';
                    el.style.opacity = '1';
                }
            });
        }, 30);
        (_a = immediateChildrenEls[0]) === null || _a === void 0 ? void 0 : _a.focus();
    }
};
exports.launchModal = launchModal;
