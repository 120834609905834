"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.User = void 0;
const db_entity_metadata_1 = require("./prototypes/db-entity-metadata");
class User extends db_entity_metadata_1.DBEntityMetadata {
    constructor() {
        super();
        this.type = 'USER';
        this.id = ''; // this is email just called id for single table design reasons
        this.firstName = '';
        this.lastName = '';
        this.merchants = {};
        this.permissions = [];
        this.userType = 'merchant_admin';
        this.lastLogin = 0;
    }
}
exports.User = User;
