"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertAndFormatWithUnits = exports.getEquivalencyValue = exports.getEquivalencyRawValue = exports.calculateOffsetAmount = exports.convertKgToLb = exports.convertLbToKg = exports.getWeightUnit = exports.MULTIPLIERS = exports.IMPERIAL_LOCALES = void 0;
const entities_1 = require("@ecocart/entities");
const numbers_1 = require("./numbers");
const translations_1 = require("./translations");
exports.IMPERIAL_LOCALES = ['en'];
exports.MULTIPLIERS = {
    LBS_OF_CO2_TO_MILES: 1.1628,
    /**
     * Unverified: These are not on the epa calculator
     * https://www.epa.gov/energy/greenhouse-gas-equivalencies-calculator
     */
    LBS_OF_CO2_TO_BUSINESS_CARDS: 370,
    LBS_OF_CO2_TO_LIGHTBULBS: 0.54,
    LBS_OF_CO2_TO_ARCTIC_ICE: 0.0147,
    LBS_OF_CO2_TO_TREES: 1 / 30,
    LBS_OF_CO2_TO_AMOUNT_ABSORBED_BY_ONE_TREE_ANNUALLY: 1 / 48,
    /** End of unverified multipliers */
    /**
     * These multipliers are based on 1,000,000 pounds of CO2 and from the results of
     * the EPA's Greenhouse Gas Equivalencies Calculator, we divided the result by 1,000,000
     * to get the multiplier for 1 pound of CO2.
     * https://www.epa.gov/energy/greenhouse-gas-equivalencies-calculator
     */
    LBS_OF_CO2_TO_HOME_ELECTRICITY: 0.0000883,
    LBS_OF_CO2_TO_SMARTPHONE_CHARGED: 55.176,
    LBS_OF_CO2_TO_SEEDLINGS: 0.0075,
    LBS_OF_CO2_TO_FOREST_ACRES: 0.000541,
    LBS_OF_CO2_TO_WIND_TURBINES: 0.000000126,
    LBS_TO_KGS: 0.453592,
    KGS_TO_LBS: 2.20462,
    MILES_TO_KILOMETERS: 1.60934
};
const getWeightUnit = (locale) => {
    if (!locale)
        return undefined;
    return exports.IMPERIAL_LOCALES.includes(locale) ? 'lb' : 'kg';
};
exports.getWeightUnit = getWeightUnit;
const convertLbToKg = (value) => value * exports.MULTIPLIERS.LBS_TO_KGS;
exports.convertLbToKg = convertLbToKg;
const convertKgToLb = (value) => value * exports.MULTIPLIERS.KGS_TO_LBS;
exports.convertKgToLb = convertKgToLb;
const calculateOffsetAmount = ({ co2, co2Unit }, { type, maxAllowable, unit }, locale = (0, translations_1.getTranslationsLocale)()) => {
    if (typeof co2 === 'string')
        co2 = Number(co2);
    if (isNaN(co2))
        console.error(`Invalid 'co2' value: ${co2}`);
    if (!entities_1.OFFSET_TYPES.includes(type))
        console.error(`Invalid 'equivalent' value: ${type}`);
    if (!co2)
        co2 = 1; // ** fallback = 1lb
    const adjustCo2InputUnit = (_co2) => {
        // ** if supplied in kg, set to lb to maintain calcs
        return co2Unit === 'kg' ? (0, exports.convertKgToLb)(_co2) : _co2;
    };
    const adjustCo2OutputUnit = (_co2) => {
        // ** if output locale specified, set to locale's unit
        return exports.IMPERIAL_LOCALES.includes(locale) ? _co2 : (0, exports.convertLbToKg)(_co2);
    };
    co2 = adjustCo2InputUnit(co2);
    const getSafeValue = (_co2, _type, min = 0, max = undefined) => {
        const formatCo2Value = () => {
            if (_co2 < 2) {
                return Number(_co2.toFixed(_type === 'trees' ? 1 : 2));
            }
            return Math.round(_co2);
        };
        _co2 = getValue(_type, _co2);
        if (min && _co2 < min)
            _co2 = min;
        // rounding rules, to avoid 0kg/lbs value, eg's...
        // 0.243 = 0.24kg
        // 1.243 = 1.24kg
        // 2.243 = 2kg
        const valNumber = formatCo2Value();
        let valFormatted = new Intl.NumberFormat(locale).format(valNumber);
        if (max && valNumber > max)
            valFormatted = new Intl.NumberFormat(locale).format(max) + '+';
        if (unit) {
            const _unit = unit === 'lbs' ? 'lb' : unit;
            valFormatted = valFormatted + ' ' + _unit; // as a convention lb should not have 's' for plural
        }
        return valFormatted;
    };
    const getValue = (_type, _co2) => {
        switch (_type) {
            case 'lbs_co2_absorbed_by_one_tree_annually':
                return _co2 * exports.MULTIPLIERS.LBS_OF_CO2_TO_AMOUNT_ABSORBED_BY_ONE_TREE_ANNUALLY;
            case 'trees':
                return _co2 * exports.MULTIPLIERS.LBS_OF_CO2_TO_TREES;
            case 'miles': {
                const miles = _co2 * exports.MULTIPLIERS.LBS_OF_CO2_TO_MILES;
                const milesOrKilometers = exports.IMPERIAL_LOCALES.includes(locale) ? miles : miles * exports.MULTIPLIERS.MILES_TO_KILOMETERS;
                return milesOrKilometers;
            }
            case 'businessCards':
                return _co2 * exports.MULTIPLIERS.LBS_OF_CO2_TO_BUSINESS_CARDS;
            case 'arcticIce':
                return _co2 * exports.MULTIPLIERS.LBS_OF_CO2_TO_ARCTIC_ICE;
            case 'lightbulbs':
                return _co2 * exports.MULTIPLIERS.LBS_OF_CO2_TO_LIGHTBULBS;
            case 'co2':
            default: {
                return adjustCo2OutputUnit(_co2);
            }
        }
    };
    switch (type) {
        case 'lbs_co2_absorbed_by_one_tree_annually':
            return getSafeValue(co2, type, 0, maxAllowable);
        case 'trees':
            return getSafeValue(co2, type, 1, maxAllowable);
        case 'miles':
            return getSafeValue(co2, type, 1, maxAllowable);
        case 'businessCards':
            return getSafeValue(co2, type, 0, maxAllowable);
        case 'arcticIce':
            return getSafeValue(co2, type, 0, maxAllowable);
        case 'lightbulbs':
            return getSafeValue(co2, type, 1, maxAllowable);
        case 'co2':
        default:
            return getSafeValue(co2, type, 0, maxAllowable);
    }
};
exports.calculateOffsetAmount = calculateOffsetAmount;
const getEquivalencyRawValue = (totalCarbonOffset, key) => {
    if (!totalCarbonOffset || !(exports.MULTIPLIERS === null || exports.MULTIPLIERS === void 0 ? void 0 : exports.MULTIPLIERS[key]))
        return undefined;
    const { value, units } = totalCarbonOffset;
    if (value == undefined || !entities_1.WEIGHT_UNITS.includes(units))
        return undefined;
    const CO2e = units === 'kg' ? (0, exports.convertKgToLb)(value) : value;
    return CO2e ? CO2e * exports.MULTIPLIERS[key] : undefined;
};
exports.getEquivalencyRawValue = getEquivalencyRawValue;
const getEquivalencyValue = (totalCarbonOffset, key) => {
    const val = (0, exports.getEquivalencyRawValue)(totalCarbonOffset, key);
    if (!val)
        return 'N/A';
    if ((0, numbers_1.abbreviateNumber)(val) !== val.toString()) {
        return (0, numbers_1.abbreviateNumber)(val);
    }
    return String((0, numbers_1.formatNumber)(val, {
        // Display whole number if it's a whole number
        // Otherwise show 1 decimals for numbers >= 10
        // 2 decimals for values < 10
        minimumFractionDigits: val % 1 !== 0 ? (val < 10 ? 2 : 1) : 0,
        maximumFractionDigits: val % 1 !== 0 ? (val < 10 ? 2 : 1) : 0
    }));
};
exports.getEquivalencyValue = getEquivalencyValue;
/**
 * Converts a number to a formatted string with currency, decimal, weight unit (if applicable)
 * @param value number
 * @param merchant Merchant
 * @param options weight unit boolean value and options to pass to formatNumber
 * @returns formatted value with weight unit (if applicable)
 */
const convertAndFormatWithUnits = (value, merchant, options = {}) => {
    const { isWeightUnit } = options, rest = __rest(options, ["isWeightUnit"]);
    const formatConfig = Object.assign({ maximumFractionDigits: 0, minimumFractionDigits: 0 }, rest);
    const isImperial = exports.IMPERIAL_LOCALES.includes((merchant === null || merchant === void 0 ? void 0 : merchant.locale) || '');
    if (!isWeightUnit)
        return (0, numbers_1.formatNumber)(value, formatConfig);
    return isImperial ? `${(0, numbers_1.formatNumber)((0, exports.convertKgToLb)(value), formatConfig)} lb` : `${(0, numbers_1.formatNumber)(value, formatConfig)}  kg`;
};
exports.convertAndFormatWithUnits = convertAndFormatWithUnits;
