"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getShopifyCurrency = exports.getShopifyLocale = exports.getCartToken = exports.getShopifyCheckoutCartToken = exports.getShopifyCheckoutAuthorizationToken = exports.getCheckoutToken = exports.getShopifyCheckoutStep = void 0;
const entities_1 = require("@ecocart/entities");
const universal_utils_1 = require("@ecocart/universal-utils");
const storage_1 = require("./storage");
/* -------------------------------- checkout -------------------------------- */
const getShopifyCheckoutStep = () => {
    var _a, _b;
    return (_b = (_a = window === null || window === void 0 ? void 0 : window.Shopify) === null || _a === void 0 ? void 0 : _a.Checkout) === null || _b === void 0 ? void 0 : _b.step;
};
exports.getShopifyCheckoutStep = getShopifyCheckoutStep;
const extractCheckoutToken = () => {
    var _a, _b;
    const cookieNames = [
        'checkout',
        'checkoutId',
        'checkoutid',
        'checkout-id',
        'checkout_id',
        'checkoutToken',
        'checkouttoken',
        'checkout-token',
        'checkout_token',
        'chk',
        'shopify_checkoutId'
    ];
    const token = (0, storage_1.ecoStorage)().get('checkoutId') ||
        (0, storage_1.ecoStorage)().get('shopifyCheckout') ||
        ((_a = storage_1.ecoCookies.getAll().find((cookie) => cookieNames.includes(cookie.name))) === null || _a === void 0 ? void 0 : _a.value);
    let cleanToken = token || '';
    if (!cleanToken) {
        return '';
    }
    else if ((0, universal_utils_1.isValidJSONString)(cleanToken) && (cleanToken === null || cleanToken === void 0 ? void 0 : cleanToken.startsWith('{"id":"'))) {
        cleanToken = (_b = JSON.parse(cleanToken)) === null || _b === void 0 ? void 0 : _b.id;
    }
    return cleanToken;
};
const getCheckoutToken = () => {
    var _a, _b, _c, _d, _e, _f, _g;
    return (((_b = (_a = window === null || window === void 0 ? void 0 : window.Shopify) === null || _a === void 0 ? void 0 : _a.Checkout) === null || _b === void 0 ? void 0 : _b.token) ||
        ((_d = (_c = window === null || window === void 0 ? void 0 : window.Shopify) === null || _c === void 0 ? void 0 : _c.Checkout) === null || _d === void 0 ? void 0 : _d.checkoutToken) ||
        ((_f = (_e = window === null || window === void 0 ? void 0 : window.Shopify) === null || _e === void 0 ? void 0 : _e.Checkout) === null || _f === void 0 ? void 0 : _f.id) ||
        ((_g = window === null || window === void 0 ? void 0 : window.rechargeData) === null || _g === void 0 ? void 0 : _g.token) ||
        extractCheckoutToken());
};
exports.getCheckoutToken = getCheckoutToken;
const getShopifyCheckoutAuthorizationToken = () => {
    var _a;
    return (_a = document === null || document === void 0 ? void 0 : document.querySelector('meta[name="shopify-checkout-authorization-token"]')) === null || _a === void 0 ? void 0 : _a.content;
};
exports.getShopifyCheckoutAuthorizationToken = getShopifyCheckoutAuthorizationToken;
/* ---------------------------------- cart ---------------------------------- */
const getShopifyCheckoutCartToken = () => {
    var _a, _b;
    return ((_b = (_a = window === null || window === void 0 ? void 0 : window.Shopify) === null || _a === void 0 ? void 0 : _a.Checkout) === null || _b === void 0 ? void 0 : _b.cartToken) || '';
};
exports.getShopifyCheckoutCartToken = getShopifyCheckoutCartToken;
const extractCartToken = () => {
    var _a;
    const cookieNames = ['CART_ID', 'cart', 'cart-token'];
    // iterate over cookieNames (rather than cookies) to retain priority selection based on above array
    // .. consider doing for checkoutToken
    const cookies = storage_1.ecoCookies.getAll();
    const firstValidCookieName = cookieNames.find((name) => cookies.find((cookie) => name === cookie.name));
    return firstValidCookieName ? ((_a = cookies.find((cookie) => cookie.name === firstValidCookieName)) === null || _a === void 0 ? void 0 : _a.value) || '' : '';
};
const getCartToken = () => {
    return (0, exports.getShopifyCheckoutCartToken)() || extractCartToken();
};
exports.getCartToken = getCartToken;
/* --------------------------------- locale --------------------------------- */
const getShopifyLocale = () => {
    var _a;
    return ((_a = window === null || window === void 0 ? void 0 : window.Shopify) === null || _a === void 0 ? void 0 : _a.locale) && entities_1.LOCALES.includes(window.Shopify.locale) ? window.Shopify.locale : undefined;
};
exports.getShopifyLocale = getShopifyLocale;
/* -------------------------------- currency -------------------------------- */
const getShopifyCurrency = () => {
    var _a, _b, _c;
    const currency = ((_a = window === null || window === void 0 ? void 0 : window.Shopify) === null || _a === void 0 ? void 0 : _a.currency) || ((_c = (_b = window === null || window === void 0 ? void 0 : window.Shopify) === null || _b === void 0 ? void 0 : _b.Checkout) === null || _c === void 0 ? void 0 : _c.currency);
    return currency && entities_1.CURRENCY_CODES.includes(currency) ? currency : undefined;
};
exports.getShopifyCurrency = getShopifyCurrency;
