"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.features = exports.FEATURE_VARIATIONS = exports.BOOLEAN_STRING = exports.ENABLED_FEATURES = exports.PRECHECK_VARIATIONS = exports.COPY_VARIATIONS = exports.CLIMATE_IMPACT = exports.OFFSET_MATERIALS = void 0;
const widget_1 = require("./enum/widget");
exports.OFFSET_MATERIALS = ['carbon', 'plastic'];
exports.CLIMATE_IMPACT = ['climate_positive', 'climate_contribution'];
exports.COPY_VARIATIONS = ['default', 'support_global_forestry_projects'];
exports.PRECHECK_VARIATIONS = ['checked', 'unchecked', 'widget_hidden'];
exports.ENABLED_FEATURES = ['social_proof', 'upsell', 'social_proof_and_upsell', 'default', 'none'];
exports.BOOLEAN_STRING = ['true', 'false'];
exports.FEATURE_VARIATIONS = [
    ...exports.CLIMATE_IMPACT,
    ...exports.OFFSET_MATERIALS,
    ...exports.ENABLED_FEATURES,
    ...exports.BOOLEAN_STRING,
    ...exports.COPY_VARIATIONS,
    ...exports.PRECHECK_VARIATIONS,
    ...widget_1.TEMPLATES
];
exports.features = {
    dummy_ab_test: {
        defaultValue: 'true',
        rules: [
            {
                variations: ['true', 'false']
            }
        ]
    },
    show_checkout_widget: {
        defaultValue: 'true',
        rules: [
            {
                variations: ['true', 'false']
            }
        ]
    },
    show_checkout_widget_body_copy: {
        defaultValue: 'true',
        rules: [
            {
                variations: ['true', 'false']
            }
        ]
    },
    vary_checkout_widget_templates: {
        defaultValue: 'default',
        rules: [
            {
                variations: ['default', 'ab_test_widget_a_visual', 'ab_test_widget_b_cobranded', 'ab_test_widget_c_action', 'none']
            }
        ]
    },
    vary_checkout_widget_precheck: {
        defaultValue: 'checked',
        rules: [
            {
                variations: ['checked', 'unchecked', 'widget_hidden']
            }
        ]
    },
    show_pdp_widget: {
        defaultValue: 'true',
        rules: [
            {
                variations: ['true', 'false']
            }
        ]
    },
    vary_pdp_widget_templates: {
        defaultValue: 'default',
        rules: [
            {
                variations: ['default', 'ab_test_pdp_a_background', 'ab_test_pdp_b_cobranded', 'ab_test_pdp_c_action', 'none']
            }
        ]
    },
    enabled_checkout_widget_features: {
        defaultValue: 'default',
        rules: [
            {
                variations: ['default', 'none', 'social_proof', 'upsell', 'social_proof_and_upsell']
            }
        ]
    },
    carbon_versus_plastic_customer_paying: {
        defaultValue: 'carbon',
        rules: [
            {
                variations: ['carbon', 'plastic']
            }
        ]
    },
    climate_positive_versus_climate_contribution: {
        defaultValue: 'climate_positive',
        rules: [
            {
                variations: ['climate_positive', 'climate_contribution']
            }
        ]
    },
    default_versus_support_global_forestry_projects: {
        defaultValue: 'default',
        rules: [
            {
                variations: ['default', 'support_global_forestry_projects']
            }
        ]
    }
};
