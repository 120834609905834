"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasValidMagicStrings = exports.pruneEcoCartEntityProps = exports.checkType = exports.isEcoCartEntity = void 0;
const entities_1 = require("@ecocart/entities");
const isEcoCartEntity = (obj) => {
    const NON_ECOCART_CONSTRUCTOR_NAMES = [
        'String',
        'Number',
        'Date',
        'Function',
        'RegExp',
        'Array',
        'Object',
        'Boolean',
        'Symbol',
        'BigInt'
    ];
    if (!obj || NON_ECOCART_CONSTRUCTOR_NAMES.includes(obj.constructor.name))
        return false;
    return true;
};
exports.isEcoCartEntity = isEcoCartEntity;
/**
 *
 * @param value The value you're checking/validating (typically a Partial<T>, where T is the referenceType)
 * @param referenceType The reference type you're checking against, typically used by creating a new instance of the class, e.g. new Merchant(), new Project(), etc.
 * @param options { enums, nullableReferenceTypes }
 *
 *  enums: an object in the shape of the reference type which, when specified, list an array of legitimate enum values
 *
 *  e.g {
 *    payorMode: ['customer_paying', 'merchant_paying', etc...]
 *  }
 *
 *  nullableReferenceTypes: an object in the shape of the reference type which, when specified, list
 *   that property's reference type you're checking against, typically used by creating a new instance of the class
 *
 *  e.g {
 *    experiment: new Experiment()
 *  }
 * }
 * @returns { isValid: boolean, message?: string } // if isValid is false, message will be a string describing the error(s)
 */
const checkType = (value, referenceType, { enums, nullableReferenceTypes } = {}) => {
    var _a;
    if (!enums || Array.isArray(enums))
        enums = {};
    if (!nullableReferenceTypes || Array.isArray(nullableReferenceTypes))
        nullableReferenceTypes = {};
    const errors = [];
    if (value === null || value === undefined || typeof value !== 'object' || !Object.keys(value).length) {
        errors.push('value is invalid');
    }
    if (!referenceType || typeof referenceType !== 'object' || !Object.keys(referenceType).length) {
        errors.push('reference type is invalid');
    }
    const referenceTypeWithNullablesAdded = Object.assign(Object.assign({}, referenceType), nullableReferenceTypes);
    if (errors.length > 0) {
        return { isValid: false, message: errors.join(' and ') };
    }
    const invalidProps = [];
    const invalidTypes = [];
    const invalidEnums = [];
    const evalObjAtDepth = (root) => {
        const valObj = root ? value[root] : value;
        const refObj = root && (referenceTypeWithNullablesAdded === null || referenceTypeWithNullablesAdded === void 0 ? void 0 : referenceTypeWithNullablesAdded[root])
            ? referenceTypeWithNullablesAdded[root]
            : referenceTypeWithNullablesAdded;
        if (valObj === undefined && refObj !== undefined) {
            return;
        }
        else if (Array.isArray(refObj) && Array.isArray(valObj)) {
            // no recursion after array deep-comparison to keep this function manageable!
            const firstItemInRefArr = refObj[0];
            valObj.forEach((valArrItem, valArrIdx) => {
                if (typeof valArrItem === 'object') {
                    Object.keys(valArrItem).forEach((valArrItemKey) => {
                        if (Object.keys(firstItemInRefArr).includes(valArrItemKey)) {
                            if (![typeof firstItemInRefArr[valArrItemKey]].includes(typeof valArrItem[valArrItemKey])) {
                                invalidTypes.push(root ? `'${root}[${valArrIdx}].${valArrItemKey}'` : `'${valArrItemKey}'`);
                            }
                        }
                        else {
                            invalidProps.push(root ? `'${root}[${valArrIdx}].${valArrItemKey}'` : `'${valArrItemKey}'`);
                        }
                    });
                }
            });
        }
        else {
            Object.keys(valObj).forEach((key) => {
                if (Object.keys(refObj).includes(key)) {
                    if (enums === null || enums === void 0 ? void 0 : enums[key]) {
                        if (!enums[key].includes(valObj[key])) {
                            invalidEnums.push(`'${key}'`);
                        }
                    }
                    else if (Array.isArray(valObj[key]) && valObj[key].length && Array.isArray(refObj[key]) && refObj[key].length) {
                        const firstItemInRefArr = refObj[key][0];
                        valObj[key].forEach((valArrItem) => {
                            if (![typeof firstItemInRefArr].includes(typeof valArrItem)) {
                                invalidTypes.push(root ? `'${root}.${key}'` : `'${key}'`);
                            }
                        });
                    }
                    else if (![typeof refObj[key]].includes(typeof valObj[key])) {
                        invalidTypes.push(root ? `'${root}.${key}'` : `'${key}'`);
                    }
                }
                else {
                    invalidProps.push(root ? `'${root}.${key}'` : `'${key}'`);
                }
                // recurse
                // TODO: Evaluate child-array check aka ` || (Array.isArray(refObj[key]) && isPrimitive(refObj[key][0]) === false)`
                const isNullable = (nullableReferenceTypes === null || nullableReferenceTypes === void 0 ? void 0 : nullableReferenceTypes[key]) !== undefined;
                const isObject = typeof valObj[key] === 'object' && ![null, undefined].includes(valObj[key]);
                if ((isNullable && valObj[key] === null) || (isObject && Object.keys(valObj[key]).length === 0)) {
                    // terminate recursion
                    return;
                }
                const isRefDefined = refObj[key] !== undefined;
                const isRefTyped = (0, exports.isEcoCartEntity)(refObj[key]);
                if (isRefDefined && isRefTyped) {
                    evalObjAtDepth(key);
                }
            });
        }
    };
    evalObjAtDepth();
    const invalidPropSet = [...new Set(invalidProps)];
    const invalidTypesSet = [...new Set(invalidTypes)];
    const invalidEnumsSet = [...new Set(invalidEnums)];
    if (invalidPropSet.length)
        errors.push(`${invalidPropSet.join(', ')} ${invalidPropSet.length === 1 ? 'does' : 'do'} not exist`);
    if (invalidTypesSet.length)
        errors.push(`${invalidTypesSet.join(', ')} has incorrect type`);
    if (invalidEnumsSet.length)
        errors.push(`${invalidEnumsSet.join(', ')} has invalid enum`);
    return errors.length > 0
        ? {
            isValid: false,
            message: `${errors.join(' and ')} for reference type ${(_a = referenceType === null || referenceType === void 0 ? void 0 : referenceType.constructor) === null || _a === void 0 ? void 0 : _a.name}`
        }
        : { isValid: true };
};
exports.checkType = checkType;
// Remove all EcoCart Entity props from an object
const pruneEcoCartEntityProps = (referenceType) => {
    if (!referenceType || typeof referenceType !== 'object' || !Object.keys(referenceType).length) {
        console.error('Error: reference type is invalid');
        return {};
    }
    return Object.keys(referenceType).reduce((acc, prop) => {
        if (!(0, exports.isEcoCartEntity)(referenceType[prop])) {
            acc[prop] = referenceType[prop];
        }
        return acc;
    }, {});
};
exports.pruneEcoCartEntityProps = pruneEcoCartEntityProps;
// Check if a string contains any valid magic strings. Will return true if the string contains no magic strings.
const hasValidMagicStrings = (val) => {
    const MAGIC_STRING_REGEX = new RegExp(`(${entities_1.MAGIC_STRINGS.join('|').replace(/\[/g, '\\[').replace(/\]/g, '\\]')})`);
    return val.split(MAGIC_STRING_REGEX).every((chunk) => {
        return entities_1.MAGIC_STRINGS.includes(chunk) || (!chunk.includes('[') && !chunk.includes(']'));
    });
};
exports.hasValidMagicStrings = hasValidMagicStrings;
